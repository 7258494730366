import { client } from '@/common/config'
import type { PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  facilityId: string
}

export const getAreas = async ({ facilityId, page, limit, search }: Params) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/areas/{facilityId}/search',
    {
      params: {
        path: {
          facilityId
        },
        query: {
          page,
          limit,
          search
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting areas:', error)
    return await Promise.reject(error)
  }

  return data
}
