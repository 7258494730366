import { client } from '@/common/config'

export const checkInductionTest = async (
  answers: Array<{
    question: string
    answer: string
  }>
) => {
  const { data, error } = await client.POST(
    '/api/safety-induction/test/check',
    {
      body: {
        testResume: answers
      }
    }
  )

  if (error !== undefined) {
    console.error('Error checking induction test:', error)
    return await Promise.reject(error)
  }

  return {
    testCompleted: data.data.testCompleted,
    expirationDate: data.data.expirationDate
  }
}
