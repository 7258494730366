// External
import {
  TouchableOpacity,
  type ViewStyle,
  StyleSheet,
  View
} from 'react-native'
import { useState } from 'react'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
// Constants
import { colors } from '@/common/constants'
// Models
import type { StowlogIcon } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  style?: ViewStyle
  onPress?: () => void
  iconName: StowlogIcon
  iconSize?: number
  iconColor?: keyof typeof colors
  showBadge?: boolean
  badgeText?: string
  disabled?: boolean
}

export const SquareIconButton = ({
  style,
  onPress,
  iconName,
  iconSize = 18,
  iconColor,
  showBadge = false,
  badgeText,
  disabled = false
}: Props) => {
  const [isPressed, setIsPressed] = useState(false)
  const colorScheme = useAppStore((state) => state.colorScheme)

  const backgroundColor = {
    light: isPressed ? colors.lightGray10Percent : colors.white,
    dark: isPressed ? colors.darkSlateGray : colors.darkerSlateGray
  }
  const borderColor = {
    light: colors.lightGray10Percent,
    dark: isPressed ? colors.darkSlateGray : '#FFFFFF0D'
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      {...(onPress !== undefined && {
        onPress,
        onPressIn: () => {
          setIsPressed(true)
        },
        onPressOut: () => {
          setIsPressed(false)
        }
      })}
      style={[
        styles.container,
        {
          backgroundColor: backgroundColor[colorScheme],
          borderColor: borderColor[colorScheme],
          ...(disabled && { opacity: 0.5 })
        },
        style
      ]}
    >
      <Icon
        name={iconName}
        size={iconSize}
        {...(iconColor !== undefined && { color: colors[iconColor] })}
      />

      {(showBadge || badgeText !== undefined) && (
        <View style={[styles.badge, { backgroundColor: colors.columbiaBlue }]}>
          {badgeText !== undefined && (
            <Text variant="smallBold">{badgeText}</Text>
          )}
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 52,
    width: 52,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  },
  badge: {
    position: 'absolute',
    borderRadius: 10,
    top: -10,
    right: -10,
    height: 20,
    minWidth: 20,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
