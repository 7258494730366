import { client } from '@/common/config'
import type { PaginatedGetParams } from '@/common/models'

export const getNotifications = async ({
  page,
  limit = 10
}: PaginatedGetParams) => {
  const { data, error } = await client.GET('/api/app-user-notifications', {
    params: {
      query: {
        page,
        limit
      }
    }
  })

  if (error !== undefined) {
    console.error('Error getting notifications:', error)
    return await Promise.reject(error)
  }

  return data
}
