import { createStackNavigator } from '@react-navigation/stack'
import type { PTWStackParamList } from '@/permitToWork/models'
import PermitToWork from '@/permitToWork/views/PermitToWork'
import PermitDetail from '@/permitToWork/views/PermitDetail'
import CreatePermitStepPersonInCharge from '@/permitToWork/views/CreatePermit/CreatePermitStepPersonInCharge'
import CreatePermitStepDates from '@/permitToWork/views/CreatePermit/CreatePermitStepDates'
import CreatePermitStepLocation from '@/permitToWork/views/CreatePermit/CreatePermitStepLocation'
import CreatePermitStaffOnSite from '@/permitToWork/views/CreatePermit/CreatePemitStaffOnSite'
import CreatePermitStepPreArrangements from '@/permitToWork/views/CreatePermit/CreatePermitStepPreArrangements'
import CreatePermitStepPPE from '@/permitToWork/views/CreatePermit/CreatePermitStepPPE'
import CreatePermitStepTypeOfWork from '@/permitToWork/views/CreatePermit/CreatePermitStepTypeOfWork'
import CreatePermitStepAttachments from '@/permitToWork/views/CreatePermit/CreatePermitStepAttachments'

const Stack = createStackNavigator<PTWStackParamList>()

const PermitToWorkStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="PermitToWork"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="PermitToWork" component={PermitToWork} />

      <Stack.Screen name="PermitDetail" component={PermitDetail} />

      <Stack.Screen
        name="CreatePermitStepPersonInCharge"
        component={CreatePermitStepPersonInCharge}
      />

      <Stack.Screen
        name="CreatePermitStepDates"
        component={CreatePermitStepDates}
      />

      <Stack.Screen
        name="CreatePermitStepLocation"
        component={CreatePermitStepLocation}
      />

      <Stack.Screen
        name="CreatePermitStaffOnSite"
        component={CreatePermitStaffOnSite}
      />

      <Stack.Screen
        name="CreatePermitStepPreArrangements"
        component={CreatePermitStepPreArrangements}
      />

      <Stack.Screen
        name="CreatePermitStepPPE"
        component={CreatePermitStepPPE}
      />

      <Stack.Screen
        name="CreatePermitStepTypeOfWork"
        component={CreatePermitStepTypeOfWork}
      />

      <Stack.Screen
        name="CreatePermitStepAttachments"
        component={CreatePermitStepAttachments}
      />
    </Stack.Navigator>
  )
}

export default PermitToWorkStack
