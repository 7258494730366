// External
import { type ViewStyle, TouchableOpacity, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
// Models
import type { StowlogIcon } from '@/common/models'
// Stores
import useCreatePermitStore from '@/permitToWork/stores/useCreatePermitStore'

interface Props {
  style?: ViewStyle
  color?: string
  iconName: StowlogIcon
  onPress: () => void
  text: string
}

export const DrawerContentItem = ({
  style,
  color,
  iconName,
  text,
  ...rest
}: Props) => {
  const { isCreating, setIsCreating } = useCreatePermitStore((state) => ({
    isCreating: state.isCreating,
    setIsCreating: state.setIsCreating
  }))
  const { t } = useTranslation()

  const onPress = () => {
    if (Platform.OS === 'web' && isCreating) {
      const confirmLeave = window.confirm(t('youHaveSavedChanges'))
      if (confirmLeave) {
        setIsCreating(false)
        rest.onPress()
      }
      return
    }

    rest.onPress()
  }

  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Icon name={iconName} size={20} color={color} />

      <Text
        variant="mediumBold"
        style={{ textTransform: 'capitalize' }}
        color={color}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
