import { checkInTimeValues } from '@/visit/constants'
import { getHours, getMinutes } from 'date-fns'

export const getCheckInTimeValues = (isSameDay: boolean) => {
  if (isSameDay) {
    const now = new Date()
    const minutes = getHours(now) * 60 + getMinutes(now)
    return checkInTimeValues.filter((time) => parseInt(time.value) >= minutes)
  }

  return checkInTimeValues
}
