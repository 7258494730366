// External
import {
  Image,
  type ViewStyle,
  type ImageSourcePropType,
  View,
  StyleSheet
} from 'react-native'
import React from 'react'
import { Text } from './Text'
// Constants
import { colors, shadows } from '@/common/constants'

interface Props {
  style?: ViewStyle
  size: number
  source?: ImageSourcePropType
  initials?: string
  borderRadius?: number
  borderWidth?: number
}

export const Avatar = ({
  style,
  size,
  source,
  initials,
  borderRadius = 15,
  borderWidth = 1
}: Props) => {
  return (
    <View
      style={[
        shadows.default,
        {
          height: size,
          width: size,
          borderRadius,
          borderWidth,
          backgroundColor: colors.columbiaBlue,
          borderColor:
            source !== undefined ? colors.white : colors.columbiaBlue,
          justifyContent: 'center',
          alignItems: 'center'
        },
        style
      ]}
    >
      {source !== undefined ? (
        <Image
          style={{
            borderRadius,
            height: size - borderWidth,
            width: size - borderWidth
          }}
          source={source}
        />
      ) : (
        <Text variant="h2Bold" style={styles.initials}>
          {initials?.toLocaleUpperCase()}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  initials: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center'
  }
})
