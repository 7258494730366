// External
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import {
  type ImagePickerAsset,
  launchImageLibraryAsync,
  MediaTypeOptions
} from 'expo-image-picker'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useTranslation } from 'react-i18next'
// Components
import { Text } from './Text'

interface Props {
  onImagePickerAsset: (asset: ImagePickerAsset) => void
}

export const UploadOptions = ({ onImagePickerAsset }: Props) => {
  const { t } = useTranslation()

  return (
    <View>
      <View style={styles.option}>
        <TouchableOpacity
          style={[styles.iconContainer, { backgroundColor: '#FFBB884D' }]}
          onPress={async () => {
            try {
              const result = await launchImageLibraryAsync({
                mediaTypes: MediaTypeOptions.Images,
                allowsEditing: true
              })

              if (!result.canceled && result.assets[0] !== undefined) {
                onImagePickerAsset(result.assets[0])
              }
            } catch (error) {
              console.error(error)
            }
          }}
        >
          <MaterialIcons name="image-search" size={24} color="#E09650" />
        </TouchableOpacity>

        <Text>{t('selectAPhoto')}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  option: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconContainer: {
    height: 78,
    width: 78,
    borderRadius: 39,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
