// External
import { type ReactNode } from 'react'
import { type TextStyle, type ViewStyle, Platform } from 'react-native'
import { Text as RNEText } from '@rneui/base'
// Constants
import { textBaseStylesMap, colors } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Models
import { type TextVariant } from '@/common/models'

interface Props {
  children: ReactNode
  variant?: TextVariant
  color?: string
  style?: TextStyle | TextStyle[] | ViewStyle
  numberOfLines?: number
}

export const Text = ({
  children,
  variant = 'base',
  color,
  style,
  numberOfLines
}: Props) => {
  const { locale } = useAppStore((state) => ({
    colorScheme: state.colorScheme,
    locale: state.locale
  }))

  const extraStylesMap: Partial<Record<TextVariant, TextStyle>> = {
    link: {
      textDecorationLine: 'underline'
    },
    button: {
      color: colors.darkSlateGray
    },
    buttonBold: {
      color: colors.white
    }
  }

  const textStyle =
    extraStylesMap[variant] !== undefined ? extraStylesMap[variant] : {}

  if (textStyle !== undefined && color !== undefined) {
    textStyle.color = color
  }

  return (
    <RNEText
      {...(Platform.OS === 'web' && { lang: locale })}
      style={[
        {
          color: colors.text,
          ...(Platform.OS !== 'web' && { textAlign: 'left' })
        },
        textBaseStylesMap[variant],
        textStyle,
        style
      ]}
      numberOfLines={numberOfLines}
    >
      {children}
    </RNEText>
  )
}
