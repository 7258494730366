// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import type { ProfileStackParamList } from '@/profile/models'
// Views
import Profile from '@/profile/views/Profile'
import EditUserType from '@/profile/views/EditUserType'
import EditCustomFieldAttachments from '@/profile/views/EditCustomFieldAttachments'

const Stack = createStackNavigator<ProfileStackParamList>()

const ProfileStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="Profile"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="Profile" component={Profile} />

      <Stack.Screen name="EditUserType" component={EditUserType} />

      <Stack.Screen
        name="EditCustomFieldAttachments"
        component={EditCustomFieldAttachments}
      />
    </Stack.Navigator>
  )
}

export default ProfileStack
