// External
import LottieView, { type AnimationObject } from 'lottie-react-native'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Platform, ScrollView, StyleSheet, View } from 'react-native'
// Components
import {
  Button,
  // ThemeDropdown,
  LanguageDropdown,
  Text
} from '@/common/components'
// Layouts
import { SafeArea } from './SafeArea'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  animationSource: {
    light: AnimationObject
    dark: AnimationObject
  }
  headerTranslationKey: string
  secondHeaderTranslationKey: string
  textTranslationKey: string
  boldTextTranslationKey?: string
  primaryButtonTranslationKey: string
  primaryButtonOnPress: () => void
  secondaryButtonTranslationKey: string
  secondaryButtonOnPress: () => void
}

export const AnimatedTextScreen = ({
  animationSource,
  headerTranslationKey,
  secondHeaderTranslationKey,
  textTranslationKey,
  boldTextTranslationKey,
  primaryButtonTranslationKey,
  primaryButtonOnPress,
  secondaryButtonTranslationKey,
  secondaryButtonOnPress
}: Props) => {
  const [webDimensions, setWebDimensions] = useState<{
    height: number
    width: number
  }>()
  const { t } = useTranslation()
  const colorScheme = useAppStore((state) => state.colorScheme)

  const translateY = useRef(new Animated.Value(-42)).current

  useEffect(() => {
    Animated.timing(translateY, {
      toValue: 0,
      duration: 700,
      useNativeDriver: true
    }).start()
  }, [])

  return (
    <SafeArea style={{ flex: 1 }} withBackground>
      <ScrollView
        bounces={false}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.dropdowns}>
          <LanguageDropdown />

          {/* <ThemeDropdown /> */}
        </View>

        <View
          style={{ flex: 1 }}
          onLayout={(e) => {
            if (Platform.OS === 'web') {
              setWebDimensions({
                height: e.nativeEvent.layout.height,
                width: e.nativeEvent.layout.width
              })
            }
          }}
        >
          {Platform.OS !== 'web' ? (
            <LottieView
              style={{ flex: 1 }}
              autoPlay
              source={animationSource[colorScheme]}
            />
          ) : Platform.OS === 'web' && webDimensions !== undefined ? (
            <LottieView
              webStyle={{
                height: webDimensions.height,
                width: webDimensions.width
              }}
              autoPlay
              loop
              source={animationSource[colorScheme]}
            />
          ) : null}
        </View>

        <View style={{ overflow: 'hidden' }}>
          <Animated.View style={{ transform: [{ translateY }] }}>
            <Text variant="h1Bold">{t(headerTranslationKey)}</Text>
          </Animated.View>
        </View>

        <View style={styles.secondHeaderContainer}>
          <Animated.View style={{ transform: [{ translateY }] }}>
            <Text variant="h1Bold">{t(secondHeaderTranslationKey)}</Text>
          </Animated.View>
        </View>

        <View style={{ marginBottom: 20 }}>
          <Text>{t(textTranslationKey)}</Text>

          {boldTextTranslationKey !== undefined && (
            <Text variant="baseBold">{t(boldTextTranslationKey)}</Text>
          )}
        </View>

        <Button
          title={t(primaryButtonTranslationKey)}
          style={{ marginBottom: 14 }}
          onPress={primaryButtonOnPress}
        />

        <Button
          type="secondary"
          title={t(secondaryButtonTranslationKey)}
          style={{ marginBottom: 25 }}
          onPress={secondaryButtonOnPress}
        />
      </ScrollView>
    </SafeArea>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    paddingHorizontal: 25
  },
  dropdowns: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 16,
    marginTop: 11
  },
  secondHeaderContainer: {
    marginBottom: 20,
    overflow: 'hidden'
  }
})
