import { client } from '@/common/config'

export const getFacilityFilters = async () => {
  const { data, error } = await client.GET('/api/auth/app/facility/filters')
  if (error !== undefined) {
    console.error('Error getting facility filters:', error)
    return await Promise.reject(error)
  }

  return data.data
}
