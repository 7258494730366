import { client } from '@/common/config'
import { type PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  facilityId: string
}

export const getHosts = async ({
  facilityId,
  page,
  limit = 5,
  search
}: Params) => {
  const { data, error } = await client.GET(
    '/api/kiosk/visit/{facilityId}/hosts',
    {
      params: {
        path: {
          facilityId
        },
        query: {
          page,
          limit,
          search
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting hosts:', error)
    return await Promise.reject(error)
  }
  return data
}
