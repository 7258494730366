// External
import { Divider, Skeleton } from '@rneui/themed'
import { FlashList } from '@shopify/flash-list'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { type SheetProps, SheetManager } from 'react-native-actions-sheet'
// Components
import { Button, NoResults, SearchBar, SelectOption } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Models
import type { Option } from '@/common/models'
// Utils
import { getResultsFromInfiniteQuery } from '@/common/utils'

export const MultipleInfiniteSelectSheet = ({
  payload,
  sheetId
}: SheetProps<'multiple-infinite-select'>) => {
  const [search, setSearch] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    payload?.selectedOptions ?? []
  )
  const { t } = useTranslation()

  const { queryKey, queryFn, getOptions, noResultsTranslationKey } = payload!

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: [...queryKey, search],
    queryFn: async ({ pageParam = 1 }) =>
      await queryFn({
        search,
        page: pageParam
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage: any) => {
      if ((lastPage.hasNextPage ?? false) === false) {
        return undefined
      }

      return lastPage.nextPage
    }
  })
  const results = getResultsFromInfiniteQuery(data)

  const renderItem = useCallback(
    ({ item }: { item: Option }) => (
      <SelectOption
        selected={
          selectedOptions.find((option) => option.value === item.value) !==
          undefined
        }
        option={item}
        onPress={() => {
          const index = selectedOptions.findIndex(
            (option) => option.value === item.value
          )
          if (index === -1) {
            setSelectedOptions([...selectedOptions, item])
          } else {
            setSelectedOptions(
              selectedOptions.filter((option) => option.value !== item.value)
            )
          }
        }}
      />
    ),
    [selectedOptions]
  )

  const onEndReached = useCallback(() => {
    if (hasNextPage) {
      void fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  return (
    <ActionSheetContainer id={sheetId}>
      {payload?.searchable === true && (
        <SearchBar
          value={search}
          onChangeText={setSearch}
          placeholder={payload?.searchPlaceholder}
        />
      )}

      <View style={{ height: 248 }}>
        <FlashList
          bounces={false}
          contentContainerStyle={{
            paddingBottom: 20,
            paddingTop: payload?.searchable === true ? 20 : 0
          }}
          data={getOptions(results)}
          extraData={selectedOptions}
          renderItem={renderItem}
          keyExtractor={(_, index) => index.toString()}
          ItemSeparatorComponent={() => <Divider />}
          onEndReached={onEndReached}
          {...(!isLoading &&
            noResultsTranslationKey != null && {
              ListEmptyComponent: (
                <NoResults
                  messageTranslationKey={noResultsTranslationKey}
                  search={search}
                />
              )
            })}
          {...(isLoading && {
            ListEmptyComponent: (
              <View style={{ gap: 20 }}>
                <Skeleton animation="wave" width="100%" height={62} />

                <Skeleton animation="wave" width="100%" height={62} />

                <Skeleton animation="wave" width="100%" height={62} />
              </View>
            )
          })}
          estimatedItemSize={62}
        />
      </View>

      <Button
        title={t('saveChanges')}
        onPress={() => {
          void SheetManager.hide(sheetId, {
            payload: selectedOptions
          })
        }}
      />
    </ActionSheetContainer>
  )
}
