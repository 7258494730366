// External
import { type StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, Pressable, Keyboard, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { object, string, type InferType } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  Text,
  ProgressBar,
  PhoneNumberInput,
  StepNavigationButtons
} from '@/common/components'
// Constants
import { phoneNumberRegEx } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep3'>

const CreateAccountStep3 = ({ navigation, route }: Props) => {
  const { t } = useTranslation()

  const validationSchema = object({
    prefix: string().required(),
    phone: string()
      .matches(phoneNumberRegEx, t('phoneNumberRegExError'))
      .required(t('provideMobileNumber'))
  })

  type FormValues = InferType<typeof validationSchema>

  const onSubmit = ({ prefix, phone }: FormValues) => {
    navigation.navigate('CreateAccountStep4', {
      ...route.params,
      phoneNumber: {
        prefix,
        phone
      }
    })
  }

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      prefix: '34',
      phone: ''
    }
  })

  const children = (
    <>
      <ProgressBar style={{ marginTop: 11 }} value={0.6} />

      <Text variant="h2Bold" style={styles.header}>
        {t('mobileNumber')}
      </Text>

      <Text>{t('fillPhoneNumber')}</Text>

      <Text style={{ marginBottom: 25 }}>{t('whatYouSeeIn')}</Text>

      <PhoneNumberInput
        prefixValue={watch('prefix')}
        value={watch('phone')}
        onChangePrefix={(option) => {
          setValue('prefix', option.value)
        }}
        onChangePhone={(phone) => {
          setValue('phone', phone)
        }}
        errorMessage={errors.phone?.message}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </>
  )

  return (
    <SafeArea style={styles.container} withBackground>
      {Platform.OS !== 'web' ? (
        <Pressable
          style={{ flex: 1 }}
          onPress={() => {
            Keyboard.dismiss()
          }}
        >
          {children}
        </Pressable>
      ) : (
        children
      )}
    </SafeArea>
  )
}

export default CreateAccountStep3

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
