import { client } from '@/common/config'

export const getRequestCount = async () => {
  const { data, error } = await client.GET('/api/app/visit-requests/count')

  if (error !== undefined) {
    console.error('Error getting request count:', error)
    return
  }

  return data.data
}
