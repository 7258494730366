import { client } from '@/common/config'

export const getAllSuspensions = async () => {
  const { data, error } = await client.GET('/api/app/suspensions/checker')

  if (error !== undefined) {
    console.error('Error getting all suspensions:', error)
    return await Promise.reject(error)
  }

  return data.data
}
