import { type ReactNode } from 'react'
import { create } from 'zustand'

interface PortalState {
  modalVisible: boolean
  modalChildren: ReactNode
  modalDismissable: boolean
  showModal: ({
    content,
    dismissable
  }: {
    content: ReactNode
    dismissable?: boolean
  }) => void
  hideModal: () => void
}

const usePortalStore = create<PortalState>()((set) => ({
  modalVisible: false,
  modalChildren: null,
  modalDismissable: true,
  showModal: ({ content, dismissable = true }) => {
    set({
      modalVisible: true,
      modalChildren: content,
      modalDismissable: dismissable
    })
  },
  hideModal: () => {
    set({ modalVisible: false, modalDismissable: true })
  }
}))
export default usePortalStore
