enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface PaginatedGetParams {
  page: number
  limit?: number
  sortField?: string
  sortOrder?: SortOrder
  filter?: Record<string, any>
  search?: string
}
