// External
import MapView, { PROVIDER_GOOGLE, Overlay } from 'react-native-maps'
import { Platform } from 'react-native'
// Layouts
import { MapScreen } from '@/common/layouts'
// Models
import type { components } from '@/common/models'
// Stores
import useGeopositionConfigStore from '@/geoposition/stores/useGeopositionConfigStore'

const Geoposition = () => {
  const geopositionConfig = useGeopositionConfigStore(
    (state) => state.geopositionConfig
  ) as components['schemas']['GeopositionConfiguration']

  const { overlay } = geopositionConfig
  const bounds = Platform.select({
    android: [
      [overlay.bounds.rightBottomLatitude, overlay.bounds.leftTopLongitude],
      [overlay.bounds.leftTopLatitude, overlay.bounds.rightBottomLongitude]
    ],
    ios: [
      [overlay.bounds.leftTopLatitude, overlay.bounds.leftTopLongitude],
      [overlay.bounds.rightBottomLatitude, overlay.bounds.rightBottomLongitude]
    ]
  })

  return (
    <MapScreen topBarTitleTranslationKey="geoposition">
      <MapView
        style={{ flex: 1 }}
        provider={Platform.OS === 'web' ? 'google' : PROVIDER_GOOGLE}
        showsCompass
        showsMyLocationButton
        showsUserLocation
        followsUserLocation
        showsBuildings={false}
        showsPointsOfInterest={false}
        showsIndoors={false}
        loadingEnabled
        customMapStyle={require('@assets/json/geopositionMapStyle.json')}
        initialCamera={overlay.camera}
      >
        {Platform.OS !== 'web' && (
          <Overlay
            bearing={overlay.bearing}
            // @ts-expect-error - it works sooooooo
            bounds={bounds}
            image={{ uri: overlay.overlayUrl }}
          />
        )}
      </MapView>
    </MapScreen>
  )
}
export default Geoposition
