// External
import { TouchableOpacity, StyleSheet, type ViewStyle } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
// Components
import { Icon } from './Icon'
// Constants
import { colors, gradients } from '@/common/constants'
// Models
import type { StowlogIcon } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  style?: ViewStyle
  size?: number
  iconName: StowlogIcon
  onPress: () => void
}

export const CircleIconButton = ({
  style,
  size = 52,
  iconName,
  onPress
}: Props) => {
  const colorScheme = useAppStore((state) => state.colorScheme)

  const gradientProps =
    colorScheme === 'light'
      ? gradients.lightCircleIconButton
      : gradients.darkCircleIconButton

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.shadow,
        {
          shadowOpacity: colorScheme === 'light' ? 0.1 : 0.2
        },
        style
      ]}
    >
      <LinearGradient
        {...gradientProps}
        style={[
          styles.gradient,
          {
            borderColor:
              colorScheme === 'light'
                ? colors.inactive
                : colors.inactive5Percent,
            height: size,
            width: size,
            borderRadius: size / 2
          }
        ]}
      >
        <Icon
          name={iconName}
          size={16}
          color={colorScheme === 'light' ? colors.text : colors.white}
        />
      </LinearGradient>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  shadow: {
    elevation: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowRadius: 4
  },
  gradient: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1
  }
})
