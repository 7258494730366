// External
import { type NavigationProp, useNavigation } from '@react-navigation/native'
// Components
import { SquareIconButton } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type {
  FacilityFilters,
  FindFacilityDrawerParamList
} from '@/findFacility/models'

interface Props {
  filters: FacilityFilters
}

export const FiltersButton = ({ filters }: Props) => {
  const navigation =
    useNavigation<NavigationProp<FindFacilityDrawerParamList>>()

  return (
    <SquareIconButton
      {...((filters.region !== null ||
        filters.country !== null ||
        filters.type !== null) && {
        style: { borderColor: colors.hydro, borderWidth: 1.5 }
      })}
      iconName="filter"
      onPress={() => {
        navigation.navigate('Filters', filters)
      }}
    />
  )
}
