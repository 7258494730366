// External
import { LinearProgress, type LinearProgressProps } from '@rneui/themed'
// hooks
import useIsRTL from '@/common/hooks/useIsRTL'

export const ProgressBar = ({ style, value }: LinearProgressProps) => {
  const isRTL = useIsRTL()

  return (
    <LinearProgress
      animation={false}
      style={[
        {
          ...(isRTL && {
            transform: [{ scaleX: -1 }]
          })
        },
        style
      ]}
      value={value}
    />
  )
}
