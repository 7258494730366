// External
import type { StackScreenProps } from '@react-navigation/stack'
import { ScrollView, View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useQuery } from '@tanstack/react-query'
// Components
import { TopBar, Text, Avatar, FacilityTimezone } from '@/common/components'
import { CancelVisit } from '@/visit/components/CancelVisit'
// Constants
import { colors } from '@/common/constants'
// Models
import type { VisitStackParamList } from '@/visit/models'
import { type StowlogIcon, VisitRequestStatus } from '@/common/models'
// Services
import { getVisitDetail } from '@/visit/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { formatDate } from '@/common/utils'

type Props = StackScreenProps<VisitStackParamList, 'VisitDetail'>

const VisitDetail = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { showModal } = usePortalStore((state) => ({
    showModal: state.showModal
  }))
  const { t } = useTranslation()
  const { top, bottom, left, right } = useSafeAreaInsets()

  const { data } = useQuery({
    queryKey: ['visit', route.params.visitUUID],
    queryFn: async () => await getVisitDetail(route.params.visitUUID)
  })

  return (
    <View style={{ flex: 1 }}>
      <TopBar
        style={{
          paddingTop: top,
          paddingLeft: left + 25,
          paddingRight: right + 25
        }}
        title={t('visit')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
        rightIcons={[
          ...(data !== undefined &&
          [VisitRequestStatus.PENDING].includes(data.status)
            ? [
                {
                  name: 'visit-cancelled' as StowlogIcon,
                  onPress: () => {
                    showModal({
                      content: (
                        <CancelVisit
                          visitUUID={data.uuid}
                          onCancel={() => {
                            navigation.goBack()
                          }}
                        />
                      )
                    })
                  }
                }
              ]
            : [])
        ]}
      />

      {data === undefined ? null : (
        <ScrollView
          bounces={false}
          contentContainerStyle={{
            paddingTop: 36,
            paddingLeft: left + 25,
            paddingRight: right + 25,
            paddingBottom: bottom + 25,
            gap: 24
          }}
        >
          <FacilityTimezone />

          <View style={styles.checkInCheckOut}>
            {['checkIn', 'checkOut'].map((key) => (
              <View style={{ flex: 1 }} key={key}>
                <Text variant="baseBold">
                  {t(key === 'checkIn' ? 'checkin' : 'checkout')}
                </Text>

                <Text variant="small" color={colors.placeholder}>
                  {data?.[key as keyof typeof data] !== undefined
                    ? formatDate(
                        new Date(data[key as keyof typeof data] as string),
                        'Pp',
                        currentFacility?.info.timezone
                      )
                    : '-'}
                </Text>
              </View>
            ))}
          </View>

          <View>
            <Text style={{ marginBottom: 8 }} variant="baseBold">
              {t('hosts')}
            </Text>

            <View style={{ gap: 8 }}>
              {data.hosts.map((host, index) => (
                <View key={index} style={styles.host}>
                  <Avatar
                    size={48}
                    initials={
                      host.firstName.charAt(0) + host.lastName.charAt(0)
                    }
                    {...(host.profileImage !== undefined && {
                      source: {
                        uri: host.profileImage
                      }
                    })}
                  />

                  <View>
                    <Text variant="small">{`${host.firstName} ${host.lastName}`}</Text>

                    <Text variant="small" color={colors.placeholder}>
                      {host.email}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          {data.questionAnswers !== undefined &&
            data.questionAnswers.length > 0 && (
              <View>
                <Text style={{ marginBottom: 8 }} variant="baseBold">
                  {t('checkinQuestionsAndAnswers')}
                </Text>

                <View style={{ gap: 8 }}>
                  {data.questionAnswers.map((answer) => (
                    <View key={answer.question}>
                      <Text variant="smallBold">{answer.question}</Text>

                      <Text variant="small">{answer.selectedAnswer}</Text>
                    </View>
                  ))}
                </View>
              </View>
            )}

          {data.cancellationReason !== null &&
            data.cancellationReason !== undefined &&
            data.cancellationReason.length > 0 && (
              <View>
                <Text style={{ marginBottom: 8 }} variant="baseBold">
                  {t('cancelationReason')}
                </Text>

                <Text variant="small">{data.cancellationReason}</Text>
              </View>
            )}

          {data.deniedReason !== null &&
            data.deniedReason !== undefined &&
            data.deniedReason.length > 0 && (
              <View>
                <Text style={{ marginBottom: 8 }} variant="baseBold">
                  {t('denialReason')}
                </Text>

                <Text variant="small">{data.deniedReason}</Text>
              </View>
            )}
        </ScrollView>
      )}
    </View>
  )
}

export default VisitDetail

const styles = StyleSheet.create({
  checkInCheckOut: {
    flexDirection: 'row',
    gap: 8
  },
  host: {
    flexDirection: 'row',
    gap: 16
  }
})
