// External
import { isBefore } from 'date-fns'
// Models
import type { ExtendedPermitStatus } from '@/permitToWork/models'
import { PTWRequestStatus, type components } from '@/common/models'

export const getExtendedPermitStatus = ({
  status,
  ...rest
}:
  | components['schemas']['PTWRequestDetailResponse']
  | components['schemas']['PTWRequestRowItemResponse']): ExtendedPermitStatus => {
  const now = new Date()
  const startDate = new Date(rest.startDate)

  if (status === PTWRequestStatus.APPROVED) {
    if (isBefore(now, startDate)) {
      return 'QUEUED'
    }

    return 'ACTIVE'
  }

  return status
}
