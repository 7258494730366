import { getAllSuspensions } from '@/suspension/services'
import { isAfter } from 'date-fns'

export const getSuspensionStatus = async () => {
  try {
    const suspensions = await getAllSuspensions()

    if (suspensions.length === 0) {
      return false
    }

    for (const suspension of suspensions) {
      if (isAfter(new Date(suspension.returnDate), new Date())) {
        return true
      }
    }

    return false
  } catch (error) {
    return await Promise.reject(error)
  }
}
