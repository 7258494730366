// External
import { useTranslation } from 'react-i18next'
import { ListItem } from '@rneui/themed'
import { View } from 'react-native'
import { useState } from 'react'
// Components
import { Text } from '@/common/components'
import { PermitGenericWorkCard } from '../PermitGenericWorkCard'
// Models
import type { components } from '@/common/models'

interface Props {
  typeOfworks: components['schemas']['PTWRequestDetailResponse']['typeOfWorks']
  genericTypeOfWorks: boolean
}

export const PermitTypeOfWorks = ({
  typeOfworks,
  genericTypeOfWorks
}: Props) => {
  const [expanded, setExpanded] = useState<number>()
  const { t } = useTranslation()

  return (
    <View style={{ marginBottom: 24 }}>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('typeOfWork')}
      </Text>

      {genericTypeOfWorks && <PermitGenericWorkCard compact />}

      {!genericTypeOfWorks &&
        typeOfworks.map(({ _id, label, risks }, index) => (
          <ListItem.Accordion
            key={_id}
            content={<Text variant="small">{label}</Text>}
            containerStyle={{ padding: 0 }}
            isExpanded={expanded === index}
            onPress={() => {
              if (index === expanded) {
                setExpanded(undefined)
              } else {
                setExpanded(index)
              }
            }}
          >
            {risks.map(({ _id, risk }, index, array) => (
              <Text
                key={_id}
                {...(index === array.length - 1 && {
                  style: { marginBottom: 8 }
                })}
                variant="small"
              >{`• ${risk}`}</Text>
            ))}
          </ListItem.Accordion>
        ))}
    </View>
  )
}
