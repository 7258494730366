import {
  fillCustomField,
  uploadCustomFieldAttachments
} from '@/profile/services'
import type { components } from '@/common/models'
import type { ImagePickerAsset } from 'expo-image-picker'

interface Params {
  customFields: Array<components['schemas']['FillAppUserCustomFieldDto']>
  attachmentCustomFields: Array<{
    id: string
    value: ImagePickerAsset[]
  }>
}

export const fillCustomFields = async ({
  customFields,
  attachmentCustomFields
}: Params) => {
  try {
    if (customFields.length > 0) {
      const promises = customFields.map(async (field) => {
        await fillCustomField(field)
      })

      await Promise.all(promises)
    }

    if (attachmentCustomFields.length > 0) {
      const promises = attachmentCustomFields.map(async (field) => {
        await uploadCustomFieldAttachments(field)
      })

      await Promise.all(promises)
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
