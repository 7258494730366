import { useNavigation } from '@react-navigation/native'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Alert } from 'react-native'

interface Params {
  condition: boolean
  onLeave?: () => void
}

const usePreventGoingBack = ({ condition, onLeave }: Params) => {
  const navigation = useNavigation()
  const { t } = useTranslation()

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (condition) {
        e.preventDefault()

        if (Platform.OS === 'web') {
          const confirmLeave = window.confirm(t('youHaveSavedChanges'))
          if (confirmLeave) {
            if (onLeave !== undefined) {
              onLeave()
            }
            navigation.dispatch(e.data.action)
          }
        } else {
          Alert.alert(t('discardChanges'), t('youHaveSavedChanges'), [
            {
              text: t('dontLeave'),
              style: 'cancel'
            },
            {
              text: t('discard'),
              style: 'destructive',
              onPress: () => {
                if (onLeave !== undefined) {
                  onLeave()
                }
                navigation.dispatch(e.data.action)
              }
            }
          ])
        }
      }
    })

    return () => {
      unsubscribe()
    }
  }, [condition, navigation])
}

export default usePreventGoingBack
