import { client } from '@/common/config'

interface Params {
  uuid: string
  value: string
}

export const updateCustomField = async ({ uuid, value }: Params) => {
  const { error } = await client.PATCH('/api/app-user/custom-fields/{uuid}', {
    params: {
      path: {
        uuid
      }
    },
    body: {
      value
    }
  })

  if (error !== undefined) {
    console.error('Error updating custom field:', error)
    return await Promise.reject(error)
  }
}
