import { client } from '@/common/config'

export const getUserType = async (userTypeId: string) => {
  const { data, error } = await client.GET(
    '/api/app/facility-user-types/{id}',
    {
      params: {
        path: {
          id: userTypeId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting user type:', error)
    return await Promise.reject(error)
  }
  return data.data
}
