// External
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Text, Avatar, Icon } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'

interface Props {
  personInChargeContractor: components['schemas']['PTWRequestDetailResponse']['personInChargeContractor']
  personInChargePhoneNumber: components['schemas']['PTWRequestDetailResponse']['personInChargePhoneNumber']
}

export const PermitPersonInCharge = ({
  personInChargeContractor,
  personInChargePhoneNumber
}: Props) => {
  const { t } = useTranslation()
  const { profileImage, firstName, lastName, fullName, email } =
    personInChargeContractor.appUser

  return (
    <View>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('personInCharge')}
      </Text>

      <View style={styles.personInCharge}>
        <Avatar
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          initials={`${firstName[0]}${lastName[0]}`}
          size={80}
          source={profileImage != null ? { uri: profileImage } : undefined}
        />

        <View style={styles.personInChargeData}>
          <Text variant="baseBold" numberOfLines={1}>
            {fullName}
          </Text>

          <Text color={colors.text70Percent} numberOfLines={1}>
            {email}
          </Text>

          <View style={styles.phone}>
            <Icon name="phone" size={16} />

            <Text variant="baseBold">{`+${personInChargePhoneNumber.prefix.toString()} ${
              personInChargePhoneNumber.phone
            }`}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  personInCharge: {
    borderRadius: 7,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 15,
    padding: 8
  },
  personInChargeData: {
    flex: 1,
    alignItems: 'flex-start'
  },
  phone: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    borderRadius: 12,
    borderColor: colors.active,
    borderWidth: 1,
    paddingHorizontal: 8,
    paddingVertical: 4
  }
})
