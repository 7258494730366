import { client } from '@/common/config'

interface Params {
  facilityId: string
  userTypeId: string
}

export const getCheckinQuestions = async ({
  facilityId,
  userTypeId
}: Params) => {
  const { data, error } = await client.GET(
    '/api/kiosk/visit/{facilityId}/{userTypeId}/check-in-questions',
    {
      params: {
        path: {
          facilityId,
          userTypeId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting checkin questions:', error)
    return await Promise.reject(error)
  }

  return data.data
}
