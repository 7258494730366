import { create } from 'zustand'
import { setBadgeCountAsync } from 'expo-notifications'

interface NotificationsState {
  unreadNotifications: number
  setUnreadNotifications: (unreadNotifications: number) => void
}

const useNotificationStore = create<NotificationsState>((set) => ({
  unreadNotifications: 0,
  setUnreadNotifications: (unreadNotifications) => {
    set({ unreadNotifications })
    void setBadgeCountAsync(unreadNotifications)
  }
}))

export default useNotificationStore
