// External
import { memo } from 'react'
import MapView, { PROVIDER_GOOGLE } from 'react-native-maps'
import { type ViewStyle, Platform } from 'react-native'
// Components
import { FindFacilityMapMarker } from './FindFacilityMapMarker'
// Models
import type { Coordinates, components } from '@/common/models'

interface Props {
  style: ViewStyle[]
  facilities: Array<components['schemas']['FacilityListItemResponse']>
  currentPosition?: Coordinates
  onPressFacility: (
    facility: components['schemas']['FacilityListItemResponse']
  ) => void
}

export const FindFacilityMap = memo(
  ({ style, facilities, currentPosition, onPressFacility }: Props) => {
    return (
      <MapView
        {...(currentPosition != null && {
          camera: {
            center: {
              latitude: currentPosition.lat,
              longitude: currentPosition.long
            },
            zoom: 10,
            pitch: 0,
            heading: 0
          }
        })}
        style={style}
        provider={Platform.OS === 'web' ? 'google' : PROVIDER_GOOGLE}
        customMapStyle={require('@assets/json/findFacilityMapStyle.json')}
      >
        {facilities.map((facility) => (
          <FindFacilityMapMarker
            key={facility._id}
            facility={facility}
            onPressFacility={() => {
              onPressFacility(facility)
            }}
          />
        ))}
      </MapView>
    )
  }
)
