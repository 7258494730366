// External
import { StyleSheet, View, Platform } from 'react-native'
import * as Linking from 'expo-linking'
// Components
import { Text } from '@/common/components'
import { NotificationListItemLink } from './NotificationListItemLink'
// Models
import { type components, AppUserNotificationActionType } from '@/common/models'
// Utils
import { onPressNotificationAction } from '@/notifications/utils'

interface Props {
  notification: components['schemas']['AppUserNotification']
}

export const NotificationListItem = ({ notification }: Props) => {
  return (
    <View style={{ gap: 10 }}>
      <Text variant="small">
        {new Date(notification.createdAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })}
      </Text>

      <Text variant="largeBold">{notification.title}</Text>

      <Text>{notification.body}</Text>

      {notification.actionType !== undefined && (
        <>
          {(notification.actionType ===
            AppUserNotificationActionType.OPEN_URL ||
            notification.actionType ===
              AppUserNotificationActionType.PHONE_CALL) &&
            notification.actionData !== undefined && (
              <NotificationListItemLink
                style={{ marginTop: 15 }}
                actionType={notification.actionType}
                text={notification.actionData}
                onPress={() => {
                  void onPressNotificationAction({
                    actionData: notification.actionData,
                    actionType: notification.actionType
                  })
                }}
              />
            )}

          {notification.metadata?.attachments !== undefined &&
            notification.metadata.attachments.length > 0 && (
              <View style={styles.attachmentContainer}>
                {notification.metadata.attachments.map((attachment, index) => (
                  <NotificationListItemLink
                    key={index}
                    actionType={AppUserNotificationActionType.ATTACHMENTS}
                    text={attachment.fileName}
                    onPress={async () => {
                      try {
                        const canOpen = await Linking.canOpenURL(attachment.url)

                        if (!canOpen) {
                          throw new Error('cantOpenTheAttachment')
                        }

                        if (Platform.OS === 'web') {
                          window.open(attachment.url, '_blank')
                          return
                        }

                        await Linking.openURL(attachment.url)
                      } catch (error) {
                        console.error('Error opening attachment:', error)
                      }
                    }}
                  />
                ))}
              </View>
            )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  textContainer: {
    flexShrink: 1,
    gap: 10
  },
  attachmentContainer: {
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15
  }
})
