import { create } from 'zustand'
import type { PTWConfigState } from '@/permitToWork/models'

const usePTWConfigStore = create<PTWConfigState>()((set) => ({
  ptwConfig: undefined,
  setPTWConfig: (ptwConfig) => {
    set({ ptwConfig })
  }
}))

export default usePTWConfigStore
