import { client } from '@/common/config'

export const getFacilityDetail = async (facilityId: string) => {
  const { data, error } = await client.GET(
    '/api/auth/app/facility/detail/{facilityId}',
    {
      params: {
        path: {
          facilityId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting facility:', error)
    return await Promise.reject(error)
  }

  return data.data
}
