// External
import { createIconSet } from '@expo/vector-icons'
import glyphMap from '@assets/json/unicodesMap.json'
import type { ViewStyle } from 'react-native'
// Constants
import { colors } from '@/common/constants'
// Models
import { type StowlogIcon } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const CustomSetIcon = createIconSet(glyphMap, 'Stowlog', 'StowlogIcons.ttf')

interface Props {
  style?: ViewStyle
  name: StowlogIcon
  size?: number
  color?: string
  onPress?: () => void
}

export const Icon = ({ name, size, color, style, onPress }: Props) => {
  const colorScheme = useAppStore((state) => state.colorScheme)

  return (
    <CustomSetIcon
      style={style}
      name={`StowlogIcons-${name}`}
      size={size}
      color={
        color !== undefined
          ? color
          : colorScheme === 'light'
          ? colors.text
          : colors.white
      }
      onPress={onPress}
    />
  )
}
