import { client } from '@/common/config'

export const getAppVersions = async (os: 'android' | 'ios') => {
  const { data, error } = await client.GET('/api/app-versioning/{so}', {
    params: {
      path: {
        so: os
      }
    }
  })

  if (error !== undefined) {
    console.error('Error getting app versions:', error)
    return await Promise.reject(error)
  }

  return data.data
}
