import { useFocusEffect } from '@react-navigation/native'
import {
  type QueryObserverResult,
  type RefetchOptions
} from '@tanstack/react-query'
import { useCallback, useRef } from 'react'

const useRefetchOnFocus = (
  refetchFunctions: Array<
    (options?: RefetchOptions) => Promise<QueryObserverResult<any>>
  >
) => {
  const firstTimeFocus = useRef(true)

  useFocusEffect(
    useCallback(() => {
      const refetchAll = async () => {
        for (const refetch of refetchFunctions) {
          await refetch()
        }
      }

      if (firstTimeFocus.current) {
        firstTimeFocus.current = false
      }

      void refetchAll()
    }, [])
  )
}

export default useRefetchOnFocus
