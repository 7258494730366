// External
import { type StackScreenProps } from '@react-navigation/stack'
// Layouts
import { AnimatedTextScreen } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'Welcome'>

const Welcome = ({ navigation }: Props) => {
  return (
    <AnimatedTextScreen
      animationSource={{
        light: require('@assets/animations/light/welcome.json'),
        dark: require('@assets/animations/dark/welcome.json')
      }}
      headerTranslationKey="welcomeHeader"
      secondHeaderTranslationKey="welcomeSecondHeader"
      textTranslationKey="welcomeText"
      primaryButtonTranslationKey="createAccount"
      primaryButtonOnPress={() => {
        navigation.navigate('CreateAccountStep1')
      }}
      secondaryButtonTranslationKey="signIn"
      secondaryButtonOnPress={() => {
        navigation.navigate('Signin', {
          resetPasswordEmail: undefined
        })
      }}
    />
  )
}

export default Welcome
