import * as Linking from 'expo-linking'
import { toast } from '@/common/constants'

export const openTermsAndConditions = async () => {
  try {
    const canOpen = await Linking.canOpenURL(
      'https://www.stowlog.com/terms-and-conditions'
    )

    if (!canOpen) {
      throw new Error('cantOpenTermsAndConditions')
    }

    await Linking.openURL('https://www.stowlog.com/terms-and-conditions')
  } catch (error: any) {
    toast.showError({
      data: {
        titleTranslationKey: 'error',
        messageTranslationKey: error.message
      }
    })
  }
}
