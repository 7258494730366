// External
import type { StackScreenProps } from '@react-navigation/stack'
import type { CompositeScreenProps } from '@react-navigation/native'
import type { DrawerScreenProps } from '@react-navigation/drawer'
// Layouts
import { SelectUserTypeScreen } from '@/common/layouts'
// Models
import type { ProfileStackParamList } from '@/profile/models'
import type { HomeDrawerParamList } from '@/home/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { logIntoFacility as logIntoFacilityUseCase } from '@/findFacility/useCases'
// Utils
import { handleError } from '@/common/utils'

type Props = CompositeScreenProps<
  StackScreenProps<ProfileStackParamList, 'EditUserType'>,
  DrawerScreenProps<HomeDrawerParamList>
>

const EditUserType = ({ navigation, route }: Props) => {
  return (
    <SelectUserTypeScreen
      onPressBack={() => {
        navigation.goBack()
      }}
      userTypes={route.params.userTypes}
      onSelectUserType={async (type) => {
        try {
          const { user, missingCustomFields } = await logIntoFacilityUseCase(
            type._id
          )
          useAppStore
            .getState()
            .logIntoFacility(
              user,
              type,
              missingCustomFields,
              route.params.facility
            )

          navigation.navigate('Home')
        } catch (error) {
          handleError(error)
        }
      }}
    />
  )
}
export default EditUserType
