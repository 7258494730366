// External
import { useRef } from 'react'
import { type StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, type TextInput } from 'react-native'
import { useTranslation } from 'react-i18next'
import { object, string, type InferType, ref } from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  Text,
  ProgressBar,
  Input,
  StepNavigationButtons
} from '@/common/components'
// Constants
import {
  numbersRegex,
  uppercaseRegex,
  lowercaseRegex,
  symbolRegex,
  noSpaceRegex
} from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep2'>

const CreateAccountStep2 = ({ navigation, route }: Props) => {
  const { t } = useTranslation()
  const repeatPasswordInputRef = useRef<TextInput>(null)

  const validationSchema = object({
    password: string()
      .min(8, t('passwordLengthError', { length: 8 }))
      .matches(noSpaceRegex, t('passwordSpaceError'))
      .matches(uppercaseRegex, t('passwordUppercaseError'))
      .matches(lowercaseRegex, t('passwordLowercaseError'))
      .matches(numbersRegex, t('passwordNumberError'))
      .matches(symbolRegex, t('passwordSymbolError'))
      .required(t('providePassword')),
    repeatPassword: string()
      .oneOf([ref('password')], t('passwordsMustMatch'))
      .required(t('providePassword'))
  })

  type FormValues = InferType<typeof validationSchema>

  const onSubmit = ({ password }: FormValues) => {
    navigation.navigate('CreateAccountStep3', {
      email: route.params.email,
      password
    })
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      repeatPassword: ''
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.4} />

      <Text variant="h2Bold" style={styles.header}>
        {t('createPassword')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('passwordShouldContain')}</Text>

      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            secureTextEntry
            placeholder={t('yourPassword')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatPasswordInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            returnKeyType="next"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.password?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatPassword"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={repeatPasswordInputRef}
            secureTextEntry
            placeholder={t('repeatYourPassword')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            returnKeyType="done"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.repeatPassword?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreateAccountStep2

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
