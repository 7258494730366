import { client } from '@/common/config'

export const getPreArrangementQuestions = async () => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/arrangements-questions/all'
  )

  if (error !== undefined) {
    console.error('Error getting pre arrangement questions:', error)
    return await Promise.reject(error)
  }

  return data.data
}
