// External
import { StyleSheet, View } from 'react-native'
import type { StackScreenProps } from '@react-navigation/stack'
import { type InferType, object, string, date } from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo, useCallback } from 'react'
import {
  addMinutes,
  getYear,
  getDate,
  getMonth,
  addMilliseconds,
  isSameDay,
  getHours,
  getMinutes
} from 'date-fns'
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz'
// Components
import {
  ProgressBar,
  Text,
  PhoneNumberInput,
  DateInput,
  StepNavigationButtons,
  Select
} from '@/common/components'
// Constants
import { phoneNumberRegEx } from '@/common/constants'
import { checkInTimeValues } from '@/visit/constants'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
// Stores
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
import useAppStore from '@/common/stores/useAppStore'
// Layouts
import { SafeArea, KeyboardFix } from '@/common/layouts'
// Utils
import { getCheckInTimeValues } from '@/visit/utils'

type Props = StackScreenProps<PTWStackParamList, 'CreatePermitStepDates'>

const CreatePermitStepDates = ({ navigation, route }: Props) => {
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const getSelectValue = useCallback((value: Date) => {
    const base = addMilliseconds(
      value,
      getTimezoneOffset(currentFacility?.info.timezone as string)
    )
    const hours = base.getUTCHours()
    const minutes = base.getUTCMinutes()
    return (hours * 60 + minutes).toString()
  }, [])

  const formSchema = useMemo(
    () =>
      object({
        startDate: date()
          .required(t('requiredField'))
          .test('startDateInPast', t('startDateCannotBeInPast'), (value) => {
            return value >= new Date()
          }),
        endDate: date().when('startDate', ([startDate]) => {
          if (startDate !== undefined) {
            return date()
              .required(t('requiredField'))
              .min(
                addMinutes(startDate as Date, 15),
                t('endDateMustBeAfterStartDate')
              )
          } else {
            return date().required(t('requiredField'))
          }
        }),
        personInChargePhonePrefix: string().required(),
        personInChargePhone: string()
          .matches(phoneNumberRegEx, t('phoneNumberRegExError'))
          .required(t('provideMobileNumber'))
      }),
    []
  )

  type FormValues = InferType<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      personInChargePhonePrefix:
        route.params.personInChargePhoneNumber.prefix.toString(),
      personInChargePhone: route.params.personInChargePhoneNumber.phone
    }
  })

  const onSubmit = ({
    startDate,
    endDate,
    personInChargePhonePrefix,
    personInChargePhone
  }: FormValues) => {
    const { personInChargePhoneNumber, ...rest } = route.params
    navigation.navigate('CreatePermitStepLocation', {
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString() as string,
      personInChargePhoneNumber: {
        prefix: parseInt(personInChargePhonePrefix),
        phone: personInChargePhone
      },
      ...rest
    })
  }

  const getOnDayPressDate = useCallback(
    (data: { year: number; month: number; day: number }, timezone: string) => {
      const baseDate = zonedTimeToUtc(
        new Date(data.year, data.month - 1, data.day),
        timezone
      )
      const now = new Date()

      if (isSameDay(baseDate, now)) {
        const totalMinutes = getMinutes(now) + getHours(now) * 60

        if (totalMinutes <= 8 * 60) {
          return addMinutes(baseDate, 8 * 60)
        } else {
          const checkInTimeValue = checkInTimeValues.find(
            ({ value }) => parseInt(value) >= totalMinutes
          )
          if (checkInTimeValue !== undefined) {
            return addMinutes(baseDate, parseInt(checkInTimeValue.value))
          }
        }
      } else {
        return addMinutes(baseDate, 8 * 60)
      }
    },
    []
  )

  return (
    <SafeArea>
      <KeyboardFix style={styles.container}>
        <ProgressBar
          style={styles.progressBar}
          value={ptwConfig?.hasPreArrangementsEnabled ?? false ? 0.25 : 0.285}
        />

        <Text variant="h2Bold" style={{ marginBottom: 8 }}>
          {t('createNewRequest')}
        </Text>

        <Text style={{ marginBottom: 25 }}>
          {t('pleaseFillInTheFollowingFields')}
        </Text>

        <Controller
          control={control}
          name="startDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <View>
              <DateInput
                label={t('startDate')}
                value={value}
                minDate={new Date().toDateString()}
                onDayPress={(data) => {
                  const date = getOnDayPressDate(
                    data,
                    currentFacility?.info.timezone as string
                  )
                  onChange(date)
                  onBlur()
                }}
                placeholder={t('selectADate')}
                errorMessage={errors.startDate?.message}
              />

              <Select
                value={getSelectValue(value)}
                disabled={value === undefined}
                label={t('startTime')}
                options={getCheckInTimeValues(isSameDay(value, new Date()))}
                onSelect={(option) => {
                  const baseDate = zonedTimeToUtc(
                    new Date(getYear(value), getMonth(value), getDate(value)),
                    currentFacility?.info.timezone as string
                  )
                  onChange(addMinutes(baseDate, parseInt(option.value)))
                  onBlur()
                }}
                placeholder={t('selectATime')}
                errorMessage={errors.startDate?.message}
              />
            </View>
          )}
        />

        <Controller
          control={control}
          name="endDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <View>
              <DateInput
                label={t('endDate')}
                disabled={watch('startDate') === undefined}
                value={value}
                minDate={new Date().toDateString()}
                onDayPress={(data) => {
                  const date = getOnDayPressDate(
                    data,
                    currentFacility?.info.timezone as string
                  )
                  onChange(date)
                  onBlur()
                }}
                placeholder={t('selectADate')}
                errorMessage={errors.endDate?.message}
              />

              <Select
                {...(value !== undefined && {
                  value: getSelectValue(value)
                })}
                disabled={value === undefined}
                label={t('endTime')}
                options={
                  value !== undefined
                    ? getCheckInTimeValues(isSameDay(value, new Date()))
                    : []
                }
                onSelect={(option) => {
                  if (value === undefined) {
                    return
                  }

                  const baseDate = zonedTimeToUtc(
                    new Date(getYear(value), getMonth(value), getDate(value)),
                    currentFacility?.info.timezone as string
                  )
                  onChange(addMinutes(baseDate, parseInt(option.value)))
                  onBlur()
                }}
                placeholder={t('selectATime')}
                errorMessage={errors.endDate?.message}
              />
            </View>
          )}
        />

        <PhoneNumberInput
          prefixValue={watch('personInChargePhonePrefix')}
          value={watch('personInChargePhone')}
          onChangePrefix={(option) => {
            setValue('personInChargePhonePrefix', option.value)
          }}
          onChangePhone={(phone) => {
            setValue('personInChargePhone', phone)
          }}
          errorMessage={errors.personInChargePhone?.message}
          labelTranslationKey="personInChargePhoneNumber"
        />

        <StepNavigationButtons
          nextOnPress={handleSubmit(onSubmit)}
          nextDisabled={isSubmitting}
        />
      </KeyboardFix>
    </SafeArea>
  )
}

export default CreatePermitStepDates

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  }
})
