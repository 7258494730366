import { client } from '@/common/config'
import type { components } from '@/common/models'

export const fillCustomField = async (
  field: components['schemas']['FillAppUserCustomFieldDto']
) => {
  const { error } = await client.POST('/api/app-user/custom-fields/add', {
    body: field
  })

  if (error !== undefined) {
    return await Promise.reject(error)
  }
}
