import { client } from '@/common/config'

export const getVirtualMap = async () => {
  const { data, error } = await client.GET('/api/virtual-map/configuration/app')

  if (error !== undefined) {
    console.error('Error getting virtual map:', error)
    return await Promise.reject(error)
  }

  return data.data
}
