import { getCustomFields, getMissingCustomFields } from '@/profile/services'
import { type components } from '@/common/models'

export const getUserCustomFields = async (): Promise<
  Array<components['schemas']['GetAppUserCustomFieldResponseItem']>
> => {
  try {
    const filledCustomFields = await getCustomFields()
    const missingCustomFields = await getMissingCustomFields()

    const customFields = [
      ...filledCustomFields,
      ...missingCustomFields.map((field) => ({
        _id: field._id,
        uuid: field.uuid,
        name: field.name,
        regex: field.regex,
        type: field.type,
        isRequired: field.isRequired,
        value: null,
        translation: field.translation,
        customFieldId: field._id
      }))
    ]

    return customFields
  } catch (error) {
    return await Promise.reject(error)
  }
}
