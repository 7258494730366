// External
import DateTimePicker from 'react-native-ui-datepicker'
// Constants
import { colors } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  minDate?: Date
  onDayPress: (day: { year: number; month: number; day: number }) => void
}

export const Calendar = ({ minDate, onDayPress }: Props) => {
  const { locale } = useAppStore((state) => ({
    locale: state.locale
  }))

  return (
    <DateTimePicker
      locale={locale}
      mode="single"
      minDate={minDate}
      maxDate="2100-01-01"
      date={undefined}
      onChange={(params) => {
        if (
          params.date !== undefined &&
          typeof params.date?.toString() === 'string'
        ) {
          const date = new Date(params.date.toString())
          if (date !== undefined) {
            onDayPress({
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              day: date.getDate()
            })
          }
        }
      }}
      selectedItemColor={colors.columbiaBlue}
      headerButtonColor={colors.hydro}
      headerTextStyle={{
        fontFamily: 'PlusJakartaSans_700Bold',
        backgroundColor: colors.columbiaBlue,
        padding: 8,
        borderRadius: 4,
        overflow: 'hidden'
      }}
      weekDaysTextStyle={{
        fontFamily: 'PlusJakartaSans_500Medium'
      }}
      calendarTextStyle={{
        fontFamily: 'PlusJakartaSans_400Regular'
      }}
      selectedTextStyle={{
        fontFamily: 'PlusJakartaSans_500Medium'
      }}
    />
  )
}
