import { create } from 'zustand'
import type { CreatePermitState } from '@/permitToWork/models'

const useCreatePermitStore = create<CreatePermitState>()((set) => ({
  isCreating: false,
  setIsCreating: (isCreating) => {
    set({ isCreating })
  }
}))

export default useCreatePermitStore
