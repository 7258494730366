import { client } from '@/common/config'

export const getPPE = async (facilityId: string) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/ppe/{facilityId}/all',
    {
      params: {
        path: {
          facilityId
        }
      }
    }
  )

  if (error !== undefined) {
    return await Promise.reject(error)
  }

  return data.data
}
