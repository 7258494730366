// External
import { useTranslation } from 'react-i18next'
import { type ViewStyle, TouchableOpacity } from 'react-native'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/common/constants'

interface Props {
  style?: ViewStyle
  onPress: () => void
}

export const DeleteAccountButton = ({ style, onPress }: Props) => {
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 10,
          borderWidth: 1,
          height: 55,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.white,
          borderColor: colors.inactive
        },
        style
      ]}
      onPress={onPress}
    >
      <Text color={colors.error} variant="buttonBold">
        {t('deleteAccount')}
      </Text>
    </TouchableOpacity>
  )
}
