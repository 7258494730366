// External
import { isAfter, isEqual } from 'date-fns'
import * as SecureStore from 'expo-secure-store'
import { Platform } from 'react-native'
// Models
import type { TokenType } from '@/auth/models'

export const getToken = async (type: TokenType) => {
  try {
    if (Platform.OS === 'web') {
      const cookies = document.cookie.split(';').map((cookie) => cookie.trim())
      const cookie = cookies.find((cookie) => cookie.startsWith(`${type}=`))

      if (typeof cookie === 'string' && cookie.length > 0) {
        const token = cookie.split('=')[1]

        if (typeof token === 'string') {
          return token
        }
      }

      return null
    }

    const expiration = await SecureStore.getItemAsync(`${type}_expiration`)
    if (expiration === null) {
      return null
    }
    const expirationDate = new Date(expiration)

    if (
      isEqual(expirationDate, new Date()) ||
      isAfter(new Date(), expirationDate)
    ) {
      return null
    }

    return await SecureStore.getItemAsync(type, {
      keychainAccessible: SecureStore.AFTER_FIRST_UNLOCK
    })
  } catch (error) {
    console.error(`Error getting ${type} token:`, error)
    return null
  }
}
