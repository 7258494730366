import { client } from '@/common/config'

export const getModules = async () => {
  const { data, error } = await client.GET('/api/app-users/modules')

  if (error !== undefined) {
    console.error("Error getting user's modules:", error)
    return await Promise.reject(error)
  }

  return data.data.modules
}
