// External
import { yupResolver } from '@hookform/resolvers/yup'
import { type StackScreenProps } from '@react-navigation/stack'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, type TextInput } from 'react-native'
import { object, ref, string, type InferType } from 'yup'
// Components
import {
  Input,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'
// Services
import { getEmailAvailable } from '@/auth/services'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep1'>

const CreateAccountStep1 = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const repeatEmailInputRef = useRef<TextInput>(null)

  const validationSchema = object({
    email: string().email().required(t('provideEmail')),
    repeatEmail: string()
      .email()
      .oneOf([ref('email')], t('emailsMustMatch'))
      .required(t('provideRepeatEmail'))
  })

  type FormValues = InferType<typeof validationSchema>

  const onSubmit = async ({ email }: FormValues) => {
    try {
      const { available } = await getEmailAvailable(email)

      if (!available) {
        toast.showError({
          data: {
            titleTranslationKey: 'emailNotAvailableTitle',
            messageTranslationKey: 'emailNotAvailableMessage'
          }
        })
        return
      }

      navigation.navigate('CreateAccountStep2', { email })
    } catch (error: any) {
      toast.showError({
        data: {
          titleTranslationKey: 'error',
          messageTranslationKey: error.message
        }
      })
    }
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      repeatEmail: ''
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.2} />

      <Text variant="h2Bold" style={styles.header}>
        {t('createAnAccount')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('typeEmailTwice')}</Text>

      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourEmail')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatEmailInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            inputMode="email"
            returnKeyType="next"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.email?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatEmail"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={repeatEmailInputRef}
            placeholder={t('repeatYourEmail')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            inputMode="email"
            returnKeyType="done"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.repeatEmail?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreateAccountStep1

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
