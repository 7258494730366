import { client } from '@/common/config'

export const getVisitDetail = async (visitUUID: string) => {
  const { data, error } = await client.GET(
    '/api/app/visit-requests/{uuid}/details',
    {
      params: {
        path: {
          uuid: visitUUID
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting visit detail:', error)
    return await Promise.reject(error)
  }

  return data.data
}
