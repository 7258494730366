// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import type { VisitStackParamList } from '@/visit/models'
// Views
import Visits from '@/visit/views/Visits'
import RequestAVisit from '@/visit/views/RequestAVisit'
import VisitDetail from '@/visit/views/VisitDetail'

export const Stack = createStackNavigator<VisitStackParamList>()

const VisitsStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Visits" component={Visits} />

      <Stack.Screen name="RequestAVisit" component={RequestAVisit} />

      <Stack.Screen name="VisitDetail" component={VisitDetail} />
    </Stack.Navigator>
  )
}

export default VisitsStack
