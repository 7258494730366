import * as Sentry from '@sentry/react-native'
import { logOut } from '@/auth/services'
import useAppStore from '@/common/stores/useAppStore'

export const signOut = async () => {
  try {
    await logOut()
    return {
      messageTranslationKey: 'successfulSignout'
    }
  } catch (error) {
    Sentry.captureException(error)
    return {
      messageTranslationKey: 'successfulSignout'
    }
  } finally {
    useAppStore.getState().logOut()
  }
}
