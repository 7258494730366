import { View, StyleSheet } from 'react-native'
import { Icon } from './Icon'
import { colors } from '@/common/constants'
import type { StowlogIcon } from '@/common/models'

interface Props {
  iconName: StowlogIcon
}

export const BackgroundIcon = ({ iconName }: Props) => {
  return (
    <View
      style={[styles.iconContainer, { backgroundColor: colors.columbiaBlue }]}
    >
      <Icon name={iconName} size={100} color={colors.hydro} />
    </View>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    marginBottom: 46,
    padding: 20,
    height: 168,
    width: 168,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
