// External
import { type SheetProps, SheetManager } from 'react-native-actions-sheet'
import { View } from 'react-native'
import { useState, useCallback } from 'react'
import { Divider } from '@rneui/themed'
import { useTranslation } from 'react-i18next'
import { FlashList } from '@shopify/flash-list'
// Components
import { SelectOption, SearchBar, Button, NoResults } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Models
import type { Option } from '@/common/models'

export const MultipleSelectSheet = ({
  payload,
  sheetId
}: SheetProps<'multiple-select'>) => {
  const [search, setSearch] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    payload?.selectedOptions ?? []
  )
  const { t } = useTranslation()

  const data =
    payload?.searchable === true
      ? payload?.options.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase())
        )
      : payload?.options

  const renderItem = useCallback(
    ({ item }: { item: Option }) => (
      <SelectOption
        selected={
          selectedOptions.find((option) => option.value === item.value) !==
          undefined
        }
        option={item}
        onPress={() => {
          const index = selectedOptions.findIndex(
            (option) => option.value === item.value
          )
          if (index === -1) {
            setSelectedOptions([...selectedOptions, item])
          } else {
            setSelectedOptions(
              selectedOptions.filter((option) => option.value !== item.value)
            )
          }
        }}
      />
    ),
    [selectedOptions]
  )

  return (
    <ActionSheetContainer id={sheetId}>
      {payload?.searchable === true && (
        <SearchBar
          value={search}
          onChangeText={setSearch}
          placeholder={payload?.searchPlaceholder}
        />
      )}

      <View style={{ height: 248 }}>
        <FlashList
          bounces={false}
          contentContainerStyle={{
            paddingBottom: 20,
            paddingTop: payload?.searchable === true ? 20 : 0
          }}
          data={data}
          extraData={selectedOptions}
          renderItem={renderItem}
          keyExtractor={(_, index) => index.toString()}
          ItemSeparatorComponent={() => <Divider />}
          {...(payload?.noResultsTranslationKey !== undefined && {
            ListEmptyComponent: (
              <NoResults
                messageTranslationKey={payload.noResultsTranslationKey}
                search={search}
              />
            )
          })}
          estimatedItemSize={62}
        />
      </View>

      <Button
        title={t('saveChanges')}
        onPress={() => {
          void SheetManager.hide(sheetId, {
            payload: selectedOptions
          })
        }}
      />
    </ActionSheetContainer>
  )
}
