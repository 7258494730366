// External
import * as Sentry from '@sentry/react-native'
import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync
} from 'expo-location'
import { useEffect, useState } from 'react'
import { AppState, Platform, type AppStateStatus } from 'react-native'
import BackgroundGeolocation, {
  type Subscription
} from 'react-native-background-geolocation'
// Constants
import { socket } from '@/geoposition/constants'
// Models
import { Modules } from '@/common/models'
import {
  GeopositionEvents,
  type GeopositionRoomPayload
} from '@/geoposition/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useGeopositionConfigStore from '@/geoposition/stores/useGeopositionConfigStore'

const useGeoposition = () => {
  if (Platform.OS === 'web') {
    return
  }

  const { user, currentUserType, currentFacility, currentModules } =
    useAppStore((state) => ({
      user: state.user,
      currentUserType: state.currentUserType,
      currentFacility: state.currentFacility,
      currentModules: state.currentModules
    }))
  const { geopositionConfig } = useGeopositionConfigStore((state) => ({
    geopositionConfig: state.geopositionConfig
  }))
  const [ready, setReady] = useState(false)
  const [permissionsGranted, setPermissionsGranted] = useState(false)

  useEffect(() => {
    BackgroundGeolocation.ready({
      // debug: __DEV__,
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION,
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
      stopOnTerminate: false,
      startOnBoot: true,
      geofenceModeHighAccuracy: true,
      disableLocationAuthorizationAlert: true,
      disableMotionActivityUpdates: true,
      disableStopDetection: true,
      pausesLocationUpdatesAutomatically: false,
      notification: {
        title: 'Stowlog is using your location',
        text: 'You will only be tracked when inside a facility'
      }
    })
      .then(() => {
        setReady(true)
      })
      .catch((error) => {
        console.error('Error readying geoposition:', error)
        Sentry.captureException(error)
      })
  }, [])

  useEffect(() => {
    const getFirstStates = async () => {
      try {
        const fgPermissions = await getForegroundPermissionsAsync()
        const bgPermissions = await getBackgroundPermissionsAsync()

        if (fgPermissions.granted && bgPermissions.granted) {
          setPermissionsGranted(true)
        } else {
          setPermissionsGranted(false)
        }
      } catch (error) {
        console.error(
          'Error getting first value of location permission states:',
          error
        )
        Sentry.captureException(error)
      }
    }

    void getFirstStates()

    const listener = async (nextAppState: AppStateStatus) => {
      const fgPermissions = await getForegroundPermissionsAsync()
      const bgPermissions = await getBackgroundPermissionsAsync()

      if (fgPermissions.granted && bgPermissions.granted) {
        setPermissionsGranted(true)
      } else {
        setPermissionsGranted(false)
      }
    }

    const subscription = AppState.addEventListener('change', (nextAppState) => {
      void listener(nextAppState)
    })

    return () => {
      subscription.remove()
    }
  }, [])

  useEffect(() => {
    if (
      user === undefined ||
      currentUserType === undefined ||
      currentFacility === undefined ||
      !currentModules.includes(Modules.GEOPOSITION) ||
      !ready ||
      !permissionsGranted
    ) {
      return
    }

    /**
     * @description Listen to geofence events and switch between tracking modes
     * @see https://transistorsoft.github.io/react-native-background-geolocation/interfaces/geofence.html#toggling-between-tracking-modes-start-and-startgeofences
     */
    const onGeofence: Subscription = BackgroundGeolocation.onGeofence(
      (geofence) => {
        const { action } = geofence
        // console.log('[Geofence event]')
        // console.log(action)

        if (action === 'ENTER') {
          if (!socket.connected) {
            socket.connect()
          }
          BackgroundGeolocation.start()
            .then(() => {
              BackgroundGeolocation.changePace(true).catch((error) => {
                console.error(
                  'Error changing pace after entering geofence:',
                  error
                )
                Sentry.captureException(error)
              })
            })
            .catch((error) => {
              console.error(
                'Error starting location tracking after entering geofence:',
                error
              )
              Sentry.captureException(error)
            })
        }

        if (action === 'EXIT') {
          BackgroundGeolocation.startGeofences()
            .then(() => {
              BackgroundGeolocation.changePace(false)
                .then(() => {
                  socket.disconnect()
                })
                .catch((error) => {
                  console.error(
                    'Error changing pace after exiting geofence:',
                    error
                  )
                  Sentry.captureException(error)
                })
            })
            .catch((error) => {
              console.error(
                'Error starting geofence detection after exiting geofence:',
                error
              )
              Sentry.captureException(error)
            })
        }
      }
    )

    const onLocation: Subscription = BackgroundGeolocation.onLocation(
      (location) => {
        // console.log('[Location update]')
        // console.log('•socket.connected:', socket.connected)
        // console.log('•coords:', location.coords)
        const payload: GeopositionRoomPayload = {
          location: {
            latitude: location.coords?.latitude ?? 0,
            longitude: location.coords?.longitude ?? 0,
            altitude: location.coords?.altitude ?? null,
            accuracy: location.coords?.accuracy,
            altitudeAccuracy: location.coords.altitude_accuracy ?? null,
            heading: location.coords.heading ?? null,
            speed:
              location.coords.speed === undefined || location.coords.speed < 0
                ? 0
                : location.coords.speed
          },
          facilityId: currentFacility._id,
          user: {
            _id: user._id,
            uuid: user.uuid,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            userTypeSlug: currentUserType.slug,
            profileImage: user.profileImage
          }
        }
        socket.emit(GeopositionEvents.TRACK, payload)
      },
      console.error
    )

    BackgroundGeolocation.addGeofence({
      identifier: 'facility',
      latitude: currentFacility.coordinates.lat,
      longitude: currentFacility.coordinates.long,
      radius: geopositionConfig?.radius ?? 500,
      notifyOnExit: true,
      notifyOnEntry: true
    })
      .then(() => {
        BackgroundGeolocation.startGeofences().catch((error) => {
          console.error('Error starting geofence detection:', error)
          Sentry.captureException(error)
        })
      })
      .catch((error) => {
        console.error("Error adding facility's geofence:", error)
        Sentry.captureException(error)
      })

    return () => {
      if (socket.connected) {
        socket.disconnect()
      }
      onLocation.remove()
      onGeofence.remove()
    }
  }, [
    user,
    currentUserType,
    currentFacility,
    currentModules,
    ready,
    permissionsGranted
  ])
}

export default useGeoposition
