// External
import { useState, useRef, useEffect } from 'react'
import { type StackScreenProps } from '@react-navigation/stack'
import { View, StyleSheet, Animated } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
// Components
import { Text, Button, CircleIconButton, Avatar } from '@/common/components'
import { SOS } from '@/findFacility/components'
// Constants
import { colors, shadows } from '@/common/constants'
// Models
import type { PreHomeStackParamList } from '@/common/models'
// Services
import { getFacilityDetail } from '@/findFacility/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { goToTheFacility } from '@/findFacility/useCases'
// Utils
import { handleError } from '@/common/utils'

const COVER_HEIGHT = 227

type Props = StackScreenProps<PreHomeStackParamList, 'FacilityDetail'>

const FacilityDetail = ({ route, navigation }: Props) => {
  const { setCurrentFacility, logIntoFacility } = useAppStore((state) => ({
    setCurrentFacility: state.setCurrentFacility,
    logIntoFacility: state.logIntoFacility
  }))
  const [goingToFacility, setGoingToFacility] = useState(false)
  const { top, left, right, bottom } = useSafeAreaInsets()
  const { t } = useTranslation()

  const { facilityId } = route.params

  const scrollY = useRef(new Animated.Value(0)).current
  const translateY = scrollY.interpolate({
    inputRange: [-COVER_HEIGHT, 0, COVER_HEIGHT],
    outputRange: [-COVER_HEIGHT / 2, 0, COVER_HEIGHT * 0.5]
  })
  const scale = scrollY.interpolate({
    inputRange: [-COVER_HEIGHT, 0, COVER_HEIGHT],
    outputRange: [2, 1, 1]
  })
  const opacity = scrollY.interpolate({
    inputRange: [0, COVER_HEIGHT],
    outputRange: [0, 0.5]
  })

  const { data, isError, error } = useQuery({
    queryKey: ['facilityDetail', facilityId],
    queryFn: async () => await getFacilityDetail(facilityId)
  })

  useEffect(() => {
    if (isError) {
      handleError(error)
      navigation.goBack()
    }
  }, [isError, error])

  // TODO - Skeleton for this page
  if (data === undefined) {
    return null
  }

  return (
    <>
      <CircleIconButton
        iconName="close"
        onPress={() => {
          navigation.goBack()
        }}
        style={{
          position: 'absolute',
          top: top + 11,
          left: left + 25,
          zIndex: 1
        }}
      />

      <Animated.ScrollView
        style={[{ backgroundColor: '#F9F9FB' }]}
        scrollIndicatorInsets={{ right: 1 }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true }
        )}
        bounces={false}
      >
        <Animated.Image
          style={[
            styles.coverImage,
            {
              height: COVER_HEIGHT,
              transform: [
                {
                  translateY
                },
                {
                  scale
                }
              ]
            }
          ]}
          resizeMode="cover"
          source={{ uri: data.onboarding?.coverImage }}
        />

        <Animated.View
          style={[
            styles.coverImageOverlay,
            {
              opacity,
              height: COVER_HEIGHT,
              transform: [
                {
                  translateY
                }
              ]
            }
          ]}
        />

        <View style={styles.avatarContainer}>
          <Avatar
            size={101}
            borderWidth={3}
            style={styles.avatar}
            source={{ uri: data.onboarding?.avatarUrl }}
          />
        </View>

        <View
          style={[
            styles.infoContainer,
            shadows.default,
            {
              backgroundColor: colors.white,
              paddingLeft: left + 25,
              paddingRight: right + 25
            }
          ]}
        >
          <Text variant="h2Bold" style={{ textAlign: 'center' }}>
            {data.name}
          </Text>

          <Text style={styles.location} color={colors.text70Percent}>
            {data.info.country.region.name}
          </Text>

          <Text style={styles.introduction}>
            {data.onboarding?.introductionText}
          </Text>

          <Button
            title={t('goToTheFacility')}
            disabled={goingToFacility}
            loading={goingToFacility}
            onPress={async () => {
              try {
                setGoingToFacility(true)
                const res = await goToTheFacility(facilityId)
                setGoingToFacility(false)

                if (res !== undefined) {
                  logIntoFacility(
                    res.user,
                    res.userType,
                    res.missingCustomFields,
                    data
                  )
                  return
                }

                setCurrentFacility(data)
              } catch (error) {
                setGoingToFacility(false)
                handleError(error)
              }
            }}
          />
        </View>

        <SOS
          style={{
            paddingTop: 25,
            paddingLeft: left + 25,
            paddingRight: right + 25,
            paddingBottom: bottom + 25
          }}
          facility={data}
        />
      </Animated.ScrollView>
    </>
  )
}

export default FacilityDetail

const styles = StyleSheet.create({
  coverImage: {
    height: '100%',
    width: '100%'
  },
  coverImageOverlay: {
    position: 'absolute',
    backgroundColor: 'black',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  avatar: {
    position: 'absolute',
    top: -50,
    alignSelf: 'center'
  },
  infoContainer: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingBottom: 25
  },
  location: {
    textAlign: 'center',
    marginBottom: 35
  },
  introduction: {
    textAlign: 'center',
    opacity: 0.5,
    marginBottom: 39
  },
  avatarContainer: {
    height: 71,
    backgroundColor: colors.white,
    zIndex: 1
  }
})
