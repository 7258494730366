// External
import { type DrawerScreenProps } from '@react-navigation/drawer'
import { type CompositeScreenProps } from '@react-navigation/native'
import { type StackScreenProps } from '@react-navigation/stack'
import { Divider, Switch } from '@rneui/themed'
import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync
} from 'expo-location'
import { getPermissionsAsync } from 'expo-notifications'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppState,
  type AppStateStatus,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
// Components
import {
  DeleteAccountButton,
  Icon,
  PoweredBy,
  SquareIconButton,
  Text,
  TopBar
} from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'
import type { FindFacilityDrawerParamList } from '@/findFacility/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { EmailVerificationAlert } from '@/auth/components'
import { modifyLocationSettings } from '@/geoposition/utils'
import { modifyNotificationSettings } from '@/notifications/utils'

type Props = CompositeScreenProps<
  DrawerScreenProps<FindFacilityDrawerParamList, 'Settings'>,
  StackScreenProps<PreHomeStackParamList>
>

const Settings = ({ navigation }: Props) => {
  const [isLocationEnabled, setIsLocationEnabled] = useState(false)
  const [areNotificationsEnabled, setAreNotificationsEnabled] = useState(false)
  const { t } = useTranslation()
  const { showModal, hideModal } = usePortalStore((state) => ({
    showModal: state.showModal,
    hideModal: state.hideModal
  }))
  const logOut = useAppStore((state) => state.logOut)

  useEffect(() => {
    const calculatePermissionsStates = async () => {
      const { granted: fgGranted } = await getForegroundPermissionsAsync()
      const { granted: bgGranted } = await getBackgroundPermissionsAsync()

      if (fgGranted && bgGranted) {
        setIsLocationEnabled(true)
      } else {
        setIsLocationEnabled(false)
      }

      const { granted } = await getPermissionsAsync()
      setAreNotificationsEnabled(granted)
    }

    void calculatePermissionsStates()

    const handleAppStateChange = (nextAppState: AppStateStatus) => {
      if (nextAppState === 'active') {
        void calculatePermissionsStates()
      }
    }

    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChange
    )

    return () => {
      subscription.remove()
    }
  }, [])

  return (
    <SafeArea style={{ backgroundColor: '#F9F9FB' }}>
      <TopBar
        style={{ marginHorizontal: 25 }}
        title={t('settings')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
        renderLanguageDropdown
      />

      <EmailVerificationAlert />

      <View style={{ marginHorizontal: 25 }}>
        {Platform.OS !== 'web' && (
          <>
            <Text variant="smallBold" style={{ marginTop: 34 }}>
              {t('systemDetails')}
            </Text>

            <View
              style={[styles.systemDetails, { backgroundColor: colors.white }]}
            >
              <TouchableOpacity
                style={styles.systemDetailsItem}
                onPress={modifyNotificationSettings}
              >
                <SquareIconButton iconName="notifications" />

                <Text variant="mediumBold" style={{ marginEnd: 'auto' }}>
                  {t('notifications')}
                </Text>

                <Switch
                  value={areNotificationsEnabled}
                  onValueChange={modifyNotificationSettings}
                />
              </TouchableOpacity>

              <Divider />

              <TouchableOpacity
                style={styles.systemDetailsItem}
                onPress={modifyLocationSettings}
              >
                <SquareIconButton iconName="location" />

                <Text variant="mediumBold" style={{ marginEnd: 'auto' }}>
                  {t('geoposition')}
                </Text>

                <Switch
                  value={isLocationEnabled}
                  onValueChange={modifyLocationSettings}
                />
              </TouchableOpacity>
            </View>
          </>
        )}

        <DeleteAccountButton
          style={styles.openDeleteModalButton}
          onPress={() => {
            showModal({
              content: (
                <View>
                  <View style={styles.deleteAccountDisclaimer}>
                    <View style={{ flexShrink: 1 }}>
                      <Text variant="smallBold">{t('deleteYourAccount')}</Text>

                      <Text variant="small">
                        {t('deleteAccountDisclaimer')}
                      </Text>
                    </View>

                    <Icon color={colors.error} name="alert-fill" size={32} />
                  </View>

                  <DeleteAccountButton
                    onPress={() => {
                      logOut()
                      toast.showSuccess({
                        data: {
                          titleTranslationKey: 'accountDeletedTitle',
                          messageTranslationKey: 'accountDeletedMessage'
                        }
                      })
                      hideModal()
                    }}
                  />
                </View>
              )
            })
          }}
        />

        <PoweredBy />
      </View>
    </SafeArea>
  )
}

export default Settings

const styles = StyleSheet.create({
  systemDetails: {
    marginTop: 10,
    borderRadius: 7,
    paddingHorizontal: 20
  },
  systemDetailsItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 21,
    gap: 20
  },
  openDeleteModalButton: {
    marginTop: 20,
    marginBottom: 34
  },
  deleteAccountDisclaimer: {
    marginBottom: 34,
    flexDirection: 'row',
    gap: 30,
    justifyContent: 'space-between'
  }
})
