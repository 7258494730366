// External
import { useNavigation } from '@react-navigation/native'
import type { NavigationProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { type ReactNode } from 'react'
// Components
import { TopBar } from '@/common/components'
// Layouts
import { SafeArea } from './SafeArea'
// Models
import type { HomeDrawerParamList } from '@/home/models'
// Stores
import useNotificationStore from '@/notifications/stores/useNotificationsStore'

interface Props {
  children: ReactNode
  topBarTitleTranslationKey: string
}

export const MapScreen = ({ children, topBarTitleTranslationKey }: Props) => {
  const unreadNotifications = useNotificationStore(
    (state) => state.unreadNotifications
  )
  const navigation = useNavigation<NavigationProp<HomeDrawerParamList>>()
  const { t } = useTranslation()

  return (
    <SafeArea edges={['top', 'left', 'right']}>
      <TopBar
        style={{
          paddingHorizontal: 25,
          paddingBottom: 14
        }}
        title={t(topBarTitleTranslationKey)}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
        rightIcons={[
          {
            name: 'notifications',
            onPress: () => {
              navigation.navigate('Notifications')
            },
            badgeText:
              unreadNotifications > 0
                ? unreadNotifications.toString()
                : undefined
          }
        ]}
      />

      {children}
    </SafeArea>
  )
}
