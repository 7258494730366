// External
import { View } from 'react-native'
import Constants from 'expo-constants'
import { useTranslation } from 'react-i18next'
// Components
import { Text } from './Text'
import { Link } from './Link'
// Utils
import { openTermsAndConditions } from '@/common/utils'

export const PoweredBy = () => {
  const { t } = useTranslation()

  return (
    <View style={{ alignItems: 'center' }}>
      <Link style={{ marginBottom: 8 }} onPress={openTermsAndConditions}>
        {t('termsAndConditions')}
      </Link>

      <Text style={{ opacity: 0.5 }} variant="small">
        {t('poweredByEstudioCactus')}
      </Text>

      <Text style={{ opacity: 0.3 }} variant="small">
        {`${Constants.expoConfig?.extra?.mode as string} - V${
          Constants.expoConfig?.version as string
        }`}
      </Text>
    </View>
  )
}
