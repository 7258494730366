import { client } from '@/common/config'

export const getLocations = async () => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/location/all'
  )

  if (error !== undefined) {
    console.error('Error getting locations:', error)
    return await Promise.reject(error)
  }

  return data.data
}
