//  External
import type { StackScreenProps } from '@react-navigation/stack'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { object, array, type InferType, mixed } from 'yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider } from '@rneui/themed'
import { SheetManager } from 'react-native-actions-sheet'
// Components
import {
  Text,
  StepNavigationButtons,
  ProgressBar,
  SelectAttachment,
  Icon
} from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { submitPermitRequest } from '@/permitToWork/useCases'
// Utils
import { handleError, getAttachmentName } from '@/common/utils'

const formSchema = object({
  attachments: array(mixed().required()).required()
})

type FormValues = InferType<typeof formSchema>

type Props = StackScreenProps<PTWStackParamList, 'CreatePermitStepAttachments'>

const CreatePermitStepAttachments = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      attachments: []
    }
  })

  const { fields, append, remove } = useFieldArray<FormValues>({
    control,
    name: 'attachments'
  })

  const onSubmit = async ({ attachments }: FormValues) => {
    try {
      await submitPermitRequest({
        ...route.params,
        facilityId: currentFacility?._id as string,
        attachments
      })

      toast.showSuccess({
        data: {
          messageTranslationKey: 'permitRequested'
        }
      })
      navigation.popToTop()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <SafeArea>
      <View style={{ marginHorizontal: 25 }}>
        <ProgressBar style={styles.progressBar} value={1} />

        <Text style={{ marginBottom: 55 }} variant="h2Bold">
          {t('addAttachmentsToYourRequest')}
        </Text>

        <SelectAttachment
          iconName="upload-cloud"
          iconSize={28}
          text={t('uploadAttachments')}
          onPress={() => {
            void SheetManager.show('upload-options', {
              payload: {
                onImagePickerAsset: (asset) => {
                  append(asset)
                  void SheetManager.hide('upload-options')
                }
              }
            })
          }}
        />

        <Text style={{ marginBottom: 8 }} variant="baseBold">
          {t('files')}
        </Text>

        <Divider />
      </View>

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={styles.attachmentList}
        bounces={false}
      >
        {fields.map((attachment, index) => (
          <View key={index} style={styles.attachmentContainer}>
            <Icon name="file" size={20} />

            <Text style={{ flex: 1 }} numberOfLines={1} variant="small">
              {getAttachmentName({ attachment: attachment as any, index })}
            </Text>

            <Icon
              name="close"
              onPress={() => {
                remove(index)
              }}
            />
          </View>
        ))}
      </ScrollView>

      <StepNavigationButtons
        style={styles.navigationButtons}
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
        nextTitleTranslationKey="submitPermit"
      />
    </SafeArea>
  )
}

export default CreatePermitStepAttachments

const styles = StyleSheet.create({
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  navigationButtons: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  attachmentList: {
    paddingTop: 8,
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  attachmentContainer: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 7,
    borderColor: colors.inactive,
    paddingHorizontal: 15,
    borderWidth: 1,
    gap: 6
  }
})
