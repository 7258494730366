// External
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { Text, Button } from '@/common/components'
// Models
import type { components } from '@/common/models'
// Services
import { forgotPassword } from '@/auth/services'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { handleError } from '@/common/utils'
import { toast } from '@/common/constants'

const useForceResetPassword = (
  user?: components['schemas']['SignInAppUser']
) => {
  const { showModal, hideModal } = usePortalStore((state) => ({
    showModal: state.showModal,
    hideModal: state.hideModal
  }))
  const { t } = useTranslation()
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    if (user !== undefined) {
      if ('forceResetPassword' in user) {
        if (user.forceResetPassword) {
          showModal({
            content: (
              <>
                <Text style={{ marginBottom: 8 }} variant="baseBold">
                  {t('forSecurityReasonsPleaseResetYourPassword')}
                </Text>

                <Text style={{ marginBottom: 24 }}>
                  {t('forceResetPasswordInstructions')}
                </Text>

                <Button
                  disabled={isSending}
                  loading={isSending}
                  title={t('restorePassword')}
                  onPress={async () => {
                    try {
                      setIsSending(true)
                      await forgotPassword(user.email)
                      setIsSending(false)
                      toast.showSuccess({
                        data: {
                          titleTranslationKey: 'forgotPasswordSuccessTitle',
                          messageTranslationKey: 'forgotPasswordSuccessMessage'
                        }
                      })
                      hideModal()
                    } catch (error) {
                      setIsSending(false)
                      handleError(error)
                    }
                  }}
                />
              </>
            )
          })
        }
      }
    }
  }, [user])
}

export default useForceResetPassword
