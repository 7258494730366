export const gradients = {
  lightPrimaryButton: {
    useAngle: true,
    angle: 112.56,
    colors: ['#85AFB1', '#5A8B96', '#2D4449', '#3F4947', '#6B5F4C'],
    locations: [0.0198, 0.1647, 0.5048, 0.7978, 0.9754]
  },
  lightSecondaryButton: {
    useAngle: true,
    angle: 135,
    colors: ['#FFF8F2', '#F6F8F8', '#EDF2F4'],
    locations: [0, 0.5156, 1]
  },
  lightSecondaryButtonBorder: {
    useAngle: true,
    angle: 302.97,
    colors: ['#324A51', '#8BC5B2', '#698E91', '#8D9485', '#E99B55'],
    locations: [0.2934, 0.3622, 0.436, 0.689, 0.825]
  },
  lightIconDropdown: {
    useAngle: true,
    angle: 106.59,
    colors: ['#EAECED', '#FCFDFD'],
    locations: [0, 1]
  },
  lightCircleIconButton: {
    useAngle: true,
    angle: 180,
    colors: ['rgba(255, 255, 255, 0.90)', 'rgba(255, 255, 255, 0.70)']
  },
  lightListFacilityShadow: {
    colors: ['#ECECEC', 'rgba(255, 255, 255, 0.00)'],
    useAngle: true,
    angle: 180,
    locations: [0, 0.2819]
  },
  darkPrimaryButton: {
    useAngle: true,
    angle: 91.99,
    colors: [
      'rgba(255, 167, 95, 0.38)',
      'rgba(55, 56, 51, 0)',
      'rgba(29, 42, 45, 0.660582)',
      'rgba(29, 42, 45, 0.34)',
      'rgba(66, 103, 111, 0.38)'
    ],
    locations: [0, 0.2833, 0.528, 0.7586, 1]
  },
  darkPrimaryButtonBorder: {
    useAngle: true,
    angle: 60,
    colors: ['#FFA75F', '#6CB1BC']
  },
  darkSecondaryButton: {
    useAngle: true,
    angle: 271.62,
    colors: [
      'rgba(90, 139, 150, 0.1)',
      'rgba(90, 139, 150, 0.2)',
      'rgba(90, 139, 150, 0.4)',
      'rgba(90, 139, 150, 0.2)',
      'rgba(90, 139, 150, 0.1)'
    ],
    locations: [0, 0.2485, 0.4943, 0.7296, 1]
  },
  darkIconDropdown: {
    useAngle: true,
    angle: 147.08,
    colors: ['#333F42', 'rgba(51, 63, 66, 0.779153)', 'rgba(51, 63, 66, 0)'],
    locations: [0, 0.4938, 1]
  },
  darkDropdownSelect: {
    useAngle: true,
    angle: 180,
    colors: ['#323D41', 'rgba(50, 61, 65, 0.00)']
  },
  darkCircleIconButton: {
    useAngle: true,
    angle: 180,
    colors: ['#323D41', 'rgba(50, 61, 65, 0.00)']
  },
  darkListFacilityShadow: {
    angle: 180,
    colors: ['#1D2527', 'rgba(29, 37, 39, 0.00)'],
    locations: [0, 0.2819]
  }
}
