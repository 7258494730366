import { type SignInParams } from '@/auth/models'
import { logIn } from '@/auth/services'
import useAppStore from '@/common/stores/useAppStore'

export const signIn = async (params: SignInParams) => {
  try {
    const user = await logIn(params)

    useAppStore.getState().logIn(user)

    return {
      messageTranslationKey: 'successfulSignin'
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
