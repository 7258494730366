import { client } from '@/common/config'
import type { PermitToWorkQuestionAnswers } from '@/common/models'

interface Params {
  facilityId: string
  companyId: string
  areaId?: string
  approverId?: string
  personInChargeContractorId: string
  startDate: string
  endDate: string
  personInChargePhoneNumber: {
    prefix: number
    phone: string
  }
  locationIds: string[]
  otherLocation: string
  description: string
  staffOnSiteContractorIds: string[]
  preArrangementQuestions?: Array<{
    arrangementId: string
    answer: PermitToWorkQuestionAnswers
  }>
  ppeRequirements: string[]
  typeOfWorkRisksIds: string[]
  genericTypeOfWork: boolean
  contractorRequesterId: string
}

export const createPermitRequest = async (params: Params) => {
  const { data, error } = await client.POST(
    '/api/app/permit-to-work/requests',
    {
      body: params
    }
  )

  if (error !== undefined) {
    console.error('Error creating permit request:', error)
    return await Promise.reject(error)
  }

  return data.data.uuid
}
