import { getFacilityUserTypes, getUser } from '@/common/services'
import { getMissingCustomFields } from '@/profile/services'
import {
  selectFacilityUserType,
  selectFacility,
  getVisitedFacilities
} from '@/findFacility/services'

export const goToTheFacility = async (facilityId: string) => {
  try {
    await selectFacility(facilityId)

    const visitedFacilities = await getVisitedFacilities()

    if (visitedFacilities.length > 0) {
      const visitedFacilitiesIds = visitedFacilities.map(
        (facility) => facility.facilityId
      )

      const facilityIndex = visitedFacilitiesIds.indexOf(facilityId)
      if (facilityIndex !== -1) {
        const facilityUserType = visitedFacilities[facilityIndex]?.userType
        const facilityUserTypes = await getFacilityUserTypes()

        if (facilityUserType === undefined) {
          return
        }

        if (
          facilityUserTypes.findIndex(
            (type) => type._id === facilityUserType._id
          ) === -1
        ) {
          return
        }

        await selectFacilityUserType(facilityUserType._id)

        const user = await getUser()

        const missingCustomFields = await getMissingCustomFields()
        return {
          user,
          userType: facilityUserType,
          missingCustomFields
        }
      }
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
