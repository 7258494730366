// External
import { View, type ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
// Components
import { Button } from './Button'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'

interface Props {
  style?: ViewStyle
  nextTitleTranslationKey?: string
  nextOnPress: () => void
  nextDisabled?: boolean
  showNextIcon?: boolean
  backTitleTranslationKey?: string
  backOnPress?: () => void
}

export const StepNavigationButtons = ({
  style,
  nextTitleTranslationKey = 'next',
  nextOnPress,
  nextDisabled,
  showNextIcon = true,
  backTitleTranslationKey,
  backOnPress
}: Props) => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const isRTL = useIsRTL()

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 'auto'
        },
        style
      ]}
    >
      <Button
        compact
        type="secondary"
        onPress={() => {
          if (backOnPress !== undefined) {
            backOnPress()
            return
          }

          navigation.goBack()
        }}
        {...(backTitleTranslationKey !== undefined
          ? { title: t(backTitleTranslationKey) }
          : { iconName: isRTL ? 'next' : 'back' })}
      />

      <Button
        compact
        title={t(nextTitleTranslationKey)}
        onPress={nextOnPress}
        disabled={nextDisabled}
        {...(showNextIcon && {
          iconName: isRTL ? 'back' : 'next'
        })}
      />
    </View>
  )
}
