// External
import type { ImagePickerAsset } from 'expo-image-picker'
import { Platform } from 'react-native'
// Models
import type { PermitToWorkQuestionAnswers } from '@/common/models'
// Services
import {
  createPermitRequest,
  uploadPermitRequestAttachments
} from '@/permitToWork/services'
// Stores
import useCreatePermitStore from '@/permitToWork/stores/useCreatePermitStore'

interface Params {
  facilityId: string
  companyId: string
  areaId?: string
  approverId?: string
  personInChargeContractorId: string
  startDate: string
  endDate: string
  personInChargePhoneNumber: {
    prefix: number
    phone: string
  }
  locationIds: string[]
  otherLocation: string
  description: string
  staffOnSiteContractorIds: string[]
  preArrangementQuestions?: Array<{
    arrangementId: string
    answer: PermitToWorkQuestionAnswers
  }>
  ppeRequirements: string[]
  typeOfWorkRisksIds: string[]
  genericTypeOfWork: boolean
  contractorRequesterId: string
  attachments: ImagePickerAsset[]
}

export const submitPermitRequest = async ({ attachments, ...rest }: Params) => {
  try {
    const uuid = await createPermitRequest(rest)

    if (attachments.length > 0) {
      await uploadPermitRequestAttachments({ uuid, attachments })
    }

    if (Platform.OS === 'web') {
      useCreatePermitStore.getState().setIsCreating(false)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
