import type { ReactNode } from 'react'
import { type ViewStyle } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller'

interface Props {
  children: ReactNode
  style?: ViewStyle
  extraHeight?: number
  contentContainerStyle?: ViewStyle
}

export const KeyboardFix = ({
  children,
  style,
  contentContainerStyle
}: Props) => {
  return (
    <KeyboardAwareScrollView
      bounces={false}
      keyboardShouldPersistTaps="handled"
      style={[{ flex: 1 }, style]}
      contentContainerStyle={[{ flexGrow: 1 }, contentContainerStyle]}
    >
      {children}
    </KeyboardAwareScrollView>
  )
}
