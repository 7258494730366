// External
import { StyleSheet, Platform, View } from 'react-native'
import ActionSheet, { type ActionSheetProps } from 'react-native-actions-sheet'
// Constants
import { colors } from '@/common/constants'
// Hooks
import useIsKeyboardOpen from '@/common/hooks/useIsKeyboardOpen'
import useIsRTL from '@/common/hooks/useIsRTL'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const ActionSheetContainer = ({
  id,
  closable = true,
  keyboardHandlerEnabled = true,
  isModal,
  children
}: ActionSheetProps) => {
  const colorScheme = useAppStore((state) => state.colorScheme)
  const isKeyboardOpen = useIsKeyboardOpen()
  const isRTL = useIsRTL()

  return (
    <ActionSheet
      id={id}
      closable={closable}
      keyboardHandlerEnabled={keyboardHandlerEnabled}
      useBottomSafeAreaPadding={Platform.select({
        ios: !isKeyboardOpen,
        android: true
      })}
      containerStyle={{
        ...styles.container,
        backgroundColor: colorScheme === 'light' ? colors.white : '#293639'
      }}
      isModal={Platform.OS === 'web' ? undefined : isModal}
    >
      {Platform.OS === 'web' ? (
        <View
          style={{ flex: 1 }}
          {...(Platform.OS === 'web' && { dir: isRTL ? 'rtl' : 'ltr' })}
        >
          {children}
        </View>
      ) : (
        children
      )}
    </ActionSheet>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    padding: 25
  }
})
