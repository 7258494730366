// External
import { StyleSheet } from 'react-native'
import type { StackScreenProps } from '@react-navigation/stack'
import { type InferType, object, string, array } from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'
// Components
import {
  ProgressBar,
  Text,
  StepNavigationButtons,
  Input,
  Select
} from '@/common/components'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
// Stores
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
import useAppStore from '@/common/stores/useAppStore'
// Layouts
import { SafeArea } from '@/common/layouts'
import { getLocations } from '@/permitToWork/services'

type Props = StackScreenProps<PTWStackParamList, 'CreatePermitStepLocation'>

const CreatePermitStepLocation = ({ navigation, route }: Props) => {
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const formSchema = object({
    locationIds: array(string().required()).required(),
    otherLocation: string().when('locationIds', {
      is: (locationIds: string[]) => locationIds.length === 0,
      then: () => string().required(t('pleaseProvideLocation')),
      otherwise: () => string()
    }),
    description: string()
      .required(t('requiredField'))
      .min(10, t('descriptionMustBeAtLeast10Characters'))
  })

  type FormValues = InferType<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      locationIds: [],
      otherLocation: '',
      description: ''
    }
  })
  const locationIds = watch('locationIds')

  const onSubmit = ({
    locationIds,
    otherLocation,
    description
  }: FormValues) => {
    navigation.navigate('CreatePermitStaffOnSite', {
      locationIds,
      otherLocation: otherLocation as string,
      description,
      ...route.params
    })
  }

  const { data } = useQuery({
    queryKey: ['locations', currentFacility?._id],
    queryFn: getLocations
  })

  return (
    <SafeArea style={styles.container}>
      <ProgressBar
        style={styles.progressBar}
        value={ptwConfig?.hasPreArrangementsEnabled ?? false ? 0.375 : 0.428}
      />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('createNewRequest')}
      </Text>

      <Text style={{ marginBottom: 25 }}>
        {t('pleaseFillInTheFollowingFields')}
      </Text>

      <Controller
        control={control}
        name="locationIds"
        render={({ field: { onBlur, onChange, value } }) => (
          <Select
            searchable
            searchPlaceholder={t('searchLocations')}
            noResultsTranslationKey="noLocationsFound"
            value={value}
            label={t('locations')}
            options={
              data?.map((location) => ({
                label: location.label,
                value: location._id
              })) ?? []
            }
            onMultipleSelect={(options) => {
              onChange(options.map((option) => option.value))
              onBlur()
            }}
            placeholder={t('selectLocations')}
            errorMessage={errors.locationIds?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="otherLocation"
        render={({ field: { onBlur, onChange, value } }) => (
          <Input
            disabled={locationIds !== undefined && locationIds.length > 0}
            value={value}
            onBlur={onBlur}
            onChangeText={onChange}
            label={t('otherLocation')}
            placeholder={t('typeTheOtherLocation')}
            errorMessage={errors.otherLocation?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field: { onBlur, onChange, value } }) => (
          <Input
            value={value}
            onBlur={onBlur}
            onChangeText={onChange}
            label={t('description')}
            placeholder={t('typeTheWorksDescription')}
            errorMessage={errors.description?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreatePermitStepLocation

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  }
})
