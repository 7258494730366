// External
import { Image, type ImageStyle } from 'react-native'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  style?: ImageStyle
}

export const Logo = ({ style }: Props) => {
  const colorScheme = useAppStore((state) => state.colorScheme)

  return (
    <Image
      source={
        colorScheme === 'light'
          ? require('@assets/images/logo-light.png')
          : require('@assets/images/logo-dark.png')
      }
      style={[style, { width: 128, height: 29 }]}
      resizeMode="contain"
    />
  )
}
