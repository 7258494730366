// External
import { View, Platform, type TextInput } from 'react-native'
import { Input as RNEInput } from '@rneui/themed'
import { useState, forwardRef, type ForwardedRef } from 'react'
import { type IconNode } from '@rneui/base'
// Components
import { Text } from './Text'
import { Icon } from './Icon'
// Constants
import { colors } from '@/common/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Models
import { type InputProps } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const InputInner = (
  {
    style,
    labelStyle,
    label,
    renderErrorMessage = true,
    leftNode,
    leftIcon,
    leftIconOnPress,
    rightIcon,
    rightIconOnPress,
    placeholder,
    onChangeText,
    onSubmitEditing,
    onBlur,
    value,
    maxLength,
    secureTextEntry = false,
    inputMode,
    returnKeyType,
    autoComplete,
    autoCapitalize,
    errorMessage,
    numberOfLines,
    keyboardType,
    disabled = false
  }: InputProps,
  ref: ForwardedRef<TextInput>
) => {
  const [isPressed, setIsPressed] = useState(false)
  const { colorScheme, locale } = useAppStore((state) => ({
    colorScheme: state.colorScheme,
    locale: state.locale
  }))
  const [showSecureText, setShowSecureText] = useState(secureTextEntry)
  const isRTL = useIsRTL()

  const textInputIconProps = {
    iconColor: colorScheme === 'light' ? colors.hydro : colors.columbiaBlue,
    size: 20
  }

  const hasErrors = !isPressed && errorMessage !== undefined

  const getBackgroundColor = () => {
    if (isPressed) {
      if (colorScheme === 'light') {
        return colors.active
      }

      return colors.darkerSlateGray
    }
  }

  const getOutlineColor = () => {
    if (isPressed) {
      return colors.hydro
    }

    if (hasErrors) {
      return colors.error
    }
  }

  return (
    <View style={style}>
      {label !== undefined && (
        <Text
          variant="label"
          style={{
            ...(labelStyle !== undefined && { ...labelStyle })
          }}
        >
          {label}
        </Text>
      )}

      <RNEInput
        disabled={disabled}
        {...(Platform.OS === 'web' && { lang: locale })}
        inputContainerStyle={{
          ...(getBackgroundColor() !== undefined && {
            backgroundColor: getBackgroundColor()
          }),
          ...(getOutlineColor() !== undefined && {
            borderColor: getOutlineColor()
          })
        }}
        inputStyle={{
          ...(getBackgroundColor() !== undefined && {
            backgroundColor: getBackgroundColor()
          }),
          ...(isRTL && { textAlign: 'right' })
        }}
        ref={ref}
        placeholder={placeholder}
        {...(onChangeText !== undefined && {
          onChangeText: (text) => {
            if (inputMode === 'email' || keyboardType === 'email-address') {
              onChangeText(text.trim())
            } else {
              onChangeText(text)
            }
          }
        })}
        onSubmitEditing={onSubmitEditing}
        onBlur={(e) => {
          if (onBlur !== undefined) {
            onBlur(e)

            if (Platform.OS === 'web') {
              setIsPressed(false)
            }
          }
        }}
        value={value}
        maxLength={maxLength}
        secureTextEntry={showSecureText}
        leftIcon={
          leftIcon !== undefined ? (
            <Icon
              color={colors.hydro}
              name={leftIcon}
              {...(leftIconOnPress !== undefined && {
                onPress: leftIconOnPress
              })}
              {...textInputIconProps}
            />
          ) : leftNode !== undefined ? (
            (leftNode as IconNode)
          ) : undefined
        }
        rightIcon={
          rightIcon !== undefined ? (
            <Icon
              color={colors.hydro}
              name={rightIcon}
              {...(rightIconOnPress !== undefined && {
                onPress: rightIconOnPress
              })}
              {...textInputIconProps}
            />
          ) : secureTextEntry ? (
            <Icon
              color={colors.hydro}
              name={showSecureText ? 'show' : 'hide'}
              onPress={() => {
                setShowSecureText((prev) => !prev)
              }}
              {...textInputIconProps}
            />
          ) : undefined
        }
        inputMode={inputMode}
        returnKeyType={returnKeyType}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        onFocus={() => {
          setIsPressed(true)
        }}
        onEndEditing={() => {
          setIsPressed(false)
        }}
        renderErrorMessage={renderErrorMessage}
        errorMessage={errorMessage}
        numberOfLines={numberOfLines}
        keyboardType={keyboardType}
      />
    </View>
  )
}

export const Input = forwardRef(InputInner)
