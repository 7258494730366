import { client } from '@/common/config'

export const logOut = async () => {
  const { error } = await client.POST('/api/auth/app/logout')

  if (error !== undefined) {
    console.error('Error logging out:', error)
    return await Promise.reject(error)
  }
}
