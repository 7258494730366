// External
import { Controller, useFormContext } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
// Components
import { QuestionAnswer, Text } from '@/common/components'
// Models
import type { components } from '@/common/models'

interface Props {
  index: number
  question: components['schemas']['GetSafetyInductionTestResponse']['questions'][0]
}

export const InductionTestQuestion = ({ index, question }: Props) => {
  const { control } = useFormContext<{
    answers: Array<{
      questionId: string
      answerId: string
    }>
  }>()

  return (
    <View>
      <Text variant="mediumBold" style={styles.question}>
        {question.question}
      </Text>

      <View style={{ gap: 17 }}>
        {question.answers.map((answer) => (
          <Controller
            control={control}
            name={`answers.${index}`}
            render={({
              field: {
                onBlur,
                onChange,
                value: { answerId, questionId }
              }
            }) => (
              <QuestionAnswer
                label={answer.answer}
                value={answer.originalAnswerId}
                onSelect={(originalAnswerId) => {
                  onChange({ questionId, answerId: originalAnswerId })
                  onBlur()
                }}
                isSelected={answerId === answer.originalAnswerId}
              />
            )}
          />
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  question: {
    textAlign: 'center',
    marginBottom: 38
  }
})
