import { colors } from '@/common/constants'
import { type CheckBoxProps, CheckBox } from '@rneui/themed'
import { Icon } from './Icon'

export const Checkbox = ({
  style,
  disabled,
  checked,
  onPress
}: CheckBoxProps) => {
  return (
    <CheckBox
      style={style}
      disabled={disabled}
      checked={checked}
      onPress={onPress}
      uncheckedIcon={
        <Icon
          name="checkbox-unchecked"
          size={25}
          color={colors.lightGray20Percent}
        />
      }
      checkedIcon={
        <Icon name="checkbox-checked" size={25} color={colors.hydro} />
      }
    />
  )
}
