// External
import { createStackNavigator } from '@react-navigation/stack'
import { Platform } from 'react-native'
// Models
import { type PreHomeStackParamList } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Navigators
import FindFacilityDrawer from '@/findFacility/navigators/FindFacilityDrawer'
// Views
import Welcome from '@/auth/views/Welcome'
import Signin from '@/auth/views/Signin'
import RestorePassword from '@/auth/views/RestorePassword'
import CreateAccountStep1 from '@/auth/views/CreateAccount/CreateAccountStep1'
import CreateAccountStep2 from '@/auth/views/CreateAccount/CreateAccountStep2'
import CreateAccountStep3 from '@/auth/views/CreateAccount/CreateAccountStep3'
import CreateAccountStep4 from '@/auth/views/CreateAccount/CreateAccountStep4'
import CreateAccountStep5 from '@/auth/views/CreateAccount/CreateAccountStep5'
import ReviewYourInformation from '@/auth/views/ReviewYourInformation'
import PushNotifications from '@/permissions/views/PushNotifications'
import EnableYourLocation from '@/permissions/views/EnableYourLocation'
import FacilityDetail from '@/findFacility/views/FacilityDetail'

export const Stack = createStackNavigator<PreHomeStackParamList>()

const PreHomeStack = () => {
  const { isLoggedIn, askForPermissions } = useAppStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    askForPermissions: state.askForPermissions
  }))

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {!isLoggedIn && (
        <Stack.Group>
          <Stack.Screen name="Welcome" component={Welcome} />

          <Stack.Screen name="Signin" component={Signin} />

          <Stack.Screen name="RestorePassword" component={RestorePassword} />

          <Stack.Screen
            name="CreateAccountStep1"
            component={CreateAccountStep1}
          />

          <Stack.Screen
            name="CreateAccountStep2"
            component={CreateAccountStep2}
          />

          <Stack.Screen
            name="CreateAccountStep3"
            component={CreateAccountStep3}
          />

          <Stack.Screen
            name="CreateAccountStep4"
            component={CreateAccountStep4}
          />

          <Stack.Screen
            name="CreateAccountStep5"
            component={CreateAccountStep5}
          />

          <Stack.Screen
            name="ReviewYourInformation"
            component={ReviewYourInformation}
          />
        </Stack.Group>
      )}

      {askForPermissions && isLoggedIn && Platform.OS !== 'web' && (
        <Stack.Group>
          <Stack.Screen
            name="PushNotifications"
            component={PushNotifications}
          />

          <Stack.Screen
            name="EnableYourLocation"
            component={EnableYourLocation}
          />
        </Stack.Group>
      )}

      {isLoggedIn && (
        <Stack.Group>
          <Stack.Screen
            name="FindFacilityDrawer"
            component={FindFacilityDrawer}
          />

          <Stack.Screen name="FacilityDetail" component={FacilityDetail} />
        </Stack.Group>
      )}
    </Stack.Navigator>
  )
}

export default PreHomeStack
