import type { Coordinates, components } from '@/common/models'

const calculateDistance = (
  point1: Coordinates,
  point2: components['schemas']['FacilityListItemResponse']
) => {
  const earthRadius = 6371

  const lat1 = point1.lat
  const lon1 = point1.long
  const lat2 = point2.coordinates.lat
  const lon2 = point2.coordinates.long

  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return earthRadius * c
}

export const sortFacilitiesByDistance = (
  currentPosition: Coordinates,
  facilities: Array<components['schemas']['FacilityListItemResponse']>
) => {
  return facilities.slice().sort((posA, posB) => {
    const distanceA = calculateDistance(currentPosition, posA)
    const distanceB = calculateDistance(currentPosition, posB)
    return distanceA - distanceB
  })
}
