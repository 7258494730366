import { checkInTimeValues } from '@/visit/constants'

const checkOutTimeValues = [
  ...checkInTimeValues,
  { value: '1440', label: '12:00 AM +1' },
  { value: '1470', label: '12:30 AM +1' },
  { value: '1500', label: '01:00 AM +1' },
  { value: '1530', label: '01:30 AM +1' },
  { value: '1560', label: '02:00 AM +1' },
  { value: '1590', label: '02:30 AM +1' },
  { value: '1620', label: '03:00 AM +1' },
  { value: '1650', label: '03:30 AM +1' },
  { value: '1680', label: '04:00 AM +1' },
  { value: '1710', label: '04:30 AM +1' },
  { value: '1740', label: '05:00 AM +1' },
  { value: '1770', label: '05:30 AM +1' },
  { value: '1800', label: '06:00 AM +1' },
  { value: '1830', label: '06:30 AM +1' },
  { value: '1860', label: '07:00 AM +1' },
  { value: '1890', label: '07:30 AM +1' },
  { value: '1920', label: '08:00 AM +1' },
  { value: '1950', label: '08:30 AM +1' },
  { value: '1980', label: '09:00 AM +1' },
  { value: '2010', label: '09:30 AM +1' },
  { value: '2040', label: '10:00 AM +1' },
  { value: '2070', label: '10:30 AM +1' },
  { value: '2100', label: '11:00 AM +1' },
  { value: '2130', label: '11:30 AM +1' },
  { value: '2160', label: '12:00 PM +1' },
  { value: '2190', label: '12:30 PM +1' },
  { value: '2220', label: '01:00 PM +1' },
  { value: '2250', label: '01:30 PM +1' },
  { value: '2280', label: '02:00 PM +1' },
  { value: '2310', label: '02:30 PM +1' },
  { value: '2340', label: '03:00 PM +1' },
  { value: '2370', label: '03:30 PM +1' },
  { value: '2400', label: '04:00 PM +1' },
  { value: '2430', label: '04:30 PM +1' },
  { value: '2460', label: '05:00 PM +1' },
  { value: '2490', label: '05:30 PM +1' },
  { value: '2520', label: '06:00 PM +1' },
  { value: '2550', label: '06:30 PM +1' },
  { value: '2580', label: '07:00 PM +1' },
  { value: '2610', label: '07:30 PM +1' },
  { value: '2640', label: '08:00 PM +1' },
  { value: '2670', label: '08:30 PM +1' },
  { value: '2700', label: '09:00 PM +1' },
  { value: '2730', label: '09:30 PM +1' },
  { value: '2760', label: '10:00 PM +1' },
  { value: '2790', label: '10:30 PM +1' },
  { value: '2820', label: '11:00 PM +1' },
  { value: '2850', label: '11:30 PM +1' }
]

export const getCheckOutTimeValues = (selectedCheckInValue: string) => {
  const returnStartIndex = checkOutTimeValues.findIndex(
    (time) => time.value === selectedCheckInValue
  )

  if (returnStartIndex >= 0) {
    return checkOutTimeValues.slice(
      returnStartIndex + 1,
      returnStartIndex + 1 + 48
    )
  }

  return checkOutTimeValues
}
