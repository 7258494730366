// External
import type { CompositeScreenProps } from '@react-navigation/native'
import type { StackScreenProps } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { View, ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Divider } from '@rneui/themed'
import { SheetManager } from 'react-native-actions-sheet'
import { zonedTimeToUtc } from 'date-fns-tz'
// Components
import {
  TopBar,
  ActionItem,
  Text,
  Avatar,
  Icon,
  PoweredBy,
  Calendar
} from '@/common/components'
import { EmailVerificationAlert } from '@/auth/components'
// Constants
import { colors, toast, userTypeIcons } from '@/common/constants'
// Hooks
import useCustomFields from '@/profile/hooks/useCustomFields'
// Services
import { getFacilityUserTypes } from '@/common/services'
import { generateEmailChangeRequest } from '@/auth/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Models
import type { HomeDrawerParamList } from '@/home/models'
import type {
  EditAccountDetailsParams,
  ProfileStackParamList
} from '@/profile/models'
import {
  CustomFieldTypes,
  type FacilityUserTypeSlug,
  type components
} from '@/common/models'
// Services
import { updateCustomField } from '@/profile/services'
// Use cases
import { editAccountDetails } from '@/profile/useCases'
// Utils
import { handleError, formatDate } from '@/common/utils'
import usePortalStore from '@/common/stores/usePortalStore'

type Props = CompositeScreenProps<
  StackScreenProps<ProfileStackParamList, 'Profile'>,
  StackScreenProps<HomeDrawerParamList>
>

const Profile = ({ navigation }: Props) => {
  const { top, left, right, bottom } = useSafeAreaInsets()
  const { t } = useTranslation()
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const { showModal, hideModal } = usePortalStore((state) => ({
    showModal: state.showModal,
    hideModal: state.hideModal
  }))
  const { data: customFields, refetch } = useCustomFields()

  const editField = async (params: EditAccountDetailsParams) => {
    try {
      await editAccountDetails(params)
      toast.showSuccess({
        data: {
          messageTranslationKey: t(
            'password' in params ? 'passwordUpdated' : 'profileUpdated'
          )
        }
      })
    } catch (error) {
      handleError(error)
    }
  }

  if (
    user === undefined ||
    currentUserType === undefined ||
    currentFacility === undefined
  ) {
    return null
  }

  const editPhoto = () => {
    void SheetManager.show('edit-profile-photo', {
      payload: {
        onSubmit: (photo) => {
          if (typeof photo === 'string') {
            void SheetManager.hide('edit-profile-photo')
            return
          }

          if (typeof photo === 'object') {
            void editField({ profileImage: photo })
          } else if (photo === undefined) {
            void editField({ profileImage: undefined })
          }

          void SheetManager.hide('edit-profile-photo')
        },
        initialValue: user.profileImage
      }
    })
  }

  const getCFActionItemText = (
    field: components['schemas']['GetBaseArrayResponseForEntityGetAppUserCustomFieldResponseItem']['data'][0]
  ) => {
    if (typeof field.value === 'string') {
      if (field.type === CustomFieldTypes.ATTACHMENTS) {
        return t('attachments', {
          count: field.attachments?.length
        })
      }

      if (field.type === CustomFieldTypes.DATE) {
        return formatDate(new Date(field.value), 'P')
      }

      return field.value
    }

    return ''
  }

  return (
    <View style={{ flex: 1, backgroundColor: '#F9F9FB' }}>
      <TopBar
        style={{
          paddingTop: top,
          paddingLeft: left + 25,
          paddingRight: right + 25
        }}
        leftIcon={{
          name: 'back',
          onPress: () => {
            navigation.goBack()
          }
        }}
        title={t('profile')}
        rightIcons={[
          {
            name: 'qr',
            onPress: () => {
              navigation.navigate('QRCode')
            }
          }
        ]}
      />

      <EmailVerificationAlert />

      <ScrollView
        bounces={false}
        scrollIndicatorInsets={{ right: 1 }}
        contentContainerStyle={{
          paddingTop: 23,
          flexGrow: 1,
          paddingLeft: left + 25,
          paddingRight: right + 25,
          paddingBottom: bottom + 25
        }}
      >
        <View style={styles.photoContainer}>
          <View>
            <Avatar
              size={108}
              {...(user.profileImage !== undefined
                ? {
                    source: {
                      uri: user.profileImage
                    }
                  }
                : {
                    initials: user.firstName.charAt(0) + user.lastName.charAt(0)
                  })}
            />

            <TouchableOpacity
              style={[styles.editPhoto, { backgroundColor: colors.white }]}
              onPress={editPhoto}
            >
              <Icon name="edit" size={13} color={colors.hydro} />
            </TouchableOpacity>
          </View>

          <TouchableOpacity onPress={editPhoto}>
            <Text variant="mediumBold">{t('editPhoto')}</Text>
          </TouchableOpacity>
        </View>

        <Text variant="smallBold" style={{ marginBottom: 10 }}>
          {t('accountDetails')}
        </Text>

        <View
          style={[
            styles.fields,
            { backgroundColor: colors.white, borderColor: colors.inactive }
          ]}
        >
          <ActionItem
            iconName="email"
            title={t('yourEmailAddress')}
            text={user.email}
            onPress={() => {
              void SheetManager.show('edit-email', {
                payload: {
                  onSubmit: async (email) => {
                    try {
                      await generateEmailChangeRequest(email)
                      await SheetManager.hide('edit-email')
                      await SheetManager.show('change-email', {
                        payload: {
                          email
                        }
                      })
                    } catch (error) {
                      handleError(error)
                    }
                  },
                  initialValue: user.email
                }
              })
            }}
          />

          <Divider />

          <ActionItem
            iconName="user-name"
            title={t('yourName')}
            text={`${user.firstName} ${user.lastName}`}
            onPress={() => {
              void SheetManager.show('edit-name', {
                payload: {
                  onSubmit: async ({ firstName, lastName }) => {
                    await editField({ firstName, lastName })
                    void SheetManager.hide('edit-name')
                  },
                  initialValues: {
                    firstName: user.firstName,
                    lastName: user.lastName
                  }
                }
              })
            }}
          />

          <Divider />

          <ActionItem
            iconName="password"
            title={t('password')}
            text="************"
            onPress={() => {
              void SheetManager.show('edit-password', {
                payload: {
                  onSubmit: async ({ password }) => {
                    await editField({ password })
                    void SheetManager.hide('edit-password')
                  }
                }
              })
            }}
          />

          <Divider />

          <ActionItem
            iconName="phone-number"
            title={t('mobileNumber')}
            {...(user.phoneNumber !== undefined && {
              text: `+${user.phoneNumber.prefix} ${user.phoneNumber.phone}`
            })}
            onPress={() => {
              void SheetManager.show('edit-number', {
                payload: {
                  onSubmit: async ({ prefix, phone }) => {
                    await editField({
                      phoneNumber: { prefix: parseInt(prefix), phone }
                    })
                    void SheetManager.hide('edit-number')
                  },
                  initialValues:
                    user.phoneNumber !== undefined
                      ? {
                          prefix: user.phoneNumber.prefix,
                          phone: user.phoneNumber.phone
                        }
                      : undefined
                }
              })
            }}
          />
        </View>

        {customFields !== undefined && customFields.length > 0 && (
          <>
            <Text variant="smallBold" style={{ marginBottom: 10 }}>
              {t('extraFields')}
            </Text>

            <View
              style={[
                styles.fields,
                { backgroundColor: colors.white, borderColor: colors.inactive }
              ]}
            >
              {customFields.map((field) => (
                <ActionItem
                  key={field.uuid}
                  iconName="extra-field"
                  title={field.translation.name}
                  text={getCFActionItemText(field)}
                  onPress={() => {
                    if (field.type === CustomFieldTypes.ATTACHMENTS) {
                      navigation.navigate('EditCustomFieldAttachments', {
                        field
                      })
                      return
                    }

                    if (field.type === CustomFieldTypes.DATE) {
                      showModal({
                        content: (
                          <Calendar
                            onDayPress={async (data) => {
                              try {
                                let date = new Date(
                                  data.year,
                                  data.month - 1,
                                  data.day
                                )

                                if (
                                  currentFacility?.info.timezone !== undefined
                                ) {
                                  date = zonedTimeToUtc(
                                    new Date(
                                      data.year,
                                      data.month - 1,
                                      data.day
                                    ),
                                    currentFacility?.info.timezone
                                  )
                                }

                                await updateCustomField({
                                  uuid: field.uuid,
                                  value: date.toISOString()
                                })
                                await refetch()
                                toast.showSuccess({
                                  data: {
                                    messageTranslationKey: 'profileUpdated'
                                  }
                                })
                                hideModal()
                              } catch (error) {
                                handleError(error)
                              }
                            }}
                          />
                        )
                      })
                      return
                    }

                    void SheetManager.show('edit-custom-field', {
                      payload: {
                        field,
                        refetch,
                        isFilled: field.value !== null
                      }
                    })
                  }}
                />
              ))}
            </View>
          </>
        )}

        <Text variant="smallBold" style={{ marginBottom: 10 }}>
          {t('facilityDetails')}
        </Text>

        <View
          style={[
            styles.fields,
            { backgroundColor: colors.white, borderColor: colors.inactive }
          ]}
        >
          <ActionItem
            iconName={
              userTypeIcons[currentUserType.slug as FacilityUserTypeSlug]
            }
            iconSize={30}
            title={t('userType')}
            text={t(`userTypes.${currentUserType.slug}`)}
            onPress={async () => {
              try {
                const userTypes = await getFacilityUserTypes()
                navigation.navigate('EditUserType', {
                  facility: currentFacility,
                  userTypes
                })
              } catch (error) {
                handleError(error)
                navigation.goBack()
              }
            }}
          />
        </View>

        <PoweredBy />
      </ScrollView>
    </View>
  )
}
export default Profile

const styles = StyleSheet.create({
  photoContainer: {
    alignItems: 'center',
    marginBottom: 34,
    gap: 14
  },
  editPhoto: {
    position: 'absolute',
    bottom: 10,
    end: 10,
    height: 27,
    width: 27,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 13.5
  },
  fields: {
    padding: 20,
    borderRadius: 7,
    borderWidth: 1,
    gap: 20,
    marginBottom: 34
  }
})
