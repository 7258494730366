import { client } from '@/common/config'
import type { components } from '@/common/models'

interface Params {
  uuid: string
  body: components['schemas']['UpdatePTWRequestStaffOnSiteDto']
}

export const addStaffOnSiteForRequest = async ({ uuid, body }: Params) => {
  const { data, error } = await client.PATCH(
    '/api/app/permit-to-work/requests/{uuid}/staff-on-site/add',
    {
      params: {
        path: {
          uuid
        }
      },
      body
    }
  )

  if (error !== undefined) {
    console.error('Error creating permit request:', error)
    return await Promise.reject(error)
  }

  return data
}
