import { client } from '@/common/config'

export const getVisitedFacilities = async () => {
  const { data, error } = await client.GET(
    `/api/auth/app/facility/last-visited`
  )

  if (error != null) {
    console.error('Error getting visited facilities:', error)
    return await Promise.reject(error)
  }

  return data.data
}
