// External
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Text, QuestionAnswer } from '@/common/components'
// Constants
import { ptwAnswersMap } from '@/permitToWork/constants'
// Models
import { PermitToWorkQuestionAnswers } from '@/common/models'

interface Props {
  value?: PermitToWorkQuestionAnswers
  question: string
  onSelectAnswer: (answer: PermitToWorkQuestionAnswers) => void
}

export const PermitPreArrangementQuestion = ({
  value,
  question,
  onSelectAnswer
}: Props) => {
  const { t } = useTranslation()
  return (
    <View>
      <Text variant="mediumBold" style={styles.question}>
        {question}
      </Text>

      <View style={{ gap: 17 }}>
        <QuestionAnswer
          label={t(ptwAnswersMap[PermitToWorkQuestionAnswers.YES])}
          value={PermitToWorkQuestionAnswers.YES.toString()}
          onSelect={(value) => {
            onSelectAnswer(parseInt(value))
          }}
          isSelected={value === PermitToWorkQuestionAnswers.YES}
        />

        <QuestionAnswer
          label={t(ptwAnswersMap[PermitToWorkQuestionAnswers.NO])}
          value={PermitToWorkQuestionAnswers.NO.toString()}
          onSelect={(value) => {
            onSelectAnswer(parseInt(value))
          }}
          isSelected={value === PermitToWorkQuestionAnswers.NO}
        />

        <QuestionAnswer
          label={t(ptwAnswersMap[PermitToWorkQuestionAnswers.NA])}
          value={PermitToWorkQuestionAnswers.NA.toString()}
          onSelect={(value) => {
            onSelectAnswer(parseInt(value))
          }}
          isSelected={value === PermitToWorkQuestionAnswers.NA}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  question: {
    textAlign: 'center',
    marginBottom: 38
  }
})
