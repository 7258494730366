// External
import { Platform, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import * as Linking from 'expo-linking'
// Components
import { Button } from './Button'
import { Text } from './Text'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'

const storeUpdateLink = Platform.select({
  ios: 'itms-apps://apps.apple.com/app/stowlog/id6463200501',
  android:
    'https://play.google.com/store/apps/details?id=com.estudiocactus.stowlog'
}) as string

interface Props {
  isBelowMin: boolean
}

export const UpdateAppVersion = ({ isBelowMin }: Props) => {
  const showModal = usePortalStore((state) => state.showModal)
  const { t } = useTranslation()

  const storeName = Platform.select({
    ios: 'App Store',
    android: 'Google Play'
  })

  const openStoreUpdateLink = async () => {
    try {
      if (await Linking.canOpenURL(storeUpdateLink)) {
        await Linking.openURL(storeUpdateLink)
      } else {
        throw new Error()
      }
    } catch (error) {
      console.error('Error opening store update link:', error)
      showModal({
        content: <Text>{t('couldntOpenStore', { storeName })}</Text>,
        dismissable: !isBelowMin
      })
    }
  }

  return (
    <View>
      <Text style={{ marginBottom: 8 }}>
        {t(isBelowMin ? 'belowMinUpdateText' : 'updateText')}
      </Text>

      <Text style={{ marginBottom: 34 }}>{t('youMayHaveToSignBackIn')}</Text>

      <Button title={t('updateStowlog')} onPress={openStoreUpdateLink} />
    </View>
  )
}
