import { type AppStateStatus, Platform, AppState } from 'react-native'
import { focusManager } from '@tanstack/react-query'
import { useEffect } from 'react'

const onAppStateChange = (status: AppStateStatus) => {
  if (Platform.OS !== 'web') {
    focusManager.setFocused(status === 'active')
  }
}

const useReactQueryFocusManager = () => {
  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChange)

    return () => {
      subscription.remove()
    }
  }, [])
}

export default useReactQueryFocusManager
