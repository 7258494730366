// Config
import { client } from '@/common/config'
// Models
import { CustomHeaders } from '@/common/models'

export const getMissingCustomFields = async () => {
  const { data, error } = await client.GET(
    '/api/app-user/custom-fields/missing',
    {
      headers: {
        [CustomHeaders.API_VERSION]: 2
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting missing custom fields:', error)
    return []
  }

  return data.data
}
