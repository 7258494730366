import { memo } from 'react'
import { View, StyleSheet } from 'react-native'
import { Skeleton } from '@rneui/themed'

export const FacilityListItemSkeleton = memo(() => {
  return (
    <View style={styles.container}>
      <Skeleton animation="pulse" height={48} width={48} />

      <View style={styles.text}>
        <Skeleton animation="wave" height={22} width="50%" />

        <Skeleton animation="wave" height={22} width="100%" />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 14,
    gap: 16
  },
  text: {
    flex: 1,
    justifyContent: 'space-between'
  }
})
