import i18n from '@/common/config/i18n'
import * as Linking from 'expo-linking'
import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync,
  requestBackgroundPermissionsAsync,
  requestForegroundPermissionsAsync
} from 'expo-location'
import { Alert } from 'react-native'

export const modifyLocationSettings = async () => {
  const { t } = i18n
  const { granted: fgGranted, canAskAgain: fgCanAskAgain } =
    await getForegroundPermissionsAsync()
  const { granted: bgGranted, canAskAgain: bgCanAskAgain } =
    await getBackgroundPermissionsAsync()

  if (
    (!fgGranted && !fgCanAskAgain) ||
    (!bgGranted && !bgCanAskAgain) ||
    fgGranted ||
    bgGranted
  ) {
    Alert.alert(
      t('alwaysPermissionRequiredTitle'),
      t('alwaysPermissionRequiredMessage'),
      [
        {
          text: t('openSettings'),
          onPress: () => {
            void Linking.openSettings()
          }
        },
        {
          text: t('cancel'),
          style: 'cancel'
        }
      ],
      {
        cancelable: true
      }
    )
    return
  }

  if (!fgGranted && fgCanAskAgain) {
    await requestForegroundPermissionsAsync()
  }

  if (!bgGranted && bgCanAskAgain) {
    await requestBackgroundPermissionsAsync()
  }
}
