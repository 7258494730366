export const colors = {
  active: '#F5F5F5',
  black: '#000000',
  columbiaBlue: '#C3E2D8',
  darkSlateGray: '#2A3F44',
  darkerSlateGray: '#1D2A2D',
  default: '#A8C1B8',
  default50Percent: '#A8C1B880',
  disabled: '#C5C7C9',
  drawer: '#F8F9FA',
  error: '#E45131',
  hover: '#EDF7F9',
  hydro: '#5A8B96',
  inactive: '#F2F2F7',
  inactive5Percent: '#F2F2F70D',
  lightGray10Percent: '#2A3F441A',
  lightGray20Percent: '#2A3F4433',
  placeholder: '#8E8E93',
  rajah: '#FFA75F',
  success: '#58D7A9',
  text: '#324A51',
  text70Percent: '#324A51B3',
  uploadDark: '#293639',
  white: '#FFFFFF',
  white10Percent: '#FFFFFF1A',
  white5Percent: '#FFFFFF0D'
}
