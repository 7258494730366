// External
import { yupResolver } from '@hookform/resolvers/yup'
import type { StackScreenProps } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet } from 'react-native'
import { type InferType, number, object, string } from 'yup'
// Components
import {
  InfiniteSelect,
  ProgressBar,
  Select,
  StepNavigationButtons,
  Text,
  UserInformation
} from '@/common/components'
// Hooks
import usePreventGoingBack from '@/common/hooks/usePreventGoingBack'
// Models
import type { components } from '@/common/models'
import type { PTWStackParamList } from '@/permitToWork/models'
// Services
import {
  getApprovers,
  getAreas,
  getCompanies,
  getCompanyContractor,
  getContractors
} from '@/permitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useCreatePermitStore from '@/permitToWork/stores/useCreatePermitStore'
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
// Layouts
import { SafeArea } from '@/common/layouts'
// Utils
import { handleError } from '@/common/utils'

type Props = StackScreenProps<
  PTWStackParamList,
  'CreatePermitStepPersonInCharge'
>

const CreatePermitStepPersonInCharge = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const { user, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentFacility: state.currentFacility
  }))
  const { isCreating, setIsCreating } = useCreatePermitStore((state) => ({
    isCreating: state.isCreating,
    setIsCreating: state.setIsCreating
  }))
  const hasAreaSelectionEnabled = ptwConfig?.hasAreaSelectionEnabled ?? false
  const hasApproversListsEnabled = ptwConfig?.hasApproversListsEnabled ?? false
  if (Platform.OS === 'web') {
    usePreventGoingBack({
      condition: isCreating,
      onLeave: () => {
        setIsCreating(false)
      }
    })
  }

  const formSchema = useMemo(
    () =>
      object({
        companyId: string().required(t('requiredField')),
        ...(hasAreaSelectionEnabled && {
          areaId: string().required(t('requiredField'))
        }),
        ...(hasApproversListsEnabled && {
          approverId: string().required(t('requiredField'))
        }),
        personInChargeContractor: object({
          _id: string().required(),
          appUser: object({
            phoneNumber: object({
              prefix: number().required(),
              phone: string().required()
            })
          })
        }).required(t('requiredField'))
      }),
    [hasAreaSelectionEnabled, hasApproversListsEnabled]
  )

  type FormValues = InferType<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      companyId: '',
      ...(hasAreaSelectionEnabled && { areaId: '' }),
      ...(hasApproversListsEnabled && { approverId: '' }),
      personInChargeContractor: undefined
    }
  })

  const onSubmit = async (values: FormValues) => {
    try {
      const companyContractor = await getCompanyContractor({
        companyId: values.companyId
      })
      const personInChargeContractorId = values.personInChargeContractor._id
      const personInChargePhoneNumber =
        values.personInChargeContractor.appUser.phoneNumber

      navigation.navigate('CreatePermitStepDates', {
        companyId: values.companyId,
        areaId: values.areaId,
        approverId: values.approverId,
        personInChargeContractorId,
        contractorRequesterId: companyContractor._id,
        personInChargePhoneNumber
      })
    } catch (error) {
      handleError(error)
    }
  }

  const { data } = useQuery({
    queryKey: ['companies', user?._id, currentFacility?._id],
    queryFn: async () =>
      await getCompanies({ facilityId: currentFacility?._id as string }),
    enabled: currentFacility !== undefined
  })

  useEffect(() => {
    if (Platform.OS === 'web') {
      setIsCreating(true)
    }
  }, [])

  return (
    <SafeArea style={styles.container}>
      <ProgressBar
        style={styles.progressBar}
        value={ptwConfig?.hasPreArrangementsEnabled ?? false ? 0.125 : 0.142}
      />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('createNewRequest')}
      </Text>

      <Text style={{ marginBottom: 25 }}>
        {t('pleaseFillInTheFollowingFields')}
      </Text>

      <Controller
        control={control}
        name="companyId"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            // searchable
            label={t('company')}
            value={value}
            placeholder={t('selectACompany')}
            // searchPlaceholder={t('searchACompany')}
            onSelect={(option) => {
              onChange(option.value)
              onBlur()
            }}
            errorMessage={errors.companyId?.message}
            options={
              data?.map((company) => ({
                label: company.name,
                value: company._id
              })) ?? []
            }
          />
        )}
      />

      {hasApproversListsEnabled && (
        <>
          <Controller
            control={control}
            name={'approverId'}
            render={({ field: { onChange, onBlur } }) => (
              <InfiniteSelect<
                components['schemas']['FacilityMemberSearchResponse']
              >
                searchable
                label={t('approver')}
                placeholder={t('selectAnApprover')}
                searchPlaceholder={t('searchAnApprover')}
                errorMessage={errors.approverId?.message}
                onSelect={(option) => {
                  onChange(option.value)
                  onBlur()
                }}
                queryKey={['approvers', currentFacility?._id]}
                queryFn={async ({ page, search }) =>
                  await getApprovers({
                    facilityId: currentFacility?._id as string,
                    page,
                    search
                  })
                }
                getOptions={(data) =>
                  data.map((approver) => ({
                    label: approver.fullName,
                    value: approver._id
                  }))
                }
                noResultsTranslationKey="noApproversFound"
              />
            )}
          />
        </>
      )}

      {hasAreaSelectionEnabled && (
        <>
          <Controller
            control={control}
            name={'areaId'}
            render={({ field: { onChange, onBlur } }) => (
              <InfiniteSelect<components['schemas']['PTWAreaRowItemResponse']>
                searchable
                noResultsTranslationKey="noAreaFound"
                label={t('area')}
                placeholder={t('selectAnArea')}
                searchPlaceholder={t('searchAnArea')}
                errorMessage={errors.areaId?.message}
                onSelect={(option) => {
                  onChange(option.value)
                  onBlur()
                }}
                queryKey={['areas', currentFacility?._id]}
                queryFn={async ({ page, search }) =>
                  await getAreas({
                    facilityId: currentFacility?._id as string,
                    page,
                    search
                  })
                }
                getOptions={(data) =>
                  data.map((area) => ({
                    label: area.name,
                    value: area._id
                  }))
                }
              />
            )}
          />
        </>
      )}

      <Controller
        control={control}
        name="personInChargeContractor"
        render={({ field: { onChange, onBlur } }) => (
          <InfiniteSelect<components['schemas']['PTWContractorRowItemResponse']>
            searchable
            label={t('personInCharge')}
            disabled={watch('companyId') === ''}
            placeholder={t('selectPersonInCharge')}
            searchPlaceholder={t('searchAContractor')}
            onSelect={(option) => {
              onChange(option.original)
              onBlur()
            }}
            errorMessage={errors.personInChargeContractor?.message}
            queryKey={['person-in-charge-contractors', currentFacility?._id]}
            queryFn={async ({ page, search }) =>
              await getContractors({
                facilityId: currentFacility?._id as string,
                companyId: watch('companyId'),
                page,
                search
              })
            }
            getOptions={(data) =>
              data.map((contractor) => ({
                label: `${contractor.appUser.firstName} ${contractor.appUser.lastName}`,
                value: contractor._id,
                original: contractor
              }))
            }
            renderOption={({ original }) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
              <UserInformation {...original?.appUser!} />
            )}
            noResultsTranslationKey="noContractorsFound"
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreatePermitStepPersonInCharge

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  }
})
