// External
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
// Components
import { Text } from '@/common/components'
// Models
import type { components } from '@/common/models'

interface Props {
  locations: components['schemas']['PTWRequestDetailResponse']['locations']
  otherLocation: components['schemas']['PTWRequestDetailResponse']['otherLocation']
}

export const PermitLocationList = ({ locations, otherLocation }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={{ marginBottom: 24 }}>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('locations')}
      </Text>

      {locations.length === 0 && otherLocation !== undefined && (
        <Text variant="small">{`• ${otherLocation}`}</Text>
      )}

      {locations.length > 0 &&
        locations.map((location) => (
          <Text key={location._id} variant="small">
            {`• ${location.label}`}
          </Text>
        ))}
    </View>
  )
}
