import { client } from '@/common/config'

interface Params {
  email: string
  verificationCode: string
}

export const validateEmailChange = async ({
  email,
  verificationCode
}: Params) => {
  const { error } = await client.POST('/api/auth/app/email/change/verify', {
    body: {
      email,
      otp: verificationCode
    }
  })

  if (error !== undefined) {
    console.error('Error validating email change:', error)
    return await Promise.reject(error)
  }
}
