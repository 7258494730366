// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import type { SuspensionStackParamList } from '@/suspension/models'
// Views
import Suspensions from '@/suspension/views/Suspensions'

export const Stack = createStackNavigator<SuspensionStackParamList>()

const SuspensionStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Suspensions" component={Suspensions} />
    </Stack.Navigator>
  )
}

export default SuspensionStack
