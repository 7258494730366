// External
import { I18nManager, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import * as Updates from 'expo-updates'
// Components
import { IconDropdown } from './IconDropdown'
// Constants
import { locales } from '@/common/constants'
// Models
import { type StowlogLocale } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const LanguageDropdown = () => {
  const { i18n } = useTranslation()
  const { setLocale } = useAppStore((state) => ({
    locale: state.locale,
    setLocale: state.setLocale
  }))

  return (
    <IconDropdown
      iconName="language"
      iconSize={22}
      options={Object.entries(locales).map(([key, { label, isRTL }]) => ({
        label,
        onPress: async () => {
          try {
            await i18n.changeLanguage(key)
            setLocale(key as StowlogLocale)

            if (
              Platform.OS !== 'web' &&
              ((I18nManager.isRTL && !isRTL) || (!I18nManager.isRTL && isRTL))
            ) {
              I18nManager.allowRTL(isRTL)
              I18nManager.forceRTL(isRTL)
              if (!__DEV__) {
                void Updates.reloadAsync()
              } else {
                console.warn('Reload the app')
              }
            }
          } catch (error) {
            console.error("Error changing app's language:", error)
          }
        }
      }))}
    />
  )
}
