// External
import { TouchableOpacity as RNTouchableOpacity, Platform } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { Input } from '@rneui/themed'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'
import { type ForwardedRef, forwardRef, useImperativeHandle } from 'react'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
// Constants
import { colors } from '@/common/constants'
// Models
import type { BaseSelectProps, Option } from '@/common/models'

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

interface Props extends BaseSelectProps {
  value?: string | string[]
  options: Option[]
  onMultipleSelect?: (options: Option[]) => void
}

export const Select = forwardRef(
  (
    {
      style,
      inputStyle,
      label,
      disabled = false,
      value,
      options,
      onSelect,
      onMultipleSelect,
      searchable = false,
      placeholder,
      searchPlaceholder,
      errorMessage,
      noResultsTranslationKey
    }: Props,
    ref: ForwardedRef<unknown>
  ) => {
    const openSheet = async () => {
      if (onSelect !== undefined) {
        const result = await SheetManager.show('select', {
          payload: {
            options,
            searchable,
            placeholder,
            searchPlaceholder,
            noResultsTranslationKey
          }
        })
        if (result !== undefined) {
          onSelect(result)
        }
        return
      }

      if (onMultipleSelect !== undefined) {
        const result = await SheetManager.show('multiple-select', {
          payload: {
            selectedOptions: Array.isArray(value)
              ? options.filter((option) => value.includes(option.value))
              : [],
            options,
            searchable,
            placeholder,
            searchPlaceholder,
            noResultsTranslationKey
          }
        })
        if (result !== undefined) {
          onMultipleSelect(result)
        }
      }
    }

    useImperativeHandle(ref, () => ({
      openSheet
    }))

    const getValue = () => {
      if (value === undefined || value.length === 0) {
        return undefined
      }

      if (Array.isArray(value)) {
        const selectedOptions = options.filter((option) =>
          value.includes(option.value)
        )
        return selectedOptions.map((option) => option.label).join(', ')
      }

      const selectedOption = options.find((option) => option.value === value)

      if (selectedOption !== undefined) {
        if ('selectedLabel' in selectedOption) {
          return selectedOption.selectedLabel
        }

        return selectedOption.label
      }
    }

    return (
      <>
        {label !== undefined && <Text variant="label">{label}</Text>}

        <Wrapper style={style} disabled={disabled} onPress={openSheet}>
          <Input
            inputStyle={
              inputStyle ?? {
                fontFamily: 'PlusJakartaSans_500Medium'
              }
            }
            inputContainerStyle={{
              borderColor:
                errorMessage !== undefined ? colors.error : 'transparent'
            }}
            disabled={disabled}
            editable={false}
            value={getValue()}
            placeholder={placeholder}
            rightIcon={
              <Icon
                style={{
                  opacity: disabled ? 0.33 : 1
                }}
                name="dropdown-arrow"
                size={10}
              />
            }
            errorMessage={errorMessage}
          />
        </Wrapper>
      </>
    )
  }
)
