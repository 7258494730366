import { type CreateAccountParams } from '@/auth/models'
import { register } from '@/auth/services'
import { updateProfileImage } from '@/common/services'

export const createAccount = async (params: CreateAccountParams) => {
  try {
    const user = await register(params)

    let profileImage
    if (params.profileImage !== undefined) {
      try {
        profileImage = await updateProfileImage({
          profileImage: params.profileImage
        })
      } catch (error) {
        console.error(
          'Error uploading profile image during account creation:',
          error
        )
      }
    }

    return {
      messageTranslationKey: 'accountCreated',
      data: {
        user: {
          ...user,
          profileImage
        }
      }
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
