// External
import { useState, useCallback } from 'react'
import type { StackScreenProps } from '@react-navigation/stack'
import type { CompositeScreenProps } from '@react-navigation/native'
import { View, StyleSheet, RefreshControl } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { Tab } from '@rneui/themed'
import type { DrawerScreenProps } from '@react-navigation/drawer'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { FlashList } from '@shopify/flash-list'
// Components
import {
  TopBar,
  Text,
  Button,
  // BackgroundIcon,
  Icon,
  CardListSkeleton
} from '@/common/components'
import { PermitCard } from '@/permitToWork/components'
// Constants
import { colors, shadows, toast } from '@/common/constants'
// Hooks
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
// Models
import { type components, PTWRequestStatus } from '@/common/models'
import type { HomeDrawerParamList } from '@/home/models'
import { type PTWStackParamList } from '@/permitToWork/models'
// Services
import {
  getPermits,
  getPermitCounts,
  getCanCreatePermits
} from '@/permitToWork/services'
// Stores
import useNotificationStore from '@/notifications/stores/useNotificationsStore'
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { getResultsFromInfiniteQuery } from '@/common/utils'

type Props = CompositeScreenProps<
  StackScreenProps<PTWStackParamList, 'PermitToWork'>,
  DrawerScreenProps<HomeDrawerParamList>
>

const PermitToWork = ({ navigation }: Props) => {
  const { unreadNotifications } = useNotificationStore((state) => ({
    unreadNotifications: state.unreadNotifications
  }))
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const [tab, setTab] = useState(0)
  const selectedStatus = Object.values(PTWRequestStatus)[
    tab
  ] as PTWRequestStatus
  const { top, left, right, bottom } = useSafeAreaInsets()
  const { t } = useTranslation()

  const getTabTitle = ({
    status,
    index
  }: {
    status: PTWRequestStatus
    index: number
  }) => {
    if (permitCountsData !== undefined && index !== tab) {
      const count = permitCountsData.find(
        (item) => item.status === status
      )?.count

      if (typeof count === 'number' && count > 0) {
        return (
          <Text style={styles.tabTitle}>
            {t(status)}
            <Text style={styles.tabTitle} color={colors.rajah}>
              {` ${count}`}
            </Text>
          </Text>
        )
      }
    }

    return t(status)
  }

  const { data: canCreatePermitsData, refetch: refetchCanCreatePermits } =
    useQuery({
      queryKey: [
        'canCreatePermits',
        user?._id,
        currentUserType?._id,
        currentFacility?._id
      ],
      queryFn: async () =>
        await getCanCreatePermits(currentFacility?._id as string),
      staleTime: 60000,
      refetchInterval: 60000
    })

  const { data: permitCountsData, refetch: refetchCounts } = useQuery({
    queryKey: [
      'permitCounts',
      user?._id,
      currentUserType?._id,
      currentFacility?._id,
      selectedStatus
    ],
    queryFn: getPermitCounts
  })

  const {
    data: permitsData,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    refetch: refetchPermits
  } = useInfiniteQuery({
    queryKey: [
      'permits',
      user?._id,
      currentUserType?._id,
      currentFacility?._id,
      selectedStatus
    ],
    queryFn: async ({ pageParam }) =>
      await getPermits({
        status: selectedStatus,
        page: pageParam
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.hasNextPage && lastPage.nextPage !== null) {
        return lastPage.nextPage
      }

      return null
    }
  })

  const results = getResultsFromInfiniteQuery(permitsData)

  const renderItem = useCallback(
    ({
      item
    }: {
      item: components['schemas']['PTWRequestRowItemResponse']
    }) => <PermitCard permit={item} />,
    []
  )

  const onEndReached = useCallback(() => {
    if (hasNextPage) {
      void fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  useRefetchOnFocus([refetchCanCreatePermits, refetchCounts, refetchPermits])

  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <TopBar
          style={{
            paddingTop: top,
            paddingLeft: left + 25,
            paddingRight: right + 25
          }}
          leftIcon={{
            name: 'back',
            onPress: () => {
              navigation.goBack()
            }
          }}
          title={t('permitToWork')}
          rightIcons={[
            {
              name: 'notifications',
              onPress: () => {
                navigation.navigate('Notifications')
              },
              badgeText:
                unreadNotifications > 0
                  ? unreadNotifications.toString()
                  : undefined
            }
          ]}
        />

        <Tab
          scrollable
          style={{
            paddingTop: 36,
            paddingLeft: left + 25,
            paddingRight: right + 25,
            paddingBottom: 20
          }}
          value={tab}
          onChange={setTab}
        >
          {Object.values(PTWRequestStatus).map((status, index, array) => (
            <Tab.Item
              key={status}
              style={{
                marginLeft: index < array.length ? 8 : 0
              }}
              title={getTabTitle({ status, index })}
            />
          ))}
        </Tab>
      </View>

      {isLoading ? (
        <CardListSkeleton />
      ) : (
        <>
          {results.length > 0 && (
            <View style={styles.listHeader}>
              <View>
                <Text variant="baseBold">
                  {t(`permitListHeaders.${selectedStatus}`)}
                </Text>

                <Text variant="small">{t('seeTheStatusOfYourRequests')}</Text>
              </View>

              <View style={styles.listHeaderCount}>
                <Text color={colors.darkerSlateGray} variant="baseBold">
                  {results.length}
                </Text>
              </View>
            </View>
          )}

          <View style={{ flex: 1 }}>
            <FlashList
              contentContainerStyle={{ padding: 25 }}
              data={results}
              renderItem={renderItem}
              onEndReached={onEndReached}
              ItemSeparatorComponent={() => <View style={{ height: 25 }} />}
              ListEmptyComponent={() => (
                <View style={styles.listEmpty}>
                  <Text
                    color={colors.placeholder}
                    variant="medium"
                    style={{ marginBottom: 56, textAlign: 'center' }}
                  >
                    {t(`permitListEmpty.${selectedStatus}`)}
                  </Text>

                  <Icon
                    style={{ opacity: 0.2 }}
                    name="request-list-empty"
                    size={96}
                    color={colors.hydro}
                  />
                </View>
              )}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetching}
                  onRefresh={() => {
                    void refetchCanCreatePermits()
                    void refetchCounts()
                    void refetchPermits()
                  }}
                />
              }
              estimatedItemSize={176}
            />
          </View>
        </>
      )}

      <Button
        style={{
          marginBottom: bottom + 25,
          marginLeft: left + 25,
          marginRight: right + 25
        }}
        title={t('createNewRequest')}
        onPress={() => {
          if (canCreatePermitsData === false) {
            toast.showError({
              data: {
                messageTranslationKey: 'youAreNotAuthorizedToCreateAPermit'
              }
            })
            return
          }

          navigation.navigate('CreatePermitStepPersonInCharge')
        }}
      />
    </View>
  )
}

export default PermitToWork

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F9F9FB'
  },
  top: {
    backgroundColor: colors.white,
    borderBottomStartRadius: 20,
    borderBottomEndRadius: 20,
    ...shadows.default
  },
  tabTitle: {
    fontFamily: 'PlusJakartaSans_700Bold',
    fontSize: 12,
    lineHeight: 13,
    letterSpacing: 0.07
  },
  listHeader: {
    marginTop: 20,
    paddingHorizontal: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listHeaderCount: {
    backgroundColor: colors.inactive,
    borderColor: '#E5E5EA',
    borderWidth: 1.5,
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center'
  },
  listEmpty: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
