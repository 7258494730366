// External
import { useTranslation } from 'react-i18next'
import {
  TouchableOpacity,
  StyleSheet,
  type ViewStyle,
  View,
  ActivityIndicator,
  Platform
} from 'react-native'
// Components
import { Icon, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { StowlogIcon } from '@/common/models'

interface Props {
  style?: ViewStyle
  onPress: () => void
  iconBackgroundColor: string
  iconName: StowlogIcon
  titleTranslationKey: string
  textTranslationKey: string
  loading?: boolean
  icon?: {
    name: StowlogIcon
    color: string
  }
}

export const ModuleItem = ({
  style,
  onPress,
  iconBackgroundColor,
  iconName,
  titleTranslationKey,
  textTranslationKey,
  loading = false,
  icon
}: Props) => {
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={[styles.container, { backgroundColor: '#F8F8FD' }, style]}
      onPress={onPress}
      disabled={loading}
    >
      <View
        style={[styles.iconContainer, { backgroundColor: iconBackgroundColor }]}
      >
        <Icon name={iconName} size={32} />
      </View>

      <View style={{ marginEnd: 'auto' }}>
        <Text numberOfLines={1} variant="mediumBold">
          {t(titleTranslationKey)}
        </Text>

        <Text numberOfLines={1} color={colors.text70Percent}>
          {t(textTranslationKey)}
        </Text>
      </View>

      {loading ? (
        <ActivityIndicator size={Platform.OS === 'ios' ? 'small' : 32} />
      ) : (
        <TouchableOpacity onPress={onPress}>
          {icon !== undefined ? (
            <Icon name={icon.name} color={icon.color} size={32} />
          ) : (
            <Text variant="smallBold">{t('view')}</Text>
          )}
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    height: 85,
    padding: 15,
    alignItems: 'center',
    gap: 20,
    flexDirection: 'row'
  },
  iconContainer: {
    height: 55,
    width: 55,
    borderRadius: 27.5,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
