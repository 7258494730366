// External
import { type SheetProps } from 'react-native-actions-sheet'
import { useTranslation } from 'react-i18next'
import { object, string, type InferType } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import { Text, PhoneNumberInput, Button } from '@/common/components'
// Constants
import { phoneNumberRegEx } from '@/common/constants'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Models
import type { Option } from '@/common/models'

type Props = SheetProps<'edit-number'>

export const EditNumber = ({ payload, sheetId }: Props) => {
  const { t } = useTranslation()

  const validationSchema = object({
    prefix: string().required(),
    phone: string()
      .matches(phoneNumberRegEx, t('phoneNumberRegExError'))
      .required(t('provideMobileNumber'))
  })

  type FormValues = InferType<typeof validationSchema>

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      prefix: payload?.initialValues?.prefix.toString() ?? '34',
      phone: payload?.initialValues?.phone ?? ''
    }
  })

  if (payload === undefined) {
    return null
  }

  return (
    <ActionSheetContainer id={sheetId}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('editYourMobileNumber')}
      </Text>

      <PhoneNumberInput
        prefixValue={watch('prefix')}
        value={watch('phone')}
        onChangePrefix={(option: Option) => {
          setValue('prefix', option.value)
        }}
        onChangePhone={(phone) => {
          setValue('phone', phone)
        }}
        errorMessage={errors.phone?.message}
      />

      <Button
        style={{ marginTop: 45 }}
        title={t('saveChanges')}
        onPress={handleSubmit(payload.onSubmit)}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </ActionSheetContainer>
  )
}
