// External
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/common/constants'
import { Icon } from './Icon'

interface Props {
  messageTranslationKey: string
  search: string
}

export const NoResults = ({ messageTranslationKey, search }: Props) => {
  const { t } = useTranslation()

  const showSearch = search != null && search !== ''

  return (
    <View style={[styles.container]}>
      <View style={[styles.iconContainer]}>
        <Icon name="search" size={19} color={colors.rajah} />
      </View>

      <View
        style={{
          justifyContent: showSearch ? 'flex-start' : 'center'
        }}
      >
        <Text variant="mediumBold">{t(messageTranslationKey)}</Text>

        {showSearch && (
          <Text color={colors.text70Percent}>{`${t('for')} "${search}"`}</Text>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 20,
    paddingVertical: 14
  },
  iconContainer: {
    width: 50,
    height: 50,
    backgroundColor: 'rgba(255, 167, 95, 0.20)',
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
