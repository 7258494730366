import { client } from '@/common/config'

interface Params {
  facilityId: string
}

export const getCompanies = async ({ facilityId }: Params) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/company/{facilityId}/results',
    {
      params: {
        path: {
          facilityId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting companies:', error)
    return await Promise.reject(error)
  }

  return data.data
}
