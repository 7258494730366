import { client } from '@/common/config'

interface Params {
  hosts: string[]
  date: string
  checkIn: string
  checkOut: string
  visitQuestionAnswers: Array<{
    questionId: string
    answer: string
  }>
}

export const createVisitRequest = async (params: Params) => {
  const { error } = await client.POST('/api/app/visit-requests', {
    body: params
  })

  if (error !== undefined) {
    console.error('Error requesting visit:', error)
    return await Promise.reject(error)
  }
}
