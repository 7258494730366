import type { InfiniteData } from '@tanstack/react-query'
// TODO - Generic typing for the return
export const getResultsFromInfiniteQuery = (
  queryData: InfiniteData<any> | undefined
) => {
  const results = []

  if (queryData?.pages !== undefined) {
    for (const page of queryData.pages) {
      if (page.results !== undefined) {
        results.push(...page.results)
      } else {
        console.warn('page.results is undefined')
      }
    }
  }

  return results
}
