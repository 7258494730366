import { client } from '@/common/config'
import type { EditAccountDetailsParams } from '@/profile/models'

export const updateUserProfile = async (params: EditAccountDetailsParams) => {
  const { data, error } = await client.PATCH('/api/app-users/profile', {
    body: params
  })

  if (error !== undefined) {
    return await Promise.reject(error)
  }

  return data.data
}
