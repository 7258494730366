import { client } from '@/common/config'

export const deleteProfileImage = async (appUserId: string) => {
  const { error } = await client.DELETE('/api/app-users/{id}/profile-image', {
    params: {
      path: {
        id: appUserId
      }
    }
  })

  if (error !== undefined) {
    console.error('Error deleting profile image:', error)
    return await Promise.reject(error)
  }
}
