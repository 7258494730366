// External
import { View, type ViewStyle, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { getTimezoneOffset } from 'date-fns-tz'
import { Tooltip } from '@rneui/themed'
import { useState } from 'react'
// Components
import { Text } from './Text'
import { Icon } from './Icon'
// Constants
import { colors } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  style?: ViewStyle
}

export const FacilityTimezone = ({ style }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  if (currentFacility?.info.timezone === undefined) {
    return null
  }

  const offset = getTimezoneOffset(currentFacility.info.timezone) / 3600000

  return (
    <Tooltip
      visible={open}
      popover={
        <Text color={colors.white} variant="smallBold">
          {t('utc', { offset: offset > 0 ? `+${offset}` : offset })}
        </Text>
      }
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      width={80}
    >
      <View style={[styles.trigger, style]}>
        <Icon name="map-pin" size={13} />

        <Text variant="small">
          {t('timezone', { timezone: currentFacility.info.timezone })}
        </Text>
      </View>
    </Tooltip>
  )
}

const styles = StyleSheet.create({
  trigger: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2
  }
})
