// External
import { StyleSheet, ScrollView, View } from 'react-native'
import type { StackScreenProps } from '@react-navigation/stack'
import { type InferType, object, array, mixed, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
// Components
import { ProgressBar, Text, StepNavigationButtons } from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import { getPreArrangementQuestions } from '@/permitToWork/services'
import { PermitToWorkQuestionAnswers } from '@/common/models'
import { PermitPreArrangementQuestion } from '@/permitToWork/components'

type Props = StackScreenProps<
  PTWStackParamList,
  'CreatePermitStepPreArrangements'
>

const CreatePermitStepPreArrangements = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      object({
        preArrangementQuestions: array(
          object({
            question: string().required(),
            arrangementId: string().required(),
            answer: mixed().oneOf([
              PermitToWorkQuestionAnswers.NA,
              PermitToWorkQuestionAnswers.NO,
              PermitToWorkQuestionAnswers.YES
            ])
          }).required()
        ).required()
      }),
    []
  )

  type FormValues = InferType<typeof formSchema>

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      preArrangementQuestions: []
    }
  })

  const { fields, replace } = useFieldArray<FormValues>({
    control,
    name: 'preArrangementQuestions'
  })

  const onSubmit = (values: FormValues) => {
    navigation.navigate('CreatePermitStepPPE', {
      ...route.params,
      preArrangementQuestions: values.preArrangementQuestions.map(
        (question) => ({
          arrangementId: question.arrangementId,
          answer: question.answer as PermitToWorkQuestionAnswers
        })
      )
    })
  }

  const { data } = useQuery({
    queryKey: ['preArrangements', currentFacility?._id],
    queryFn: getPreArrangementQuestions
  })

  useEffect(() => {
    if (data !== undefined) {
      replace(
        data.map((question) => ({
          question: question.question,
          arrangementId: question._id,
          answer: PermitToWorkQuestionAnswers.YES
        }))
      )
    }
  }, [data])

  return (
    <SafeArea>
      <View style={{ marginHorizontal: 25 }}>
        <ProgressBar style={styles.progressBar} value={0.625} />

        <Text variant="h2Bold" style={{ marginBottom: 8 }}>
          {t('preArrangements')}
        </Text>

        <Text style={{ marginBottom: 25 }}>
          {t('pleaseAnswerTheFollowingQuestions')}
        </Text>
      </View>

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={styles.questions}
        bounces={false}
      >
        {fields.map((question, index) => (
          <Controller
            key={question.id}
            control={control}
            name={`preArrangementQuestions.${index}`}
            render={({ field: { onBlur, onChange, value } }) => (
              <PermitPreArrangementQuestion
                value={value.answer as PermitToWorkQuestionAnswers | undefined}
                question={question.question}
                onSelectAnswer={(answer) => {
                  onChange({
                    ...value,
                    answer
                  })
                  onBlur()
                }}
              />
            )}
          />
        ))}
      </ScrollView>

      <StepNavigationButtons
        style={styles.navigationButtons}
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreatePermitStepPreArrangements

const styles = StyleSheet.create({
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  navigationButtons: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  questions: {
    paddingHorizontal: 25,
    gap: 38
  }
})
