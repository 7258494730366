import { client } from '@/common/config'

export const getInductionStatus = async () => {
  const { data, error } = await client.GET(
    '/api/safety-induction/current-status'
  )

  if (error !== undefined) {
    console.error('Error getting induction status:', error)
    return await Promise.reject(error)
  }

  return data.data
}
