// External
import {
  TouchableOpacity,
  View,
  StyleSheet,
  type ViewStyle
} from 'react-native'
// Components
import { Text, Icon } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import {
  AppUserNotificationActionType,
  type StowlogIcon
} from '@/common/models'

type ExcludedProperty = AppUserNotificationActionType.OPEN_APP

type ActionTypeRecord = {
  [K in Exclude<AppUserNotificationActionType, ExcludedProperty>]: {
    color: string
    icon: StowlogIcon
  }
}

const actionTypeMap: ActionTypeRecord = {
  [AppUserNotificationActionType.PHONE_CALL]: {
    color: colors.columbiaBlue,
    icon: 'notification-type-phone-call'
  },
  [AppUserNotificationActionType.ATTACHMENTS]: {
    color: '#C1F0FF',
    icon: 'notification-type-attachments'
  },
  [AppUserNotificationActionType.OPEN_URL]: {
    color: '#FAF6E5',
    icon: 'notification-type-open-url'
  }
}

interface Props {
  style?: ViewStyle
  actionType: Exclude<AppUserNotificationActionType, ExcludedProperty>
  text: string
  onPress: () => void
}

export const NotificationListItemLink = ({
  style,
  actionType,
  text,
  onPress
}: Props) => {
  return (
    <TouchableOpacity style={[styles.link, style]} onPress={onPress}>
      <View
        style={[
          styles.iconContainer,
          {
            backgroundColor: actionTypeMap[actionType].color
          }
        ]}
      >
        <Icon name={actionTypeMap[actionType].icon} size={11} />
      </View>

      <Text numberOfLines={1} variant="link">
        {text}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    height: 22,
    width: 22,
    borderRadius: 11,
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    flexDirection: 'row',
    gap: 10
  }
})
