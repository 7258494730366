import { type CreateAccountParams, TokenType } from '@/auth/models'
import { storeToken } from '@/auth/utils'
import { client } from '@/common/config'
import { CustomHeaders } from '@/common/models'

export const register = async (params: CreateAccountParams) => {
  try {
    const { profileImage, phoneNumber, ...rest } = params
    const { data, error } = await client.POST('/api/auth/app/register', {
      body: {
        ...rest,
        phoneNumber: {
          prefix: parseInt(phoneNumber.prefix),
          phone: phoneNumber.phone
        }
      },
      headers: {
        [CustomHeaders.API_VERSION]: 2
      }
    })

    if (error !== undefined) {
      console.error('Error registering user:', error)
      return await Promise.reject(error)
    }

    await storeToken(
      data.data.accessToken,
      TokenType.AUTH,
      data.data.expirationToken
    )
    await storeToken(
      data.data.refreshToken,
      TokenType.REFRESH,
      data.data.refreshExpirationToken
    )
    return data.data.user
  } catch (error) {
    console.error('Error registering user:', error)
    return await Promise.reject(error)
  }
}
