import {
  selectFacility,
  selectFacilityUserType,
  getFacilityDetail
} from '@/findFacility/services'
import { getUser } from '@/common/services'

interface Params {
  facilityId: string
  userTypeId: string
}

export const goBackToTheFacility = async ({
  facilityId,
  userTypeId
}: Params) => {
  try {
    const facility = await getFacilityDetail(facilityId)

    await selectFacility(facilityId)

    await selectFacilityUserType(userTypeId)
    const user = await getUser()

    return { user, facility }
  } catch (error) {
    return await Promise.reject(error)
  }
}
