// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import type { CustomFieldsStackParamList } from '@/common/models'
// Views
import SelectYourUserType from '@/findFacility/views/SelectYourUserType'
import CustomFields from '@/profile/views/CustomFields'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const Stack = createStackNavigator<CustomFieldsStackParamList>()

const CustomFieldsStack = () => {
  const { missingCustomFields } = useAppStore((state) => ({
    missingCustomFields: state.missingCustomFields
  }))

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={
        missingCustomFields.length > 0 ? 'CustomFields' : 'SelectYourUserType'
      }
    >
      <Stack.Screen
        name="SelectYourUserType"
        component={SelectYourUserType}
        options={{ animationTypeForReplace: 'pop' }}
      />

      <Stack.Screen name="CustomFields" component={CustomFields} />
    </Stack.Navigator>
  )
}

export default CustomFieldsStack
