//  External
import type { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { object, array, type InferType, string } from 'yup'
import { useMemo, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider } from '@rneui/themed'
import { FlashList } from '@shopify/flash-list'
// Components
import {
  Text,
  StepNavigationButtons,
  ProgressBar,
  SelectOption
} from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
import type { components } from '@/common/models'
// Services
import { getPPE } from '@/permitToWork/services'
// Stores
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
import useAppStore from '@/common/stores/useAppStore'

type Props = StackScreenProps<PTWStackParamList, 'CreatePermitStepPPE'>

const CreatePermitStepPPE = ({ navigation, route }: Props) => {
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const defaultPPE = ptwConfig?.defaultPPE ?? []
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      object({
        ppeRequirements: array(string().required()).required()
      }),
    []
  )

  type FormValues = InferType<typeof formSchema>

  const {
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      ppeRequirements: []
    }
  })

  const ppeRequirements = watch('ppeRequirements')

  const onSubmit = ({ ppeRequirements }: FormValues) => {
    navigation.navigate('CreatePermitStepTypeOfWork', {
      ...route.params,
      ppeRequirements
    })
  }

  const renderItem = useCallback(
    ({ item }: { item: components['schemas']['PPETranslatedResponse'] }) => (
      <SelectOption
        key={item._id}
        disabled={defaultPPE.includes(item._id)}
        selected={ppeRequirements.includes(item._id)}
        option={{ label: item.label, value: item._id }}
        onPress={() => {
          setValue(
            'ppeRequirements',
            ppeRequirements.includes(item._id)
              ? ppeRequirements.filter((id) => id !== item._id)
              : [...ppeRequirements, item._id]
          )
        }}
      />
    ),
    [defaultPPE, ppeRequirements]
  )

  const { data } = useQuery({
    queryKey: ['ppes', currentFacility?._id],
    queryFn: async () => await getPPE(currentFacility?._id as string)
  })

  useEffect(() => {
    if (data !== undefined && defaultPPE.length > 0) {
      setValue('ppeRequirements', defaultPPE)
    }
  }, [data])

  return (
    <SafeArea>
      <View style={{ marginHorizontal: 25 }}>
        <ProgressBar
          style={styles.progressBar}
          value={ptwConfig?.hasPreArrangementsEnabled ?? false ? 0.75 : 0.714}
        />

        <Text variant="h2Bold" style={{ marginBottom: 8 }}>
          {t('requiredPPEs')}
        </Text>

        <Text style={{ marginBottom: 25 }}>
          {defaultPPE.length === 0
            ? t('selectNecessaryPPE')
            : t('listOfRequiredPPEs', {
                count: defaultPPE.length,
                requiredPPE: data
                  ?.filter((ppe) => defaultPPE.includes(ppe._id))
                  .map((ppe) => ppe.label)
                  .join(', ')
              })}
        </Text>
      </View>

      <View style={{ flex: 1 }}>
        <FlashList
          bounces={false}
          contentContainerStyle={styles.contentContainer}
          data={data ?? []}
          extraData={ppeRequirements}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <Divider />}
          estimatedItemSize={62}
        />
      </View>

      <StepNavigationButtons
        style={styles.navigationButtons}
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreatePermitStepPPE

const styles = StyleSheet.create({
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  navigationButtons: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  ppeContainer: {
    minHeight: 62,
    paddingHorizontal: 18,
    paddingVertical: 20,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  contentContainer: {
    paddingHorizontal: 25,
    paddingBottom: 25
  }
})
