// External
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
// Components
import { Avatar, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'

interface Props {
  requesterContractor: components['schemas']['PTWRequestDetailResponse']['requesterContractor']
}

export const PermitRequester = ({ requesterContractor }: Props) => {
  const { t } = useTranslation()
  const { profileImage, firstName, lastName, fullName, email } =
    requesterContractor.appUser

  return (
    <View style={{ marginBottom: 24 }}>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('permitRequesterInformation')}
      </Text>

      <View style={styles.permitRequester}>
        <Avatar
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          initials={`${firstName[0]}${lastName[0]}`}
          size={40}
          borderRadius={20}
          source={profileImage != null ? { uri: profileImage } : undefined}
        />

        <View style={{ flex: 1 }}>
          <Text variant="smallBold" numberOfLines={1}>
            {fullName}
          </Text>

          <Text variant="small" color={colors.text70Percent} numberOfLines={1}>
            {email}
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 24
  },
  permitRequester: {
    backgroundColor: colors.inactive,
    borderRadius: 7,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 15,
    padding: 8
  }
})
