import { client } from '@/common/config'

interface Params {
  password: string
  token: string
}

export const resetPassword = async ({ password, token }: Params) => {
  const { error } = await client.POST('/api/auth/app/reset-password', {
    body: {
      password,
      token
    }
  })

  if (error !== undefined) {
    console.error('Error resetting password:', error)
    return await Promise.reject(error)
  }
}
