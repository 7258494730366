import { memo } from 'react'
import { View, StyleSheet } from 'react-native'
import { Skeleton } from '@rneui/themed'

export const ModuleItemSkeleton = memo(() => {
  return (
    <View style={styles.container}>
      <Skeleton circle height={55} width={55} />

      <Skeleton style={{ flex: 1 }} animation="wave" height={55} />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 14,
    gap: 20,
    padding: 15
  }
})
