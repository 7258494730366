import { client } from '@/common/config'
import type { PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  uuid: string
}

export const getStaffOnSiteAvailableForRequest = async ({
  uuid,
  ...pagination
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/requests/{uuid}/staff-on-site/available',
    {
      params: {
        path: {
          uuid
        },
        query: pagination
      }
    }
  )

  if (error !== undefined) {
    console.error('Error creating permit request:', error)
    return await Promise.reject(error)
  }

  return data
}
