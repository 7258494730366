import type { ExtendedPermitStatus } from '@/permitToWork/models'
import { permitStatusColors, permitStatusIcons } from '@/permitToWork/constants'
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Icon, Text } from '@/common/components'

interface Props {
  compact?: boolean
  status: ExtendedPermitStatus
}

export const PermitStatus = ({ status, compact = false }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={{ flexDirection: 'row', gap: compact ? 5 : 10 }}>
      <View
        style={[
          styles.iconContainer,
          { backgroundColor: permitStatusColors[status].iconBackground }
        ]}
      >
        <Icon
          name={permitStatusIcons[status]}
          color={permitStatusColors[status].icon}
          size={24}
        />
      </View>

      <View
        style={[
          styles.statusTextContainer,
          { backgroundColor: permitStatusColors[status].background }
        ]}
      >
        <Text color={permitStatusColors[status].text} variant="smallBold">
          {t(compact ? status : `permitCardStatus.${status}`)}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  status: {
    flexDirection: 'row',
    gap: 10
  },
  iconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  statusTextContainer: {
    paddingHorizontal: 15,
    height: 32,
    justifyContent: 'center',
    borderRadius: 10
  }
})
