import { client } from '@/common/config'

export const getVerificationCode = async (email: string) => {
  const { error } = await client.POST('/api/auth/app/email/sign-up/request', {
    body: { email }
  })

  if (error !== undefined) {
    console.error('Error getting email verification code:', error)
    return await Promise.reject(error)
  }
}
