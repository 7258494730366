// External
import { View, type ViewStyle, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Text, ActionItem } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'
// Utils
import { callSOS } from '@/common/utils'

interface Props {
  style?: ViewStyle
  facility: components['schemas']['FacilityDetailResponse']
}

export const SOS = ({ style, facility }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={style}>
      <Text variant="baseBold">{t('contactAndSupport')}</Text>

      <View
        style={[
          styles.sosContainer,
          { backgroundColor: colors.white, borderColor: colors.inactive }
        ]}
      >
        <ActionItem
          onPress={() => {
            void callSOS(facility)
          }}
          title="S.O.S"
          text={facility.name}
          action={t('call')}
          iconName="headphone"
          iconSize={22}
          titleColor={colors.error}
        />
      </View>

      <View style={{ flexDirection: 'row' }}>
        <Text variant="mediumBold" color={colors.error}>
          *
        </Text>

        <Text style={{ marginStart: 6 }}>{t('ifYouNeedSupport')}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  sosContainer: {
    padding: 20,
    marginVertical: 12,
    borderRadius: 7,
    borderWidth: 1
  },
  sos: {
    marginStart: 10,
    marginEnd: 'auto',
    flex: 1
  },
  call: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
