// External
import { StyleSheet, Pressable } from 'react-native'
import { useState } from 'react'
import Constants from 'expo-constants'
// Constants
import { colors } from '@/common/constants'

export const ModeIndicator = () => {
  const [opacity, setOpacity] = useState(1)
  const mode: string = Constants?.expoConfig?.extra?.mode

  if (__DEV__) {
    return (
      <Pressable
        style={[
          styles.container,
          {
            opacity,
            backgroundColor: mode === 'PROD' ? colors.error : colors.rajah
          }
        ]}
        onPress={() => {
          setOpacity((prev) => (prev === 1 ? 0.25 : 1))
        }}
      />
    )
  }

  return null
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    position: 'absolute',
    justifyContent: 'center',
    top: 0,
    width: '100%',
    height: 32
  }
})
