// External
import {
  useState,
  forwardRef,
  useImperativeHandle,
  type ForwardedRef
} from 'react'
import { TouchableOpacity as RNTouchableOpacity, Platform } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { Input, type InputProps } from '@rneui/themed'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'
// Components
import { Icon } from './Icon'
// Constants
import { colors } from '@/common/constants'
// Models
import type { BaseSelectProps, Option } from '@/common/models'
import { Text } from './Text'

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

interface Props<T> extends BaseSelectProps<T> {
  inputStyle?: InputProps['inputStyle']
  keepPlaceholder?: boolean
  queryKey: any[]
  queryFn: (params: any) => Promise<any>
  getOptions: (data: T[]) => Array<Option<T>>
  defaultSelectedOption?: Option<T>
  defaultSelectedOptions?: Array<Option<T>>
  onMultipleSelect?: (options: Array<Option<T>>) => void
}

export const InfiniteSelectInner = <T,>(
  {
    style,
    inputStyle,
    label,
    disabled = false,
    onSelect,
    searchable = false,
    keepPlaceholder = false,
    placeholder,
    searchPlaceholder,
    errorMessage,
    queryKey,
    queryFn,
    getOptions,
    noResultsTranslationKey,
    renderOption,
    defaultSelectedOption,
    defaultSelectedOptions = [],
    onMultipleSelect
  }: Props<T>,
  ref: ForwardedRef<unknown>
) => {
  const [selectedOption, setSelectedOption] = useState<Option<T> | undefined>(
    defaultSelectedOption ?? undefined
  )
  const [selectedOptions, setSelectedOptions] = useState<Array<Option<T>>>(
    defaultSelectedOptions
  )

  const openSheet = async () => {
    if (onSelect !== undefined) {
      const result = await SheetManager.show('infinite-select', {
        payload: {
          searchable,
          placeholder,
          searchPlaceholder,
          queryKey,
          queryFn,
          getOptions,
          noResultsTranslationKey,
          renderOption
        }
      })
      if (result !== undefined) {
        setSelectedOption(result)
        onSelect(result)
      }
      return
    }

    if (onMultipleSelect !== undefined) {
      const result = await SheetManager.show('multiple-infinite-select', {
        payload: {
          searchable,
          placeholder,
          searchPlaceholder,
          queryKey,
          queryFn,
          getOptions,
          noResultsTranslationKey,
          selectedOptions: defaultSelectedOptions
        }
      })
      if (result !== undefined) {
        setSelectedOptions(result)
        onMultipleSelect(result)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    openSheet
  }))

  const getValue = () => {
    if (keepPlaceholder) {
      return undefined
    }

    if (selectedOption !== undefined) {
      return selectedOption.selectedLabel ?? selectedOption.label
    }

    if (selectedOptions.length > 0) {
      return selectedOptions.map((option) => option.label).join(', ')
    }

    return undefined
  }

  return (
    <>
      {label !== undefined && <Text variant="label">{label}</Text>}

      <Wrapper style={style} disabled={disabled} onPress={openSheet}>
        <Input
          inputStyle={
            inputStyle ?? {
              fontFamily: 'PlusJakartaSans_500Medium'
            }
          }
          inputContainerStyle={{
            borderColor:
              errorMessage !== undefined ? colors.error : 'transparent'
          }}
          disabled={disabled}
          editable={false}
          value={getValue()}
          placeholder={placeholder}
          rightIcon={
            <Icon
              style={{
                opacity: disabled ? 0.33 : 1
              }}
              name="dropdown-arrow"
              size={10}
            />
          }
          errorMessage={errorMessage}
        />
      </Wrapper>
    </>
  )
}

export const InfiniteSelect = forwardRef(InfiniteSelectInner)
