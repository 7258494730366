// External
import { yupResolver } from '@hookform/resolvers/yup'
import { type StackScreenProps } from '@react-navigation/stack'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, type TextInput } from 'react-native'
import { object, string, type InferType } from 'yup'
// Components
import {
  Input,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { nameRegEx } from '@/common/constants'
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep4'>

const CreateAccountStep4 = ({ navigation, route }: Props) => {
  const { t } = useTranslation()
  const lastNameInputRef = useRef<TextInput>(null)

  const validationSchema = object({
    firstName: string()
      .matches(nameRegEx, t('nameInstructions'))
      .required(t('provideName')),
    lastName: string()
      .matches(nameRegEx, t('nameInstructions'))
      .required(t('provideLastName'))
  })

  type FormValues = InferType<typeof validationSchema>

  const onSubmit = ({ firstName, lastName }: FormValues) => {
    navigation.navigate('CreateAccountStep5', {
      ...route.params,
      firstName,
      lastName
    })
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.6} />

      <Text variant="h2Bold" style={styles.header}>
        {t('yourName')}
      </Text>

      <Text>{t('fillWithFullName')}</Text>

      <Text style={{ marginBottom: 25 }}>{t('whatYouSeeIn')}</Text>

      <Controller
        control={control}
        name="firstName"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourName')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              lastNameInputRef.current?.focus()
            }}
            onBlur={() => {
              onChange(value?.trim())
              onBlur()
            }}
            value={value}
            returnKeyType="next"
            autoComplete="name"
            autoCapitalize="words"
            errorMessage={errors.firstName?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={lastNameInputRef}
            placeholder={t('yourLastName')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={() => {
              onChange(value?.trim())
              onBlur()
            }}
            value={value}
            returnKeyType="done"
            autoComplete="name-family"
            autoCapitalize="words"
            errorMessage={errors.lastName?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreateAccountStep4

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8,
    textTransform: 'capitalize'
  }
})
