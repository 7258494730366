// External
import { useState } from 'react'
import type { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { TopBar, AttachmentList } from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { ProfileStackParamList } from '@/profile/models'
import { SheetManager } from 'react-native-actions-sheet'
// Services
import { updateCustomFieldAttachment } from '@/profile/services'
// Utils
import { handleError } from '@/common/utils'
import { toast } from '@/common/constants'

type Props = StackScreenProps<
  ProfileStackParamList,
  'EditCustomFieldAttachments'
>

const EditCustomFieldAttachments = ({ navigation, route }: Props) => {
  const [attachments, setAttachments] = useState(
    route.params.field.attachments ?? []
  )
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  return (
    <SafeArea style={styles.container}>
      <TopBar
        style={{ marginBottom: 23 }}
        title={t('editAttachments')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
      />

      <AttachmentList
        attachments={attachments}
        isLoading={isSaving}
        options={[
          {
            label: t('update'),
            onPress: (attachment) => {
              void SheetManager.show('upload-options', {
                payload: {
                  onImagePickerAsset: async (asset) => {
                    try {
                      await SheetManager.hide('upload-options')
                      setIsSaving(true)
                      const updatedAttachments =
                        await updateCustomFieldAttachment({
                          customFieldId: route.params.field.customFieldId,
                          attachmentId: attachment._id,
                          attachmentName: attachment.name,
                          newValue: asset
                        })
                      if (updatedAttachments !== undefined) {
                        setAttachments(updatedAttachments)
                      }
                      toast.showSuccess({
                        data: {
                          messageTranslationKey: 'attachmentUpdated'
                        }
                      })
                    } catch (error) {
                      handleError(error)
                    } finally {
                      setIsSaving(false)
                    }
                  }
                }
              })
            }
          }
        ]}
      />
    </SafeArea>
  )
}

export default EditCustomFieldAttachments

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    paddingBottom: 25
  }
})
