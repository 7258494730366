// External
import { useRef } from 'react'
import { Marker, Callout, type MapMarker } from 'react-native-maps'
import { View, Image, StyleSheet, Platform } from 'react-native'
// Components
import { Avatar, Icon, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'

interface Props {
  facility: components['schemas']['FacilityListItemResponse']
  onPressFacility: (
    facility: components['schemas']['FacilityListItemResponse']
  ) => void
}

export const FindFacilityMapMarker = ({ facility, onPressFacility }: Props) => {
  const ref = useRef<MapMarker>(null)

  if (
    facility.coordinates.lat === undefined ||
    facility.coordinates.long === undefined
  ) {
    return null
  }

  return (
    <Marker
      ref={ref}
      key={facility._id}
      coordinate={{
        latitude: facility.coordinates.lat,
        longitude: facility.coordinates.long
      }}
      title={facility.name}
      description={facility.info.address}
      {...(Platform.OS === 'android' && {
        onCalloutPress: () => {
          onPressFacility(facility)
        }
      })}
    >
      {Platform.OS !== 'android' && (
        <Callout
          tooltip={true}
          onPress={() => {
            onPressFacility(facility)
          }}
        >
          <View style={styles.callout}>
            <Avatar
              source={{ uri: facility.avatarUrl }}
              size={48}
              borderRadius={6}
            />

            <View style={{ flex: 1 }}>
              <View style={styles.facilityName}>
                <Text numberOfLines={1} variant="baseBold">
                  {facility.name}
                </Text>

                {Platform.OS === 'web' && (
                  <Icon
                    name="close"
                    onPress={() => {
                      if (ref.current != null) {
                        ref.current.hideCallout()
                      }
                    }}
                    size={12}
                  />
                )}
              </View>

              <Text
                numberOfLines={1}
                color={colors.text70Percent}
                variant="small"
              >
                {facility.info.address}
              </Text>
            </View>
          </View>
        </Callout>
      )}

      <Image
        style={styles.marker}
        source={require('@assets/images/location.png')}
        resizeMode="contain"
      />
    </Marker>
  )
}

const styles = StyleSheet.create({
  callout: {
    width: Platform.OS === 'web' ? 354 : 320,
    padding: 8,
    gap: 16,
    backgroundColor: colors.white,
    marginBottom: 8,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center'
  },
  marker: {
    width: 30,
    height: 30
  },
  facilityName: {
    flex: 1,
    flexDirection: 'row',
    gap: 12,
    justifyContent: 'space-between'
  }
})
