import * as Notifications from 'expo-notifications'
import * as Linking from 'expo-linking'

export const modifyNotificationSettings = async () => {
  try {
    const { granted, canAskAgain } = await Notifications.getPermissionsAsync()

    if ((!granted && !canAskAgain) || granted) {
      void Linking.openSettings()
      return
    }

    if (!granted && canAskAgain) {
      await Notifications.requestPermissionsAsync()
    }
  } catch (error) {
    void Linking.openSettings()
  }
}
