import { client } from '@/common/config'
import { type PaginatedGetParams, type SuspensionStatus } from '@/common/models'

interface Params extends PaginatedGetParams {
  userUUID: string
  status: SuspensionStatus
}

export const getSuspensions = async ({
  userUUID,
  status,
  page,
  limit = 10
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/suspensions/{userUUID}/search',
    {
      params: {
        path: {
          userUUID
        },
        query: {
          status,
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting suspensions:', error)
    return await Promise.reject(error)
  }

  return data
}
