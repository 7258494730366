import { memo } from 'react'
import { View } from 'react-native'
import { Skeleton, Divider } from '@rneui/themed'

export const NotificationListSkeleton = memo(() => {
  return (
    <View>
      <View style={{ gap: 10 }}>
        <Skeleton animation="wave" height={21} width="20%" />

        <Skeleton animation="wave" height={23} width="50%" />

        <Skeleton style={{ flex: 1 }} animation="wave" height={21} />
      </View>

      <Divider style={{ marginVertical: 21 }} />

      <View style={{ gap: 10 }}>
        <Skeleton animation="wave" height={21} width="20%" />

        <Skeleton animation="wave" height={23} width="66.6%" />

        <Skeleton style={{ flex: 1 }} animation="wave" height={84} />
      </View>

      <Divider style={{ marginVertical: 21 }} />

      <View style={{ gap: 10 }}>
        <Skeleton animation="wave" height={21} width="20%" />

        <Skeleton animation="wave" height={23} width="33.3%" />

        <Skeleton animation="wave" height={21} width="80%" />
      </View>
    </View>
  )
})
