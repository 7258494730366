import { client } from '@/common/config'

export const getCanCreatePermits = async (facilityId: string) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/contractor/{facilityId}/can-create-permit',
    {
      params: {
        path: {
          facilityId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting can create permits:', error)
    return await Promise.reject(error)
  }

  return data.data.success
}
