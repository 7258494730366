// External
import { type ReactNode } from 'react'
import Animated, {
  type AnimateStyle,
  interpolate,
  useAnimatedStyle
} from 'react-native-reanimated'
import { useDrawerProgress } from '@react-navigation/drawer'
import { View, Platform, type ViewStyle } from 'react-native'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'

interface Props {
  style?: ViewStyle
  children: ReactNode
}

export const AnimatedDrawerScreen = ({ style, children }: Props) => {
  const progress = useDrawerProgress()
  const isRTL = useIsRTL()

  if (Platform.OS === 'web') {
    return <View style={[{ flex: 1 }, style]}>{children}</View>
  }

  const animatedStyle = useAnimatedStyle(() => ({
    flex: 1,
    overflow: 'hidden',
    transform: [
      {
        scale: interpolate(progress.value, [0, 1], [1, 0.8], 'clamp')
      },
      {
        translateY: interpolate(progress.value, [0, 1], [0, 40], 'clamp')
      },
      {
        translateX: interpolate(
          progress.value,
          [0, 1],
          [0, isRTL ? 25 : -25],
          'clamp'
        )
      }
    ],
    borderRadius: interpolate(progress.value, [0, 1], [0, 30], 'clamp')
  }))

  return (
    <Animated.View style={[animatedStyle, style as AnimateStyle<ViewStyle>]}>
      {children}
    </Animated.View>
  )
}
