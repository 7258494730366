// External
import { ImageBackground } from 'react-native'
import {
  SafeAreaView,
  type SafeAreaViewProps
} from 'react-native-safe-area-context'
// Constants
import { colors } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props extends SafeAreaViewProps {
  withBackground?: boolean
}

export const SafeArea = ({
  style,
  edges,
  children,
  withBackground = false
}: Props) => {
  const colorScheme = useAppStore((state) => state.colorScheme)
  const baseStyle = {
    flex: 1,
    backgroundColor:
      colorScheme === 'light' ? colors.white : colors.darkerSlateGray
  }

  if (withBackground) {
    return (
      <ImageBackground
        style={baseStyle}
        source={require('@assets/images/background.png')}
        resizeMode="cover"
      >
        <SafeAreaView edges={edges} style={[{ flex: 1 }, style]}>
          {children}
        </SafeAreaView>
      </ImageBackground>
    )
  }

  return (
    <SafeAreaView edges={edges} style={[baseStyle, style]}>
      {children}
    </SafeAreaView>
  )
}
