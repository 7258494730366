// External
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'
// Models
import { AppUserNotificationActionType } from '@/common/models'

interface Params {
  actionData?: string
  actionType: AppUserNotificationActionType
}

export const onPressNotificationAction = async ({
  actionData,
  actionType
}: Params) => {
  let url: string | undefined

  if (actionData !== undefined && actionData.length > 0) {
    switch (actionType) {
      case AppUserNotificationActionType.PHONE_CALL:
        url = `tel:${actionData}`
        break
      case AppUserNotificationActionType.OPEN_URL:
        url = actionData
        break
    }
  }

  if (url !== undefined) {
    try {
      const openable = await Linking.canOpenURL(url)
      if (openable) {
        if (Platform.OS === 'web') {
          window.open(url, '_blank')
          return
        }

        await Linking.openURL(url)
      }
    } catch (error) {
      console.error(`Error opening ${url}:`, error)
    }
  }
}
