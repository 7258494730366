// External
import type { StackScreenProps } from '@react-navigation/stack'
import type { CompositeScreenProps } from '@react-navigation/native'
import type { DrawerScreenProps } from '@react-navigation/drawer'
import {
  PanResponder,
  Animated,
  Dimensions,
  View,
  StyleSheet,
  Platform,
  useWindowDimensions
} from 'react-native'
import { useRef, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LinearGradient from 'react-native-linear-gradient'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
// Components
import {
  Text,
  CircleIconButton,
  Input,
  DragIndicator
} from '@/common/components'
import {
  FacilitiesVisited,
  FacilityList,
  FindFacilityMap,
  FiltersButton
} from '@/findFacility/components'
// Constants
import { colors, gradients } from '@/common/constants'
// Hooks
import useCurrentPosition from '@/findFacility/hooks/useCurrentPosition'
// Layouts
import { KeyboardFix, AnimatedDrawerScreen } from '@/common/layouts'
// Models
import type { PreHomeStackParamList, components } from '@/common/models'
import type {
  FindFacilityDrawerParamList,
  FacilityFilters
} from '@/findFacility/models'
// Services
import { getFacilities, getVisitedFacilities } from '@/findFacility/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { handleError } from '@/common/utils'

type Props = CompositeScreenProps<
  DrawerScreenProps<FindFacilityDrawerParamList, 'FindFacility'>,
  StackScreenProps<PreHomeStackParamList>
>

const DRAGGABLE_HEIGHT = 150
const TOP_SEARCH_CONTAINER_HEIGHT = 300

const FindFacility = ({ navigation, route }: Props) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const [filters, setFilters] = useState<FacilityFilters>({
    region: null,
    country: null,
    type: null
  })
  const [mountDraggable, setMountDraggable] = useState(true)
  const { t } = useTranslation()
  const colorScheme = useAppStore((state) => state.colorScheme)
  const { top, bottom, left, right } = useSafeAreaInsets()
  const currentPosition = useCurrentPosition()
  const { height: DEVICE_HEIGHT } = useWindowDimensions()

  const LIST_MIN_HEIGHT = DEVICE_HEIGHT * 0.5 - DRAGGABLE_HEIGHT
  const MAP_HEIGHT = DEVICE_HEIGHT - (LIST_MIN_HEIGHT + DRAGGABLE_HEIGHT - 50)

  const SHOW_TOP_INPUT_START =
    DEVICE_HEIGHT -
    TOP_SEARCH_CONTAINER_HEIGHT -
    TOP_SEARCH_CONTAINER_HEIGHT / 2
  const SHOW_TOP_INPUT_END = DEVICE_HEIGHT - TOP_SEARCH_CONTAINER_HEIGHT

  const bottomHeight = useRef(new Animated.Value(LIST_MIN_HEIGHT)).current
  const LIST_MAX_HEIGHT =
    Platform.OS === 'android'
      ? DEVICE_HEIGHT - TOP_SEARCH_CONTAINER_HEIGHT + top
      : DEVICE_HEIGHT - TOP_SEARCH_CONTAINER_HEIGHT

  const onPressFacility = useCallback(
    (facility: components['schemas']['FacilityListItemResponse']) => {
      navigation.navigate('FacilityDetail', {
        facilityId: facility._id
      })
    },
    []
  )

  const backgroundColor =
    colorScheme === 'light' ? colors.white : colors.darkerSlateGray
  const horizontalPadding = {
    paddingLeft: left + 25,
    paddingRight: right + 25
  }

  const topPosition = bottomHeight.interpolate({
    inputRange: [SHOW_TOP_INPUT_START, SHOW_TOP_INPUT_END],
    outputRange: [-TOP_SEARCH_CONTAINER_HEIGHT, 0],
    extrapolate: 'clamp'
  })

  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponderCapture: (e, gestureState) =>
        Math.abs(gestureState.dy) > 5,
      onPanResponderMove: (e) => {
        bottomHeight.setValue(
          DEVICE_HEIGHT - e.nativeEvent.pageY - DRAGGABLE_HEIGHT
        )
      },
      onPanResponderRelease: (e) => {
        const { pageY } = e.nativeEvent

        if (pageY >= 0 && pageY <= TOP_SEARCH_CONTAINER_HEIGHT) {
          Animated.timing(bottomHeight, {
            toValue: LIST_MAX_HEIGHT,
            useNativeDriver: false
          }).start(() => {
            setMountDraggable(false)
          })
        }
      }
    })
  ).current

  useEffect(() => {
    if (route.params !== undefined) {
      setFilters(route.params)
    }
  }, [route.params])

  const {
    data: facilitiesData,
    isLoading: isLoadingFacilities,
    error,
    isError
  } = useQuery({
    queryKey: [
      'facilities',
      debouncedSearch,
      filters.country,
      filters.region,
      filters.type?._id
    ],
    queryFn: async () =>
      await getFacilities({
        page: 1,
        limit: 100,
        search,
        region: filters.region,
        country: filters.country,
        type: filters.type
      }),
    staleTime: 60000,
    refetchInterval: 60000
  })

  const { data: visitedFacilitiesData } = useQuery({
    queryKey: ['visitedFacilities'],
    queryFn: getVisitedFacilities
  })

  useEffect(() => {
    if (isError) {
      handleError(error)
    }
  }, [error, isError])

  return (
    <AnimatedDrawerScreen>
      <KeyboardFix
        contentContainerStyle={{ justifyContent: 'flex-end' }}
        extraHeight={LIST_MIN_HEIGHT}
      >
        {!(Platform.OS === 'web' && Dimensions.get('window').width > 1024) && (
          <CircleIconButton
            style={{
              position: 'absolute',
              zIndex: 1,
              top: top + 11,
              left: left + 25
            }}
            iconName="menu"
            onPress={navigation.openDrawer}
          />
        )}

        <FindFacilityMap
          style={[styles.map, { height: MAP_HEIGHT }]}
          facilities={facilitiesData?.results ?? []}
          currentPosition={currentPosition}
          onPressFacility={onPressFacility}
        />

        {mountDraggable && (
          <View
            {...panResponder.panHandlers}
            style={[styles.draggable, horizontalPadding, { backgroundColor }]}
          >
            <DragIndicator />

            <Text variant="baseBold" style={styles.findText}>
              {t('selectAFacility')}
            </Text>

            <View
              style={{
                flexDirection: 'row',
                gap: 10
              }}
            >
              <Input
                style={{ flex: 1 }}
                placeholder={t('findAFacility')}
                value={search}
                onChangeText={(text) => {
                  setSearch(text)
                }}
                showLabel={false}
                leftIcon="search"
                {...(search.length > 0 && {
                  rightIcon: 'x-circle',
                  rightIconOnPress: () => {
                    setSearch('')
                  }
                })}
              />

              <FiltersButton filters={filters} />
            </View>
          </View>
        )}

        <Animated.View
          style={{
            minHeight: LIST_MIN_HEIGHT,
            maxHeight: LIST_MAX_HEIGHT,
            backgroundColor,
            height: bottomHeight
          }}
        >
          <LinearGradient
            {...(colorScheme === 'light'
              ? gradients.lightListFacilityShadow
              : gradients.darkListFacilityShadow)}
            style={styles.gradient}
          />

          <FacilityList
            contentContainerStyle={{
              paddingBottom: bottom + 25,
              ...horizontalPadding
            }}
            isLoading={isLoadingFacilities}
            facilities={facilitiesData?.results ?? []}
            currentPosition={currentPosition}
            search={search}
            onPressFacility={onPressFacility}
          />
        </Animated.View>

        <Animated.View
          style={[
            styles.topSearchContainer,
            {
              backgroundColor,
              top: topPosition,
              paddingTop: top + 11
            }
          ]}
        >
          <View
            style={[
              styles.search,
              {
                paddingLeft: left + 25,
                paddingRight: right + 25
              }
            ]}
          >
            <CircleIconButton
              iconName="back"
              onPress={() => {
                setMountDraggable(true)
                Animated.timing(bottomHeight, {
                  toValue: LIST_MIN_HEIGHT,
                  useNativeDriver: false
                }).start()
              }}
            />

            <Input
              style={{ flex: 1 }}
              placeholder={t('findAFacility')}
              value={search}
              onChangeText={(text) => {
                setSearch(text)
              }}
              showLabel={false}
              renderErrorMessage={false}
              leftIcon="search"
              {...(search.length > 0 && {
                rightIcon: 'x-circle',
                rightIconOnPress: () => {
                  setSearch('')
                }
              })}
            />

            <FiltersButton filters={filters} />
          </View>

          <FacilitiesVisited
            visitedFacilities={visitedFacilitiesData ?? []}
            horizontalPadding={horizontalPadding}
          />
        </Animated.View>
      </KeyboardFix>
    </AnimatedDrawerScreen>
  )
}

export default FindFacility

const styles = StyleSheet.create({
  map: {
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    zIndex: 0,
    position: 'absolute'
  },
  draggable: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingBottom: 20,
    paddingTop: 18,
    height: DRAGGABLE_HEIGHT
  },
  findText: {
    alignSelf: 'center',
    marginBottom: 20
  },
  search: {
    flexDirection: 'row',
    gap: 10
  },
  topSearchContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: TOP_SEARCH_CONTAINER_HEIGHT,
    zIndex: 1
  },
  gradient: {
    height: 38,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  }
})
