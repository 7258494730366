// External
import { type StackScreenProps } from '@react-navigation/stack'
import { useState, useEffect } from 'react'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SelectUserTypeScreen } from '@/common/layouts'
// Models
import type { components, CustomFieldsStackParamList } from '@/common/models'
// Services
import { getFacilityUserTypes } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { logIntoFacility as logIntoFacilityUseCase } from '@/findFacility/useCases'
// Utils
import { handleError } from '@/common/utils'

type Props = StackScreenProps<CustomFieldsStackParamList, 'SelectYourUserType'>

const SelectYourUserType = ({ navigation }: Props) => {
  const [userTypes, setUserTypes] = useState<
    Array<components['schemas']['GetFacilityUserTypesResponse']>
  >([])
  const { currentFacility, logIntoFacility, logOutOfFacility } = useAppStore(
    (state) => ({
      currentFacility: state.currentFacility,
      logIntoFacility: state.logIntoFacility,
      logOutOfFacility: state.logOutOfFacility
    })
  )

  const fetch = async () => {
    try {
      const res = await getFacilityUserTypes()
      setUserTypes(res)
    } catch (error) {
      handleError(error)
      logOutOfFacility()
    }
  }

  useEffect(() => {
    void fetch()
  }, [])

  return (
    <SelectUserTypeScreen
      onPressBack={logOutOfFacility}
      userTypes={userTypes}
      onSelectUserType={async (type) => {
        try {
          if (currentFacility === undefined) {
            toast.showError({
              data: {
                titleTranslationKey: 'error',
                messageTranslationKey: 'thereWasAnIssueWithTheFacility'
              }
            })
            logOutOfFacility()
            return
          }

          const { user, missingCustomFields } = await logIntoFacilityUseCase(
            type._id
          )
          if (missingCustomFields.length > 0) {
            navigation.navigate('CustomFields')
          }
          logIntoFacility(user, type, missingCustomFields, currentFacility)
        } catch (error) {
          handleError(error)
          logOutOfFacility()
        }
      }}
    />
  )
}
export default SelectYourUserType
