import { client } from '@/common/config'

export const readNotifications = async () => {
  const { error } = await client.POST('/api/app-user-notifications/read')

  if (error !== undefined) {
    console.error('Error reading notifications:', error)
    return await Promise.reject(error)
  }
}
