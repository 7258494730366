// External
import io, { type Socket } from 'socket.io-client'
import { useEffect } from 'react'
// Constants
import { socketUrl } from '@/common/constants'
// Models
import { TokenType } from '@/auth/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { getToken } from '@/auth/utils'

const useUserUpdates = () => {
  const { setUser } = useAppStore((state) => ({
    setUser: state.setUser
  }))

  useEffect(() => {
    let socket: Socket

    const assignSocket = async () => {
      const token = await getToken(TokenType.AUTH)
      socket = io(`${socketUrl}/app-users`, {
        transports: ['websocket'],
        query: {
          token
        }
      })

      socket.on('app-user.updated', (updatedUser) => {
        if (useAppStore.getState().user !== undefined) {
          setUser(updatedUser.appUser)
        }
      })
    }

    void assignSocket()

    return () => {
      if (socket !== undefined) {
        socket.off('app-user.updated')
        socket.disconnect()
      }
    }
  }, [])
}
export default useUserUpdates
