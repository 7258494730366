// External
import type { DrawerScreenProps } from '@react-navigation/drawer'
import { StyleSheet, View } from 'react-native'
import { useCallback, useEffect } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { Divider } from '@rneui/themed'
import { useInfiniteQuery } from '@tanstack/react-query'
import { FlashList } from '@shopify/flash-list'
// Components
import { TopBar, Text, Icon } from '@/common/components'
import {
  NotificationListItem,
  NotificationListSkeleton
} from '@/notifications/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { HomeDrawerParamList } from '@/home/models'
import type { components } from '@/common/models'
// Services
import { getNotifications, readNotifications } from '@/notifications/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { handleError, getResultsFromInfiniteQuery } from '@/common/utils'
import { useFocusEffect } from '@react-navigation/native'

type Props = DrawerScreenProps<HomeDrawerParamList, 'Notifications'>

const Notifications = ({ navigation }: Props) => {
  const { top, left, right, bottom } = useSafeAreaInsets()
  const { t } = useTranslation()
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))

  const renderItem = useCallback(
    ({ item }: { item: components['schemas']['AppUserNotification'] }) => (
      <NotificationListItem notification={item} />
    ),
    []
  )

  const { data, isLoading, isError, error, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: [
        'notifications',
        user?._id,
        currentUserType?._id,
        currentFacility?._id
      ],
      queryFn: async ({ pageParam }) =>
        await getNotifications({ page: pageParam }),
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        if (lastPage.hasNextPage && lastPage.nextPage !== null) {
          return lastPage.nextPage
        }

        return null
      }
    })

  const onEndReached = useCallback(() => {
    if (hasNextPage) {
      void fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  useEffect(() => {
    if (isError) {
      handleError(error)
      navigation.goBack()
    }
  }, [isError])

  useFocusEffect(
    useCallback(() => {
      void readNotifications()
    }, [])
  )

  return (
    <View style={{ flex: 1, paddingTop: top, backgroundColor: colors.white }}>
      <TopBar
        style={{ paddingLeft: left + 25, paddingRight: right + 25 }}
        title={t('notifications')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
      />

      <View style={{ flex: 1 }}>
        <FlashList
          bounces={false}
          data={getResultsFromInfiniteQuery(data)}
          renderItem={renderItem}
          contentContainerStyle={{
            paddingTop: 23,
            paddingLeft: left + 25,
            paddingRight: right + 25,
            paddingBottom: bottom + 25
          }}
          ListEmptyComponent={
            isLoading ? (
              <NotificationListSkeleton />
            ) : (
              <View style={styles.listEmpty}>
                <Text style={styles.listEmptyText} variant="largeBold">
                  {t('haventReceivedNotifications')}
                </Text>

                <View
                  style={[
                    styles.iconContainer,
                    { backgroundColor: colors.columbiaBlue }
                  ]}
                >
                  <Icon name="bell" size={88} color={colors.hydro} />
                </View>
              </View>
            )
          }
          onEndReached={onEndReached}
          onEndReachedThreshold={0.5}
          ItemSeparatorComponent={() => (
            <Divider style={{ marginVertical: 21 }} />
          )}
          estimatedItemSize={100}
        />
      </View>
    </View>
  )
}
export default Notifications

const styles = StyleSheet.create({
  listEmpty: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listEmptyText: {
    marginBottom: 44,
    textAlign: 'center'
  },
  iconContainer: {
    padding: 20,
    height: 168,
    width: 168,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
