import { View, type ViewStyle } from 'react-native'
import { colors } from '@/common/constants'

interface Props {
  style?: ViewStyle
}

export const DragIndicator = ({ style }: Props) => {
  return (
    <View
      style={[
        {
          width: 55,
          height: 4,
          borderRadius: 27.5,
          marginBottom: 20,
          alignSelf: 'center',
          backgroundColor: colors.placeholder
        },
        style
      ]}
    />
  )
}
