// External
import * as Sentry from '@sentry/react-native'
import type { ReactNode } from 'react'
// Views
import ErrorView from '@/common/views/ErrorView'

interface Props {
  children: ReactNode
}

const ErrorBoundary = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorView}>{children}</Sentry.ErrorBoundary>
  )
}
export default ErrorBoundary
