import { View } from 'react-native'
import { Avatar } from './Avatar'
import { Text } from './Text'
import { colors } from '@/common/constants'

interface Props {
  firstName: string
  lastName: string
  profileImage?: string
  fullName: string
  email: string
}

export const UserInformation = ({
  firstName,
  lastName,
  profileImage,
  fullName,
  email
}: Props) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        gap: 16
      }}
    >
      <Avatar
        size={40}
        borderRadius={20}
        initials={`${firstName[0] as string}${lastName[0] as string}`}
        {...(profileImage !== undefined && {
          source: {
            uri: profileImage
          }
        })}
      />

      <View>
        <Text>{fullName}</Text>

        <Text style={{ color: colors.text70Percent }} variant="small">
          {email}
        </Text>
      </View>
    </View>
  )
}
