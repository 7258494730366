// External
import { type DrawerScreenProps } from '@react-navigation/drawer'
import { WebView } from 'react-native-webview'
// Layouts
import { MapScreen } from '@/common/layouts'
// Models
import type { HomeDrawerParamList } from '@/home/models'

type Props = DrawerScreenProps<HomeDrawerParamList, 'VirtualMap'>

const VirtualMap = ({ route }: Props) => {
  return (
    <MapScreen topBarTitleTranslationKey="3dMap">
      <WebView style={{ flex: 1 }} source={{ uri: route.params.link }} />
    </MapScreen>
  )
}
export default VirtualMap
