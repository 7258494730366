import type { GeopositionConfigState } from '@/geoposition/models'
import { create } from 'zustand'

const useGeopositionConfigStore = create<GeopositionConfigState>()((set) => ({
  geopositionConfig: undefined,
  setGeopositionConfig: (geopositionConfig) => {
    set({ geopositionConfig })
  }
}))
export default useGeopositionConfigStore
