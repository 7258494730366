import { type SheetProps } from 'react-native-actions-sheet'
import { UploadOptions } from '@/common/components'
import { ActionSheetContainer } from '@/common/layouts'

export const UploadOptionsSheet = ({
  payload,
  sheetId
}: SheetProps<'upload-options'>) => {
  return (
    <ActionSheetContainer id={sheetId} isModal={false}>
      <UploadOptions
        onImagePickerAsset={(asset) => {
          void payload?.onImagePickerAsset(asset)
        }}
      />
    </ActionSheetContainer>
  )
}
