// External
import type { ReactNode } from 'react'
import { TouchableOpacity, type ViewStyle } from 'react-native'
// Components
import { Text } from './Text'

interface Props {
  style?: ViewStyle
  numberOfLines?: number
  children: ReactNode
  onPress: () => void
}

export const Link = ({ style, numberOfLines, children, onPress }: Props) => {
  return (
    <TouchableOpacity onPress={onPress} style={style}>
      <Text variant="link" numberOfLines={numberOfLines}>
        {children}
      </Text>
    </TouchableOpacity>
  )
}
