// External
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
// Services
import { getMissingCustomFields } from '@/profile/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const useMissingCustomFields = () => {
  const { user, currentUserType, currentFacility, setMissingCustomFields } =
    useAppStore((state) => ({
      user: state.user,
      currentUserType: state.currentUserType,
      currentFacility: state.currentFacility,
      setMissingCustomFields: state.setMissingCustomFields
    }))

  const { data } = useQuery({
    queryKey: [
      'missingCustomFields',
      user?._id,
      currentUserType?._id,
      currentFacility?._id
    ],
    queryFn: getMissingCustomFields
  })

  useEffect(() => {
    if (data !== undefined) {
      setMissingCustomFields(data.filter((field) => field.isRequired))
    }
  }, [data])
}

export default useMissingCustomFields
