// Models
import type { components } from '@/common/models'
import type { EditAccountDetailsParams } from '@/profile/models'
// Services
import { updateUserProfile, deleteProfileImage } from '@/profile/services'
import { updateProfileImage } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const editAccountDetails = async (params: EditAccountDetailsParams) => {
  try {
    if ('profileImage' in params) {
      const { profileImage } = params
      const userCopy = useAppStore.getState()
        .user as components['schemas']['SignInAppUser']
      let newProfileImage: string | undefined

      if (params.profileImage === undefined) {
        await deleteProfileImage(userCopy._id)
      } else if (profileImage !== undefined) {
        newProfileImage = await updateProfileImage({
          profileImage
        })
      }

      useAppStore
        .getState()
        .setUser({ ...userCopy, profileImage: newProfileImage })

      return
    }

    const user = await updateUserProfile(params)

    if ('password' in params) {
      return
    }

    useAppStore.getState().setUser(user)
  } catch (error) {
    return await Promise.reject(error)
  }
}
