import { client } from '@/common/config'

export const forgotPassword = async (email: string) => {
  const { error } = await client.POST('/api/auth/app/forgot-password', {
    body: {
      email
    }
  })

  if (error !== undefined) {
    console.error('Error sending password reset email:', error)
    return await Promise.reject(error)
  }
}
