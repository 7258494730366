// External
import { TouchableOpacity, StyleSheet, ImageBackground } from 'react-native'
// Components
import { Text } from './Text'
import { Icon } from './Icon'
// Constants
import { colors } from '@/common/constants'
// Models
import type { StowlogIcon } from '@/common/models'

interface Props {
  photo?: string
  onRemovePhoto?: () => void
  iconName: StowlogIcon
  iconSize?: number
  text: string
  onPress: () => void
}

export const SelectAttachment = ({
  photo,
  onRemovePhoto,
  iconName,
  iconSize = 20,
  text,
  onPress
}: Props) => {
  if (photo !== undefined) {
    return (
      <ImageBackground
        source={{ uri: photo }}
        style={styles.container}
        imageStyle={{ borderRadius: 10 }}
      >
        {onRemovePhoto !== undefined && (
          <TouchableOpacity
            style={styles.addIconContainer}
            onPress={onRemovePhoto}
          >
            <Icon name="close" size={17} color={colors.hydro} />
          </TouchableOpacity>
        )}
      </ImageBackground>
    )
  }

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Icon
        name={iconName}
        size={iconSize}
        color={colors.placeholder}
        style={styles.imagesIcon}
      />

      <Text style={styles.text}>{text}</Text>

      <TouchableOpacity style={styles.addIconContainer} onPress={onPress}>
        <Icon name="add-file" size={17} color={colors.hydro} />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    height: 200,
    width: 150,
    borderRadius: 10,
    backgroundColor: colors.inactive
  },
  imagesIcon: {
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 5
  },
  text: {
    textAlign: 'center',
    marginBottom: 'auto',
    color: colors.placeholder
  },
  addIconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 10,
    right: 10,
    height: 27,
    width: 27,
    borderRadius: 13.5,
    backgroundColor: colors.white
  }
})
