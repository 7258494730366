import { client } from '@/common/config'

interface Params {
  visitRequestUUID: string
  reason: string
}

export const cancelVisitRequest = async ({
  visitRequestUUID,
  reason
}: Params) => {
  const { error } = await client.PATCH(
    '/api/app/visit-requests/{uuid}/cancel',
    {
      body: {
        reason
      },
      params: {
        path: {
          uuid: visitRequestUUID
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error cancelling visit request:', error)
    return await Promise.reject(error)
  }
}
