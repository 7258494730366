// External
import io, { type Socket } from 'socket.io-client'
import { useEffect } from 'react'
// Constants
import { socketUrl } from '@/common/constants'
// Models
import { TokenType } from '@/auth/models'
// Services
import { getFacilityDetail } from '@/findFacility/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { getToken } from '@/auth/utils'

const useFacilityUpdates = () => {
  const { setCurrentFacility } = useAppStore((state) => ({
    setCurrentFacility: state.setCurrentFacility
  }))

  useEffect(() => {
    let socket: Socket

    const assignSocket = async () => {
      const token = await getToken(TokenType.AUTH)
      socket = io(`${socketUrl}/facility`, {
        transports: ['websocket'],
        query: {
          token
        }
      })

      socket.on('facility.updated', async (event) => {
        try {
          if (useAppStore.getState().currentFacility !== undefined) {
            const facility = await getFacilityDetail(event.facility._id)
            setCurrentFacility(facility)
          }
        } catch (error) {
          console.error(
            'Error getting facility details on useFacilityUpdates hook:',
            error
          )
        }
      })
    }

    void assignSocket()

    return () => {
      if (socket !== undefined) {
        socket.off('facility.updated')
        socket.disconnect()
      }
    }
  }, [])
}
export default useFacilityUpdates
