// External
import type { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
// Components
import { TopBar } from '@/common/components'
import { RequestVisitForm } from '@/visit/components/RequestVisitForm'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SafeArea, KeyboardFix } from '@/common/layouts'
// Models
import type { VisitStackParamList } from '@/visit/models'
// Services
import { getCheckinQuestions } from '@/visit/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'

type Props = StackScreenProps<VisitStackParamList, 'RequestAVisit'>

const RequestAVisit = ({ navigation }: Props) => {
  const { currentUserType, currentFacility } = useAppStore((state) => ({
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const { data } = useQuery({
    queryKey: ['checkinQuestions', currentUserType?._id, currentFacility?._id],
    queryFn: async () =>
      await getCheckinQuestions({
        userTypeId: currentUserType?._id as string,
        facilityId: currentFacility?._id as string
      })
  })

  return (
    <SafeArea>
      <KeyboardFix contentContainerStyle={{ flex: 1 }}>
        <TopBar
          style={{ paddingHorizontal: 25 }}
          title={t('requestAVisit')}
          leftIcon={{
            name: 'back',
            onPress: navigation.goBack
          }}
        />

        {data === undefined ? null : (
          <RequestVisitForm
            checkinQuestions={data}
            onSubmitSuccess={() => {
              toast.showSuccess({
                data: {
                  messageTranslationKey: 'visitRequested'
                }
              })
              navigation.goBack()
            }}
          />
        )}
      </KeyboardFix>
    </SafeArea>
  )
}

export default RequestAVisit
