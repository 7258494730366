import { client } from '@/common/config'

export const getSuspensionCount = async (userTypeSlug: string) => {
  const { data, error } = await client.GET(
    '/api/app/suspensions/{userTypeSlug}/status/count',
    {
      params: {
        path: {
          userTypeSlug
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting request count:', error)
    return
  }

  return data.data
}
