import { toast } from '@/common/constants'
import * as Sentry from '@sentry/react-native'

export const handleError = (error: unknown) => {
  if (typeof error === 'object' && error !== null) {
    if (
      'errorCode' in error &&
      typeof error.errorCode === 'string' &&
      error.errorCode !== 'unknown'
    ) {
      toast.showError({
        data: {
          titleTranslationKey: 'error',
          messageTranslationKey: error.errorCode
        }
      })
      return
    }

    if ('message' in error) {
      if (Array.isArray(error.message)) {
        toast.showError({
          data: {
            titleTranslationKey: 'error',
            messageTranslationKey: error.message.join()
          }
        })
        return
      }

      if (typeof error.message === 'string') {
        toast.showError({
          data: {
            titleTranslationKey: 'error',
            messageTranslationKey: error.message
          }
        })
        return
      }
    }
  }

  Sentry.captureException(error)

  toast.showError({
    data: {
      titleTranslationKey: 'unknownErrorTitle',
      messageTranslationKey: 'unknownErrorMessage'
    }
  })
}
