// External
import { type SheetProps, SheetManager } from 'react-native-actions-sheet'
import { View } from 'react-native'
import { useState, useCallback } from 'react'
import { Divider } from '@rneui/themed'
import { FlashList } from '@shopify/flash-list'
// Components
import { SelectOption, SearchBar, NoResults } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Models
import type { Option } from '@/common/models'

export const SelectSheet = ({ payload, sheetId }: SheetProps<'select'>) => {
  const [search, setSearch] = useState('')

  const data =
    payload?.searchable === true
      ? payload?.options.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase())
        )
      : payload?.options

  const renderItem = useCallback(
    ({ item }: { item: Option }) => (
      <SelectOption
        option={item}
        onPress={() => {
          void SheetManager.hide(sheetId, {
            payload: item
          })
        }}
      />
    ),
    []
  )

  return (
    <ActionSheetContainer id={sheetId}>
      {payload?.searchable === true && (
        <SearchBar
          value={search}
          onChangeText={setSearch}
          placeholder={payload?.searchPlaceholder}
        />
      )}

      <View style={{ height: 248 }}>
        <FlashList
          bounces={false}
          contentContainerStyle={{
            paddingTop: payload?.searchable === true ? 20 : 0
          }}
          data={data}
          renderItem={renderItem}
          keyExtractor={(_, index) => index.toString()}
          ItemSeparatorComponent={() => (
            <Divider style={{ marginHorizontal: 20 }} />
          )}
          {...(payload?.noResultsTranslationKey !== undefined && {
            ListEmptyComponent: (
              <NoResults
                messageTranslationKey={payload.noResultsTranslationKey}
                search={search}
              />
            )
          })}
          estimatedItemSize={62}
        />
      </View>
    </ActionSheetContainer>
  )
}
