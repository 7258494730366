// External
import { getLocales } from 'expo-localization'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// Constants
import { defaultLocale, locales, resources } from '@/common/constants'
// Models
import type { StowlogLocale } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const initialize = async () => {
  const languageCode = getLocales()[0]?.languageCode
  let lng: StowlogLocale = defaultLocale

  if (
    languageCode !== undefined &&
    languageCode !== null &&
    Object.keys(locales).includes(languageCode)
  ) {
    lng = languageCode as StowlogLocale
  }

  useAppStore.getState().setLocale(lng)

  void i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: defaultLocale,
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    lng
  })
}

void initialize()

export default i18n
