// External
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { Divider } from '@rneui/themed'
import { useTranslation } from 'react-i18next'
import { useNavigation, type NavigationProp } from '@react-navigation/native'
// Components
import { Text } from '@/common/components'
import { PermitCardTimer } from './PermitCardTimer'
import { PermitStatus } from './PermitStatus'
// Constants
import { shadows, colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'
import type { PTWStackParamList } from '@/permitToWork/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { formatDate } from '@/common/utils'
import { getExtendedPermitStatus } from '@/permitToWork/utils'

interface Props {
  permit: components['schemas']['PTWRequestRowItemResponse']
}

export const PermitCard = ({ permit }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationProp<PTWStackParamList>>()

  const { startDate, endDate } = permit
  const status = getExtendedPermitStatus(permit)

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate('PermitDetail', {
          uuid: permit.uuid,
          seq: permit.seq
        })
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        {(status === 'ACTIVE' || status === 'QUEUED') && (
          <PermitCardTimer
            status={status}
            startDate={startDate}
            endDate={endDate}
          />
        )}

        <View
          {...((status === 'ACTIVE' || status === 'QUEUED') && {
            style: {
              marginStart: 'auto'
            }
          })}
        >
          <Text style={{ marginBottom: 8 }} variant="small">
            {`${t('identification')}:`}
            <Text variant="smallBold">{` #${permit.seq}`}</Text>
          </Text>

          <PermitStatus status={status} />
        </View>
      </View>

      <Divider style={styles.divider} />

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Text variant="small">{t('startDate')}</Text>

          <View style={styles.date}>
            <Text variant="smallBold">
              {formatDate(
                new Date(startDate),
                'P',
                currentFacility?.info.timezone
              )}
            </Text>

            <Text style={{ marginBottom: 2.4 }} variant="extraSmall">
              {formatDate(
                new Date(startDate),
                'p',
                currentFacility?.info.timezone
              )}
            </Text>
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <Text variant="small">{t('endDate')}</Text>

          <View style={styles.date}>
            <Text variant="smallBold">
              {formatDate(
                new Date(endDate),
                'P',
                currentFacility?.info.timezone
              )}
            </Text>

            <Text style={{ marginBottom: 2.4 }} variant="extraSmall">
              {formatDate(
                new Date(endDate),
                'p',
                currentFacility?.info.timezone
              )}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colors.white,
    ...shadows.default,
    borderRadius: 10,
    height: 176
  },
  divider: {
    marginTop: 20,
    marginBottom: 10
  },
  date: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 4
  }
})
