// External
import io from 'socket.io-client'
import { useEffect } from 'react'
// Constants
import { socketUrl } from '@/common/constants'
// Models
import { NotificationEvents } from '@/notifications/models'
// Stores
import useNotificationStore from '@/notifications/stores/useNotificationsStore'

interface Props {
  userId: string | undefined
  facilityId: string | undefined
  userTypeId: string | undefined
}

const useUnreadNotificationCount = ({
  userId,
  facilityId,
  userTypeId
}: Props) => {
  const { setUnreadNotifications } = useNotificationStore((state) => ({
    setUnreadNotifications: state.setUnreadNotifications
  }))

  useEffect(() => {
    if (
      userId === undefined ||
      facilityId === undefined ||
      userTypeId === undefined
    ) {
      return
    }

    const socket = io(`${socketUrl}/app-user-notifications`, {
      transports: ['websocket'],
      query: {
        appUserId: userId,
        facilityId,
        userTypeId
      }
    })

    // socket.on('connect', () => {
    //   console.log('🔌 Unread notification count socket connected')
    // })
    // socket.on('disconnect', () => {
    //   console.log('❌ Unread notification count socket disconnected')
    // })
    socket.on(
      NotificationEvents.GET_UNREAD_NOTIFICATION_COUNT,
      (unreadCount: number) => {
        setUnreadNotifications(unreadCount)
      }
    )

    socket.connect()
    socket.emit(NotificationEvents.CONNECT_USER_TO_NOTIFICATIONS)

    return () => {
      if (socket.connected) {
        socket.off(NotificationEvents.GET_UNREAD_NOTIFICATION_COUNT)
        socket.disconnect()
      }
    }
  }, [userId, facilityId, userTypeId])
}
export default useUnreadNotificationCount
