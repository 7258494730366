import { AttachmentList, Text } from '@/common/components'
import { colors } from '@/common/constants'
import { type components } from '@/common/models'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

interface Props {
  attachments: components['schemas']['PTWRequestDetailResponse']['attachments']
}

export const PermitAttachmentList = ({ attachments }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      {attachments != null && attachments.length > 0 && (
        <>
          <Text variant="label">{t('attachments')}</Text>
          <AttachmentList
            attachments={
              attachments.map((a) => ({
                _id: a._id,
                extension: a.extension,
                name: a.name,
                mimeType: a.mimeType,
                size: a.size,
                url: a.url
              })) as any
            }
          />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
  listItemContainer: { paddingHorizontal: 0, flex: 1 },
  title: {
    color: colors.black
  },
  risk: {
    color: colors.text70Percent
  },
  genericWork: {
    color: colors.text70Percent
  }
})
