// External
import { Divider } from '@rneui/themed'
import { differenceInDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
// Components
import { Text } from '@/common/components'
// Constants
import { colors, shadows } from '@/common/constants'
// Models
import type { components } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { formatDate } from '@/common/utils'

interface Props {
  suspension: components['schemas']['SuspensionRowItemResponseDto']
}

export const SuspensionCard = ({ suspension }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const duration = differenceInDays(
    new Date(suspension.returnDate),
    new Date(suspension.createdAt)
  )

  return (
    <View style={styles.container}>
      <Text style={{ marginBottom: 8 }} variant="mediumBold" numberOfLines={1}>
        {suspension.reason}
      </Text>

      <Text variant="small">
        {t('suspensionDuration', { count: duration })}
      </Text>

      <Text variant="small" numberOfLines={4}>
        <Text variant="smallBold">{`${t('description')}: `}</Text>
        {suspension.description}
      </Text>

      <Divider style={styles.divider} />

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Text variant="small">{t('suspensionDate')}</Text>

          <View style={styles.date}>
            <Text variant="smallBold">
              {formatDate(
                new Date(suspension.createdAt),
                'P',
                currentFacility?.info.timezone
              )}
            </Text>

            <Text style={{ marginBottom: 2.4 }} variant="extraSmall">
              {formatDate(
                new Date(suspension.createdAt),
                'p',
                currentFacility?.info.timezone
              )}
            </Text>
          </View>
        </View>

        <View style={{ flex: 1 }}>
          <Text variant="small">{t('returnDate')}</Text>

          <View style={styles.date}>
            <Text variant="smallBold">
              {suspension.isPermanent
                ? t('permanentBan')
                : formatDate(
                    new Date(suspension.returnDate),
                    'P',
                    currentFacility?.info.timezone
                  )}
            </Text>

            {!suspension.isPermanent && (
              <Text style={{ marginBottom: 2.4 }} variant="extraSmall">
                {formatDate(
                  new Date(suspension.returnDate),
                  'p',
                  currentFacility?.info.timezone
                )}
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colors.white,
    ...shadows.default,
    borderRadius: 10
  },
  divider: {
    marginTop: 20,
    marginBottom: 10
  },
  date: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 4
  }
})
