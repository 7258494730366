// External
import { useState } from 'react'
import { Menu } from 'react-native-paper'
import {
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  View,
  Platform,
  useWindowDimensions
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { useTranslation } from 'react-i18next'
// Components
import { Text } from './Text'
import { SquareIconButton } from './SquareIconButton'
// Constants
import { colors, gradients } from '@/common/constants'
// Models
import type { StowlogIcon } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Option {
  label: string
  onPress: () => void
}

interface Props {
  iconName: StowlogIcon
  iconSize?: number
  options: Option[]
}

export const IconDropdown = ({ iconName, iconSize, options }: Props) => {
  const [visible, setVisible] = useState(false)
  const { colorScheme } = useAppStore((state) => ({
    locale: state.locale,
    colorScheme: state.colorScheme
  }))
  const { t } = useTranslation()

  if (Platform.OS === 'web') {
    return (
      <>
        <SquareIconButton iconName={iconName} iconSize={iconSize} />

        <select
          value=""
          style={{
            height: 52,
            width: 52,
            opacity: 0,
            position: 'absolute'
          }}
          onChange={(e) => {
            const selectedOption = options.find(
              (option) => option.label === e.target.value
            )

            if (selectedOption !== undefined) {
              selectedOption.onPress()
            }
          }}
        >
          <option value="" disabled>
            {t('selectAnOption')}
          </option>

          {options.map((option, index) => (
            <option key={index} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </>
    )
  }

  const { height } = useWindowDimensions()

  return (
    <Menu
      visible={visible}
      onDismiss={() => {
        setVisible(false)
      }}
      anchor={
        <SquareIconButton
          iconName={iconName}
          iconSize={iconSize}
          onPress={() => {
            setVisible(true)
          }}
        />
      }
      anchorPosition="bottom"
      contentStyle={styles.content}
    >
      <LinearGradient
        {...(colorScheme === 'light'
          ? gradients.lightIconDropdown
          : gradients.darkIconDropdown)}
        style={{ borderRadius: 7 }}
      >
        <ScrollView bounces={false} style={{ maxHeight: height * 0.5 }}>
          {options.map((option, index) => (
            <View key={option.label}>
              <TouchableOpacity
                key={option.label}
                style={[
                  styles.option,
                  {
                    ...(index < options.length - 1 && {
                      borderBottomWidth: 1,
                      borderColor:
                        colorScheme === 'light'
                          ? colors.lightGray10Percent
                          : colors.darkerSlateGray
                    })
                  }
                ]}
                onPress={() => {
                  option.onPress()
                  setVisible(false)
                }}
              >
                <Text variant="dropdownOption">{option.label}</Text>
              </TouchableOpacity>
            </View>
          ))}
        </ScrollView>
      </LinearGradient>
    </Menu>
  )
}

const styles = StyleSheet.create({
  option: {
    paddingVertical: 17,
    marginHorizontal: 17,
    flexDirection: 'row'
  },
  content: {
    paddingVertical: 0,
    backgroundColor: 'transparent',
    marginTop: 5
  }
})
