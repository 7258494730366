// External
import { addMinutes } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
// Services
import { createVisitRequest } from '@/visit/services'

interface Params {
  hosts: string[]
  date: {
    day: number
    month: number
    year: number
  }
  checkIn: string
  checkOut: string
  visitQuestionAnswers: Array<{
    questionId: string
    answer: string
  }>
  timezone: string
}

export const requestVisit = async ({
  date,
  checkIn,
  checkOut,
  timezone,
  ...rest
}: Params) => {
  try {
    const baseDate = zonedTimeToUtc(
      new Date(date.year, date.month - 1, date.day),
      timezone
    )
    const checkInDate = addMinutes(baseDate, parseInt(checkIn))
    const checkOutDate = addMinutes(baseDate, parseInt(checkOut))

    if (__DEV__) {
      console.log(checkInDate.toISOString())
      console.log(checkOutDate.toISOString())
    }

    await createVisitRequest({
      date: checkInDate.toISOString(),
      checkIn: checkInDate.toISOString(),
      checkOut: checkOutDate.toISOString(),
      ...rest
    })
  } catch (error) {
    return await Promise.reject(error)
  }
}
