import { memo } from 'react'
import { View, StyleSheet } from 'react-native'
import { Skeleton } from '@rneui/themed'

export const CardListSkeleton = memo(() => {
  return (
    <View style={styles.container}>
      <View style={styles.listHeader}>
        <Skeleton animation="wave" height={43} width={258.7} />

        <Skeleton circle height={32} width={32} />
      </View>

      <Skeleton animation="wave" height={176} />

      <Skeleton animation="wave" height={176} />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 25,
    flex: 1,
    gap: 25
  },
  listHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})
