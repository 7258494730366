// External
import {
  TouchableOpacity,
  View,
  StyleSheet,
  type ViewStyle,
  type TextStyle
} from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
import { SquareIconButton } from './SquareIconButton'
// Constants
import { colors } from '@/common/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Models
import type { StowlogIcon } from '@/common/models'

interface Props {
  style?: ViewStyle
  titleStyle?: TextStyle
  iconName?: StowlogIcon
  iconSize?: number
  onPress?: () => void
  title: string
  titleColor?: string
  text?: string
  action?: string
}

export const ActionItem = ({
  style,
  titleStyle,
  iconName,
  iconSize,
  onPress,
  title,
  titleColor,
  text,
  action
}: Props) => {
  const { t } = useTranslation()
  const isRTL = useIsRTL()

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      {iconName !== undefined && (
        <SquareIconButton iconName={iconName} iconSize={iconSize} />
      )}

      <View style={styles.field}>
        <Text
          style={titleStyle}
          variant="mediumBold"
          numberOfLines={1}
          color={titleColor}
        >
          {title}
        </Text>

        <Text numberOfLines={1} color={colors.text70Percent}>
          {text}
        </Text>
      </View>

      {onPress !== undefined && (
        <>
          <Text variant="baseBold" style={{ marginEnd: 15 }}>
            {action !== undefined ? action : t('edit')}
          </Text>

          <Icon name={isRTL ? 'back' : 'next'} size={12} />
        </>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 55,
    flexDirection: 'row',
    alignItems: 'center'
  },
  field: {
    flex: 1,
    marginStart: 10,
    marginEnd: 15
  }
})
