import { client } from '@/common/config'
import { type PTWRequestStatus, type PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  status: PTWRequestStatus
}

export const getPermits = async ({ status, page, limit }: Params) => {
  const { data, error } = await client.GET('/api/app/permit-to-work/requests', {
    params: {
      query: {
        status,
        page,
        limit
      }
    }
  })

  if (error !== undefined) {
    console.error('Error getting permits:', error)
    return await Promise.reject(error)
  }

  return data
}
