// External
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
// Services
import { getFacilityUserTypes } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const useCheckUserType = () => {
  const { currentUserType, currentFacility, removeUserType } = useAppStore(
    (state) => ({
      currentUserType: state.currentUserType,
      currentFacility: state.currentFacility,
      removeUserType: state.removeUserType
    })
  )

  const { data } = useQuery({
    queryKey: ['facilityUserTypes', currentFacility?._id],
    queryFn: getFacilityUserTypes
  })

  useEffect(() => {
    if (data !== undefined) {
      const userTypeExists = data.findIndex(
        (type) => type._id === currentUserType?._id
      )

      if (userTypeExists === -1) {
        removeUserType()
      }
    }
  }, [data, currentUserType])
}

export default useCheckUserType
