export enum CustomHeaders {
  OS = 'X-Stowlog-device-os',
  OS_VERSION = 'X-Stowlog-device-os-version',
  BUILD_NUMBER = 'X-Stowlog-device-build-number',
  APP_VERSION = 'X-Stowlog-device-app-version',
  USER_ID = 'X-Stowlog-user-id',
  LANGUAGE = 'X-Stowlog-language',
  API_VERSION = 'X-Stowlog-version',
  REFRESH_TOKEN = 'X-Stowlog-refresh-token'
}
