// External
import { useEffect } from 'react'
import {
  AndroidImportance,
  setNotificationChannelAsync,
  setNotificationHandler,
  type Subscription,
  addNotificationResponseReceivedListener
} from 'expo-notifications'
import { Platform } from 'react-native'
// Services
import { markNotificationAsOpened } from '@/notifications/services'
// Utils
import { onPressNotificationAction } from '@/notifications/utils'

const useNotifications = () => {
  const configureNotifications = async () => {
    setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false
      })
    })

    if (Platform.OS === 'android') {
      await setNotificationChannelAsync('default', {
        name: 'default',
        importance: AndroidImportance.DEFAULT
      })
    }
  }

  useEffect(() => {
    if (Platform.OS !== 'web') {
      void configureNotifications()
    }
  }, [])

  useEffect(() => {
    let subscription: Subscription
    if (Platform.OS !== 'web') {
      subscription = addNotificationResponseReceivedListener((response) => {
        try {
          const { data } = response.notification.request.content
          void markNotificationAsOpened(data.expoLogUUID)
          void onPressNotificationAction({
            actionData: data.actionData,
            actionType: data.actionType
          })
        } catch (error) {
          console.error(error)
        }
      })
    }

    return () => {
      if (subscription !== undefined) {
        subscription.remove()
      }
    }
  }, [])
}
export default useNotifications
