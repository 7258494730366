// External
import { Pressable, StyleSheet } from 'react-native'
import { useState } from 'react'
// Components
import { Text } from './Text'
import { RadioButton } from './RadioButton'
// Constants
import { colors } from '@/common/constants'

interface Props {
  label: string
  value: string
  onSelect: (value: string) => void
  isSelected: boolean
}

export const QuestionAnswer = ({
  label,
  value,
  onSelect,
  isSelected
}: Props) => {
  const [isPressed, setIsPressed] = useState(false)

  return (
    <Pressable
      style={[
        styles.container,
        {
          backgroundColor:
            isPressed || isSelected ? colors.hover : colors.inactive
        }
      ]}
      onPress={() => {
        onSelect(value)
      }}
      onPressIn={() => {
        setIsPressed(true)
      }}
      onPressOut={() => {
        setIsPressed(false)
      }}
    >
      <Text variant="baseBold" style={{ flexShrink: 1 }}>
        {label}
      </Text>

      <RadioButton
        checked={isPressed || isSelected}
        onPress={() => {
          onSelect(value)
        }}
      />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 50,
    width: '100%',
    borderRadius: 50,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20
  }
})
