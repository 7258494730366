import { useQuery } from '@tanstack/react-query'
import useAppStore from '@/common/stores/useAppStore'
import { getUserCustomFields } from '@/profile/useCases'

const useCustomFields = () => {
  const { locale, user, currentUserType, currentFacility } = useAppStore(
    (state) => ({
      locale: state.locale,
      user: state.user,
      currentUserType: state.currentUserType,
      currentFacility: state.currentFacility
    })
  )

  const { data, refetch } = useQuery({
    queryKey: [
      'userCustomFields',
      locale,
      user?._id,
      currentUserType?._id,
      currentFacility?._id
    ],
    queryFn: getUserCustomFields
  })

  return { data, refetch }
}

export default useCustomFields
