import { client } from '@/common/config'

interface Params {
  companyId: string
}

export const getCompanyContractor = async ({ companyId }: Params) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/contractor/me/{companyId}',
    {
      params: {
        path: {
          companyId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting company contractor:', error)
    return await Promise.reject(error)
  }

  return data.data
}
