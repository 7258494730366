interface Params {
  uri: string
  fileName: string
}

export const getWebFile = async ({ uri, fileName }: Params) => {
  try {
    const blob = await (await fetch(uri)).blob()
    const file = new File([blob], fileName, { type: blob.type })
    return file
  } catch (error) {
    console.error(error)
    return await Promise.reject(error)
  }
}
