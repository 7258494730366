// External
import type { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import useAppStore from '@/common/stores/useAppStore'
// Components
import { StepNavigationButtons, Text } from '@/common/components'
import { InductionVideoPlayer } from '@/safetyInduction/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { InductionStackParamList } from '@/safetyInduction/models'

type Props = StackScreenProps<InductionStackParamList, 'InductionVideo'>

const InductionVideo = ({ navigation, route }: Props) => {
  const { t } = useTranslation()
  const currentUserType = useAppStore((state) => state.currentUserType)
  const { inductionTest } = route.params

  if (currentUserType === undefined) {
    return null
  }

  return (
    <SafeArea>
      <View style={{ paddingHorizontal: 25 }}>
        <Text variant="h2Bold" style={styles.title}>
          {t('safetyInductionVideo', {
            userType: t(`userTypes.${currentUserType.slug}`)
          })}
        </Text>

        <Text>{t('pleaseWatchTheVideo')}</Text>
      </View>

      <View
        style={[
          styles.videoContainer,
          { ...(Platform.OS === 'web' && { alignItems: 'center' }) }
        ]}
      >
        <InductionVideoPlayer
          vimeoId={
            inductionTest.inductionVideo !== null
              ? inductionTest.inductionVideo.vimeoId
              : inductionTest.fallbackVideo?.vimeoId
          }
        />
      </View>

      <StepNavigationButtons
        style={styles.buttons}
        nextOnPress={() => {
          navigation.navigate('InductionTest', {
            questions: inductionTest.questions
          })
        }}
      />
    </SafeArea>
  )
}
export default InductionVideo

const styles = StyleSheet.create({
  title: {
    marginTop: 11,
    marginBottom: 9
  },
  videoContainer: {
    marginTop: 44,
    height: 240,
    width: '100%'
  },
  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  buttons: {
    marginHorizontal: 25,
    marginBottom: 25
  }
})
