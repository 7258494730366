import { client } from '@/common/config'

export const getFacilityUserTypes = async () => {
  const { data, error } = await client.GET(
    '/api/auth/app/facility/get-user-types'
  )

  if (error !== undefined) {
    console.error("Error getting facility's user types:", error)
    return await Promise.reject(error)
  }

  return data.data
}
