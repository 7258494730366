// External
import { Platform } from 'react-native'
// Services
import { getModules } from '@/home/services'
import { getGeopositionConfig } from '@/geoposition/services'
import { getPTWConfig } from '@/permitToWork/services'
// Models
import { Modules, type components } from '@/common/models'

export const getUserModules = async () => {
  try {
    const modules = await getModules()

    let geopositionConfig:
      | components['schemas']['GeopositionConfiguration']
      | null = null
    if (modules.includes(Modules.GEOPOSITION) && Platform.OS !== 'web') {
      geopositionConfig = await getGeopositionConfig()
    }

    let PTWConfig: components['schemas']['PTWConfig'] | null = null
    if (modules.includes(Modules.PERMIT_TO_WORK)) {
      PTWConfig = await getPTWConfig()
    }

    return {
      modules,
      geopositionConfig,
      PTWConfig
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
