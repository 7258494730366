// Services
import { checkInductionTest } from '@/safetyInduction/services'

export const submitInductionTest = async (
  answers: Array<{
    question: string
    answer: string
  }>
) => {
  try {
    const res = await checkInductionTest(answers)
    return res
  } catch (error) {
    return await Promise.reject(error)
  }
}
