// External
import { type SheetProps } from 'react-native-actions-sheet'
import { useTranslation } from 'react-i18next'
import { type ImagePickerAsset } from 'expo-image-picker'
import { useState } from 'react'
// Components
import {
  Text,
  Button,
  UploadOptions,
  SelectAttachment
} from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'

type Props = SheetProps<'edit-profile-photo'>

export const EditProfilePhoto = ({ payload, sheetId }: Props) => {
  const [photo, setPhoto] = useState<ImagePickerAsset | string | undefined>(
    payload?.initialValue
  )
  const [showUploadOptions, setShowUploadOptions] = useState(false)
  const { t } = useTranslation()

  return (
    <ActionSheetContainer id={sheetId}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('editPhoto')}
      </Text>

      {!showUploadOptions ? (
        <>
          <SelectAttachment
            photo={typeof photo === 'object' ? photo.uri : photo}
            onRemovePhoto={() => {
              setPhoto(undefined)
            }}
            iconName="profile-picture"
            text={t('uploadPhoto')}
            onPress={() => {
              setShowUploadOptions(true)
            }}
          />

          <Button
            style={{ marginTop: 45 }}
            title={t('saveChanges')}
            onPress={() => payload?.onSubmit(photo)}
          />
        </>
      ) : (
        <UploadOptions
          onImagePickerAsset={(asset) => {
            setPhoto(asset)
            setShowUploadOptions(false)
          }}
        />
      )}
    </ActionSheetContainer>
  )
}
