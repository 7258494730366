// Config
import { client } from '@/common/config'
// Models
import type { FacilityFilters } from '@/findFacility/models'
import type { PaginatedGetParams } from '@/common/models'

type Params = PaginatedGetParams & FacilityFilters

export const getFacilities = async ({
  page,
  limit = 10,
  search,
  region,
  country,
  type
}: Params) => {
  const { data, error } = await client.GET('/api/auth/app/facility', {
    params: {
      query: {
        page,
        limit,
        search,
        ...(region !== null && { regionCode: region }),
        ...(country !== null && { country }),
        ...(type !== null && { type: type._id })
      }
    }
  })

  if (error !== undefined) {
    return await Promise.reject(error)
  }

  return { results: data.results, nextPage: data.nextPage }
}
