// External
import { useEffect, useRef } from 'react'
import ActionSheet, { type ActionSheetRef } from 'react-native-actions-sheet'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Platform } from 'react-native'
import { Divider } from '@rneui/themed'
// Components
import { Icon, Text, Avatar, DragIndicator } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { formatDate } from '@/common/utils'

interface Props {
  signatures: Array<components['schemas']['PTWRequestSignatureDetail']>
}

export const PermitSignatures = ({ signatures }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()
  const signaturesSheetRef = useRef<ActionSheetRef>(null)

  useEffect(() => {
    signaturesSheetRef.current?.show()
  }, [])

  return (
    <ActionSheet
      ref={signaturesSheetRef}
      backgroundInteractionEnabled
      gestureEnabled
      closable={false}
      isModal={false}
      snapPoints={Platform.OS === 'web' ? [25] : [0]}
      initialSnapIndex={0}
      containerStyle={styles.container}
      CustomHeaderComponent={<DragIndicator style={{ marginBottom: 25 }} />}
    >
      <Text style={styles.header} variant="baseBold">
        {t('signatures')}
      </Text>

      <Divider />

      {signatures.map((signature) => (
        <View key={signature._id} style={styles.signatureContainer}>
          <Avatar
            size={40}
            borderRadius={20}
            {...(signature.signedBy?.profileImage !== undefined && {
              source: {
                uri: signature.signedBy.profileImage
              }
            })}
            initials={
              signature.signedBy !== undefined
                ? `${signature.signedBy.firstName[0] as string}${
                    signature.signedBy.lastName[0] as string
                  }`
                : t(`permitSignatureInitialsPlaceholders.${signature.name}`)
            }
          />

          <View style={{ flex: 1 }}>
            <Text variant="smallBold">
              {t(`permitSignatures.${signature.name}`)}
            </Text>

            <Text variant="small">
              {signature.signedBy !== undefined
                ? `${signature.signedBy.firstName} ${signature.signedBy.lastName}`
                : t('pending')}
              {signature.signedAt !== null && (
                <Text variant="small">
                  {` - ${formatDate(
                    new Date(signature.signedAt),
                    'Pp',
                    currentFacility?.info.timezone
                  )}`}
                </Text>
              )}
            </Text>
          </View>

          <Icon
            style={{ marginStart: 'auto' }}
            name={signature.signedAt !== null ? 'check-fill' : 'alert-fill'}
            color={signature.signedAt !== null ? colors.success : colors.rajah}
            size={32}
          />
        </View>
      ))}
    </ActionSheet>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingTop: 10,
    paddingHorizontal: 25,
    paddingBottom: 13.5,
    backgroundColor: '#FAFAFA'
  },
  header: {
    marginBottom: 25,
    textAlign: 'center'
  },
  signatureContainer: {
    flexDirection: 'row',
    gap: 15,
    padding: 11.5,
    alignItems: 'center'
  }
})
