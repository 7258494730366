// External
import { Platform } from 'react-native'
import type { ImagePickerAsset } from 'expo-image-picker'
// Config
import { client } from '@/common/config'
// Utils
import { getWebFile, getAndroidFileType } from '@/common/utils'

interface Params {
  id: string
  value: ImagePickerAsset[]
}

export const uploadCustomFieldAttachments = async ({ id, value }: Params) => {
  try {
    let attachments: any

    if (Platform.OS === 'web') {
      const promises = value.map(
        async (attachment, index) =>
          await getWebFile({
            uri: attachment.uri,
            fileName: `Attachment ${index + 1}`
          })
      )
      attachments = await Promise.all(promises)
    } else {
      attachments = value.map((attachment, index) => ({
        name: attachment.fileName ?? `Attachment ${index + 1}`,
        type:
          Platform.OS === 'android'
            ? getAndroidFileType(attachment)
            : attachment.type,
        uri:
          Platform.OS === 'ios'
            ? attachment.uri.replace('file://', '')
            : attachment.uri
      }))
    }

    const body = new FormData()

    // @ts-expect-error - attachment implicitly has an 'any' type
    attachments.forEach((attachment) => {
      body.append('attachments', attachment)
    })

    const { error } = await client.POST(
      '/api/app-user/custom-fields/{customFieldId}/upload',
      {
        params: {
          path: {
            customFieldId: id
          }
        },
        // @ts-expect-error - body doesn't match OpenAPI's generated type
        body,
        // ! will not work if this line is removed
        bodySerializer: (body) => body
      }
    )

    if (error !== undefined) {
      console.error('Error uploading custom field attachments:', error)
      return await Promise.reject(error)
    }
  } catch (error) {
    console.error('Error uploading custom field attachments:', error)
    return await Promise.reject(error)
  }
}
