// External
import { getLocales } from 'expo-localization'
// Models
import { type Coordinates } from '@/common/models'

export const calculateDistanceToFacility = (
  point1: Coordinates,
  point2: Coordinates
) => {
  const measurementSystem = getLocales()[0]?.measurementSystem
  const isMetric = measurementSystem === 'metric'
  const earthRadius = 6371 // Earth's radius in kilometers
  const lat1 = degreesToRadians(point1.lat)
  const lon1 = degreesToRadians(point1.long)
  const lat2 = degreesToRadians(point2.lat)
  const lon2 = degreesToRadians(point2.long)

  const dLat = lat2 - lat1
  const dLon = lon2 - lon1

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceInKm = earthRadius * c
  const distanceInMtr = distanceInKm * 1000

  const unit = isMetric ? (distanceInMtr >= 1000 ? 'km' : 'm') : 'mi' // 'mi' for miles in imperial system

  let value
  if (isMetric) {
    value = unit === 'km' ? Math.round(distanceInKm) : Math.round(distanceInMtr)
  } else {
    const distanceInMiles = distanceInKm * 0.621371 // Convert km to miles for imperial system
    value =
      distanceInMiles >= 1
        ? Math.round(distanceInMiles)
        : distanceInMiles.toFixed(2)
  }

  return {
    unit,
    value
  }
}

const degreesToRadians = (degrees: number) => {
  return degrees * (Math.PI / 180)
}
