// External
import type { DrawerType } from 'react-native-gesture-handler'
import { Platform, Dimensions, type DimensionValue } from 'react-native'
// Constants
import { colors } from '@/common/constants'

const getDrawerType = (width: number) => {
  if (Platform.OS === 'web' && width > 1024) {
    return 'permanent'
  }

  if (Platform.OS === 'web') {
    return 'front'
  }

  return 'slide'
}

const getDrawerWidth = (width: number): DimensionValue => {
  if (width > 1280) {
    return '20%'
  }

  if (width > 1024) {
    return '33.3%'
  }

  if (width > 768) {
    return '40%'
  }

  return '80%'
}

export const getDrawerScreenOptions = () => {
  const { width } = Dimensions.get('window')

  const options = {
    headerShown: false,
    drawerActiveBackgroundColor: 'transparent',
    drawerType: getDrawerType(width) as DrawerType,
    drawerInactiveBackgroundColor: 'transparent',
    overlayColor: 'transparent',
    drawerStyle: {
      backgroundColor: colors.drawer,
      width: getDrawerWidth(width),
      ...(Platform.OS === 'web' && { borderLeftWidth: 0, borderRightWidth: 0 })
    },
    sceneContainerStyle: {
      backgroundColor: colors.drawer
    }
  }

  return options
}
