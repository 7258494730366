// External
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
// Components
import { Text } from './Text'
import { Icon } from './Icon'
// Constants
import { colors } from '@/common/constants'
// Models
import { type ToastProps } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const CustomToast = ({ type, data }: ToastProps) => {
  const colorScheme = useAppStore((state) => state.colorScheme)
  const { top } = useSafeAreaInsets()
  const { t } = useTranslation()

  const generateTitle = () => {
    if (data.titleTranslationKey !== undefined) {
      return t(data.titleTranslationKey)
    }

    if (type === 'success') {
      return t('success')
    }

    return t('error')
  }

  return (
    <View
      style={[
        {
          borderRadius: 10,
          flexDirection: 'row',
          gap: 16,
          padding: 15,
          borderWidth: 0.5,
          alignItems: 'center',
          marginTop: top,
          maxWidth: 320
        },
        {
          backgroundColor:
            colorScheme === 'light' ? colors.white : colors.uploadDark,
          borderColor: type === 'success' ? colors.success : colors.error,
          elevation: 6,
          shadowOffset: {
            width: 0,
            height: colorScheme === 'light' ? 4 : 5
          },
          shadowOpacity: colorScheme === 'light' ? 0.1 : 0.15,
          shadowRadius: 6
        }
      ]}
    >
      <Icon
        name={type === 'success' ? 'success' : 'alert'}
        color={type === 'success' ? colors.success : colors.error}
        size={29}
      />

      <View style={{ flex: 1 }}>
        <Text
          variant="baseBold"
          {...(type === 'error' && { style: { color: colors.error } })}
        >
          {generateTitle()}
        </Text>

        <Text>{t(data.messageTranslationKey)}</Text>
      </View>
    </View>
  )
}
