import { client } from '@/common/config'

export const getEmailAvailable = async (email: string) => {
  const { data, error } = await client.POST('/api/auth/app/email/available', {
    body: {
      email
    }
  })

  if (error !== undefined) {
    console.error('Error checking if email is available:', error)
    return await Promise.reject(error)
  }

  return data.data
}
