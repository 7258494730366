/* eslint-disable @typescript-eslint/restrict-template-expressions */
// External
import { yupResolver } from '@hookform/resolvers/yup'
import type { StackScreenProps } from '@react-navigation/stack'
import { useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { array, boolean, type InferType, object, string } from 'yup'
// Components
import {
  Avatar,
  Checkbox,
  Icon,
  InfiniteSelect,
  ProgressBar,
  StepNavigationButtons,
  Text,
  UserInformation
} from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
// Layouts
import { KeyboardFix, SafeArea } from '@/common/layouts'
import { getContractors } from '@/permitToWork/services'
// Models
import type { components } from '@/common/models'
import type { PTWStackParamList } from '@/permitToWork/models'

type Props = StackScreenProps<PTWStackParamList, 'CreatePermitStaffOnSite'>

const CreatePermitStaffOnSite = ({ navigation, route }: Props) => {
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      object({
        staffOnSiteContractors: array(
          object({
            _id: string().required(),
            appUser: object({
              profileImage: string(),
              firstName: string().required(),
              lastName: string().required(),
              fullName: string().required()
            })
          }).required()
        ).required(),
        contractorsAwareOfRisks: boolean()
          .isTrue(t('youNeedToAcceptTheAdditionalRequirement'))
          .required()
      }),
    []
  )

  type FormValues = InferType<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      staffOnSiteContractors: [],
      // @ts-expect-error - The type seems to generate incorrectly from the schema, it should be a boolean
      // that's validated to be true on submit, but the type sets it to true instead of boolean
      contractorsAwareOfRisks: false
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'staffOnSiteContractors'
  })

  const onSubmit = ({ staffOnSiteContractors }: FormValues) => {
    const staffOnSiteContractorIds = staffOnSiteContractors.map(
      (contractor) => contractor._id
    )

    if (ptwConfig?.hasPreArrangementsEnabled ?? false) {
      navigation.navigate('CreatePermitStepPreArrangements', {
        ...route.params,
        staffOnSiteContractorIds
      })
      return
    }

    navigation.navigate('CreatePermitStepPPE', {
      ...route.params,
      staffOnSiteContractorIds
    })
  }

  return (
    <SafeArea>
      <KeyboardFix style={styles.container}>
        <ProgressBar
          style={styles.progressBar}
          value={ptwConfig?.hasPreArrangementsEnabled ?? false ? 0.5 : 0.57}
        />

        <Text variant="h2Bold" style={{ marginBottom: 8 }}>
          {t('staffOnSite')}
        </Text>

        <Text style={{ marginBottom: 25 }}>{t('pleaseSearchContractors')}</Text>

        <InfiniteSelect<components['schemas']['PTWContractorRowItemResponse']>
          keepPlaceholder
          placeholder={t('selectContractors')}
          searchable
          searchPlaceholder={t('searchContractors')}
          queryKey={['contractors-search', currentFacility?._id]}
          queryFn={async (params) =>
            await getContractors({
              facilityId: currentFacility?._id as string,
              companyId: route.params.companyId,
              ...params
            })
          }
          onSelect={(option) => {
            if (fields.some((contractor) => contractor._id === option.value)) {
              toast.showError({
                data: {
                  messageTranslationKey: 'contractorAlreadySelected'
                }
              })
              return
            }
            if (option.original !== undefined) {
              append(option.original)
            }
          }}
          getOptions={(data) =>
            data.map((contractor) => ({
              label: `${contractor.appUser.firstName} ${contractor.appUser.lastName}`,
              value: contractor._id,
              original: contractor
            }))
          }
          noResultsTranslationKey="noContractorsFound"
          renderOption={({ original }) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            <UserInformation {...original?.appUser!} />
          )}
        />

        {fields.length > 0 && (
          <View style={styles.selectedContractors}>
            {fields.map((contractor, index) => (
              <View key={contractor._id} style={styles.listItem}>
                <Avatar
                  size={40}
                  borderRadius={20}
                  {...(contractor.appUser.profileImage !== undefined
                    ? {
                        source: { uri: contractor.appUser.profileImage }
                      }
                    : {
                        initials: `${contractor.appUser.firstName[0]}${contractor.appUser.lastName[0]}`
                      })}
                />

                <Text>{contractor.appUser.fullName}</Text>

                <Icon
                  style={{ marginStart: 'auto' }}
                  name="remove-file"
                  size={16}
                  onPress={() => {
                    remove(index)
                  }}
                />
              </View>
            ))}
          </View>
        )}

        <Text variant="small" style={styles.additionalRequests}>
          {t('additionalRequests')}
        </Text>

        <View style={styles.condition}>
          <Controller
            control={control}
            name="contractorsAwareOfRisks"
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                checked={value}
                onPress={() => {
                  onChange(!value)
                }}
                onBlur={onBlur}
              />
            )}
          />

          <Text style={{ flex: 1 }}>{t('iAgreeAllTheContractors')}</Text>
        </View>

        {errors.contractorsAwareOfRisks?.message !== undefined && (
          <Text style={styles.error}>
            {errors.contractorsAwareOfRisks.message}
          </Text>
        )}

        <StepNavigationButtons
          nextOnPress={handleSubmit(onSubmit)}
          nextDisabled={isSubmitting}
        />
      </KeyboardFix>
    </SafeArea>
  )
}

export default CreatePermitStaffOnSite

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  condition: {
    flexDirection: 'row',
    gap: 16
  },
  searchContractorsList: {
    position: 'absolute',
    backgroundColor: colors.white,
    top: 4,
    left: 0,
    right: 0,
    borderRadius: 7,
    borderColor: colors.inactive,
    borderWidth: 1
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    gap: 8
  },
  additionalRequests: {
    marginTop: 25,
    marginBottom: 16
  },
  selectedContractors: {
    marginTop: 5,
    backgroundColor: colors.inactive,
    borderRadius: 7
  },
  noContractorsFound: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    marginTop: 12,
    color: colors.error,
    fontFamily: 'PlusJakartaSans_400Regular'
  }
})
