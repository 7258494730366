import { Divider, Skeleton } from '@rneui/themed'
import { StyleSheet, View } from 'react-native'

export const PermitDetailSkeleton = () => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={{ flex: 1 }}>
          <Skeleton animation="wave" height={33} />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            gap: 8,
            justifyContent: 'flex-end'
          }}
        >
          <Skeleton animation="wave" height={33} width={33} circle />

          <Skeleton animation="wave" height={33} width={70} />
        </View>
      </View>

      <Divider style={{ marginVertical: 20 }} />

      <View>
        <View style={styles.row}>
          <Skeleton animation="wave" height={33} width={120} />

          <Skeleton animation="wave" height={33} width={60} />
        </View>

        <Skeleton style={{ marginTop: 8 }} animation="wave" height={120} />
      </View>

      {Array.from({ length: 5 }).map((_, i) => (
        <View key={i}>
          <Skeleton
            animation="wave"
            style={styles.label}
            height={20}
            width={120}
          />
          <Skeleton animation="wave" height={40} />
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    gap: 20
  },
  spaceVertical: {
    marginVertical: 16
  },
  label: {
    marginBottom: 8
  },
  row: {
    gap: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
  }
})
