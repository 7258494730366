// External
import { useTranslation } from 'react-i18next'
// Components
import { Text } from '@/common/components'
import { VerifyEmailForm } from './VerifyEmailForm'
// Constants
import { toast } from '@/common/constants'
// Services
import { verifyEmail } from '@/auth/services'
import { getUser } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { handleError } from '@/common/utils'

export const EmailVerificationRequired = () => {
  const { user, setUser } = useAppStore((state) => ({
    user: state.user,
    setUser: state.setUser
  }))
  const { hideModal } = usePortalStore((state) => ({
    hideModal: state.hideModal
  }))

  const { t } = useTranslation()

  return (
    <>
      <Text variant="baseBold" style={{ marginBottom: 8 }}>
        {t('emailVerificationRequiredTitle')}
      </Text>

      <Text style={{ marginBottom: 24 }}>
        {t('emailVerificationRequiredText', { email: user?.email })}
      </Text>

      <VerifyEmailForm
        email={user?.email as string}
        onSubmit={async ({ verificationCode }) => {
          try {
            if (user !== undefined) {
              await verifyEmail({ email: user.email, verificationCode })
              const updatedUser = await getUser()
              setUser({ ...user, ...updatedUser })
              toast.showSuccess({
                data: {
                  messageTranslationKey: 'emailVerified'
                }
              })
              hideModal()
            }
          } catch (error) {
            handleError(error)
          }
        }}
        sendAgainType="signupOrVerify"
        buttonType="submit"
      />
    </>
  )
}
