import { colors } from '@/common/constants'
import type { VisitRequestStatus } from '@/common/models'

export const visitStatusColors: Record<
  VisitRequestStatus,
  {
    background: string
    text: string
    icon: string
    iconBackground: string
  }
> = {
  APPROVED: {
    background: '#EEFFFA',
    text: colors.success,
    icon: colors.white,
    iconBackground: colors.success
  },
  PENDING: {
    background: '#FFFAF6',
    text: colors.rajah,
    icon: colors.white,
    iconBackground: colors.rajah
  },
  DENIED: {
    background: '#FFF6F4',
    text: colors.error,
    icon: colors.white,
    iconBackground: colors.error
  },
  CANCELED: {
    background: '#F5F5F5',
    text: '#919191',
    icon: colors.white,
    iconBackground: '#919191'
  },
  EXPIRED: {
    background: '#EBF5F8',
    text: '#778A92',
    icon: colors.white,
    iconBackground: '#778A92'
  }
}
