// External
import { useState, useRef } from 'react'
import {
  StyleSheet,
  View,
  TextInput,
  Pressable,
  type ViewStyle
} from 'react-native'
// Components
import { Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const CODE_LENGTH = 4

interface Props {
  style?: ViewStyle
  value: string
  onChangeText: (text: string) => void
}

export const VerificationCodeInput = ({
  style,
  value,
  onChangeText
}: Props) => {
  const [containerIsFocused, setContainerIsFocused] = useState(false)
  const colorScheme = useAppStore((state) => state.colorScheme)

  const codeDigitsArray = new Array(CODE_LENGTH).fill(0)

  const ref = useRef<TextInput>(null)

  const handleOnPress = () => {
    setContainerIsFocused(true)
    ref?.current?.focus()
  }

  const handleOnBlur = () => {
    setContainerIsFocused(false)
  }

  const toDigitInput = (_value: number, idx: number) => {
    const digit = value.length > 0 ? value[idx] : ' '

    const isCurrentDigit = idx === value.length
    const isLastDigit = idx === CODE_LENGTH - 1
    const isCodeFull = value.length === CODE_LENGTH

    const isFocused = isCurrentDigit || (isLastDigit && isCodeFull)

    const inputContainerThemed = {
      ...styles.inputContainer,
      backgroundColor:
        colorScheme === 'light' ? colors.inactive : colors.darkSlateGray
    }

    const focusedInputContainerThemed = {
      ...styles.focusedInputContainer,
      backgroundColor:
        colorScheme === 'light' ? colors.active : colors.darkerSlateGray
    }

    const containerStyle =
      containerIsFocused && isFocused
        ? { ...inputContainerThemed, ...focusedInputContainerThemed }
        : inputContainerThemed

    return (
      <View key={idx} style={containerStyle}>
        <Text style={[styles.inputText]}>{digit}</Text>
      </View>
    )
  }

  return (
    <View style={[{ alignItems: 'center' }, style]}>
      <Pressable style={styles.inputsContainer} onPress={handleOnPress}>
        {codeDigitsArray.map(toDigitInput)}
      </Pressable>

      <TextInput
        ref={ref}
        value={value}
        onChangeText={onChangeText}
        onSubmitEditing={handleOnBlur}
        keyboardType="number-pad"
        returnKeyType="done"
        textContentType="oneTimeCode"
        maxLength={CODE_LENGTH}
        style={styles.hiddenCodeInput}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  inputsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15
  },
  inputContainer: {
    borderRadius: 7,
    padding: 12,
    height: 52,
    width: 42,
    justifyContent: 'center',
    alignItems: 'center'
  },
  focusedInputContainer: {
    borderWidth: 1,
    borderColor: colors.hydro
  },
  inputText: {
    fontSize: 13,
    lineHeight: 18,
    fontFamily: 'PlusJakartaSans_700Bold'
  },
  hiddenCodeInput: {
    position: 'absolute',
    opacity: 0
  }
})
