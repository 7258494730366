// External
import { useEffect } from 'react'
import {
  addNotificationResponseReceivedListener,
  type NotificationResponse
} from 'expo-notifications'
import { useNavigation, type NavigationProp } from '@react-navigation/native'
import { Platform } from 'react-native'
// Models
import type { components } from '@/common/models'
import type { HomeDrawerParamList } from '@/home/models'
// Services
import {
  getFacilityDetail,
  getUserType,
  selectFacility,
  selectFacilityUserType
} from '@/findFacility/services'
import { getUser } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const useRedirectNotificationsHandler = () => {
  if (Platform.OS === 'web') {
    return
  }

  const navigation = useNavigation<NavigationProp<HomeDrawerParamList>>()
  const { currentUserType, currentFacility, logIntoFacility } = useAppStore(
    (state) => ({
      currentUserType: state.currentUserType,
      currentFacility: state.currentFacility,
      logIntoFacility: state.logIntoFacility
    })
  )

  useEffect(() => {
    const subscription = addNotificationResponseReceivedListener((response) => {
      const handleResponse = async (response: NotificationResponse) => {
        try {
          const { facilityId, userTypeId } =
            response.notification.request.content.data

          if (
            (facilityId !== currentFacility?._id &&
              userTypeId !== currentUserType?._id) ||
            facilityId !== currentFacility?._id
          ) {
            const userType = await getUserType(userTypeId)
            const facility = await getFacilityDetail(facilityId)

            await selectFacility(facilityId)

            await selectFacilityUserType(userTypeId)

            const user = await getUser()

            logIntoFacility(user, userType, [], facility)
          } else if (userTypeId !== currentUserType?._id) {
            const userType = await getUserType(userTypeId)

            await selectFacilityUserType(userTypeId)

            const user = await getUser()

            logIntoFacility(
              user,
              userType,
              [],
              currentFacility as components['schemas']['FacilityDetailResponse']
            )
          }

          navigation.navigate('Notifications')
        } catch (error) {
          console.error('Error on useRedirectNotificationsHandler:', error)
        }
      }

      void handleResponse(response)
    })

    return () => {
      if (subscription !== undefined) {
        subscription.remove()
      }
    }
  }, [currentFacility, currentUserType, logIntoFacility, navigation])
}
export default useRedirectNotificationsHandler
