// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import type { InductionStackParamList } from '@/safetyInduction/models'
// Views
import InductionVideo from '@/safetyInduction/views/InductionVideo'
import InductionTest from '@/safetyInduction/views/InductionTest'
import SuccessfulInduction from '@/safetyInduction/views/SuccessfulInduction'

const Stack = createStackNavigator<InductionStackParamList>()

const InductionStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="InductionVideo" component={InductionVideo} />

      <Stack.Screen name="InductionTest" component={InductionTest} />

      <Stack.Screen
        name="SuccessfulInduction"
        component={SuccessfulInduction}
      />
    </Stack.Navigator>
  )
}
export default InductionStack
