// External
import { Platform } from 'react-native'
import { SearchBar as RNESearchBar, type SearchBarProps } from '@rneui/themed'
// Components
import { Icon } from './Icon'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const SearchBar = (props: SearchBarProps) => {
  const { locale } = useAppStore((state) => ({
    locale: state.locale
  }))

  return (
    <RNESearchBar
      {...(Platform.OS === 'web' && { lang: locale })}
      searchIcon={<Icon name="search" size={19} />}
      {...props}
    />
  )
}
