import { socketUrl } from '@/common/constants'
import {
  GeopositionEvents,
  type GeopositionRoomPayload
} from '@/geoposition/models'
import { io } from 'socket.io-client'

export const socket = io(`${socketUrl}/geoposition`, {
  transports: ['websocket'],
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5
})

socket.on('connect', () => {
  console.log('Connected to geoposition socket')
})

socket.on('disconnect', () => {
  console.log('Disconnected from geoposition socket')
})

socket.on('error', (error) => {
  console.log('Geoposition socket error:', error)
})

socket.on(GeopositionEvents.TRACK, (payload: GeopositionRoomPayload) => {
  console.log('Emitted payload:', payload.location)
})
