// External
import {
  ScrollView,
  View,
  Image,
  TouchableOpacity,
  StyleSheet
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Text, CircleIconButton, Icon } from '@/common/components'
// Constants
import { colors, shadows } from '@/common/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Models
import type { components } from '@/common/models'

interface Props {
  onPressBack: () => void
  userTypes: Array<components['schemas']['GetFacilityUserTypesResponse']>
  onSelectUserType: (
    userType: components['schemas']['GetFacilityUserTypesResponse']
  ) => Promise<void>
}

export const SelectUserTypeScreen = ({
  onPressBack,
  userTypes,
  onSelectUserType
}: Props) => {
  const { t } = useTranslation()
  const { top, bottom, left, right } = useSafeAreaInsets()
  const isRTL = useIsRTL()

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          marginTop: 11 + top,
          marginLeft: left + 25,
          marginRight: right + 25
        }}
      >
        <View style={{ marginBottom: 34 }}>
          <CircleIconButton
            iconName={isRTL ? 'next' : 'back'}
            onPress={onPressBack}
            style={styles.backButton}
          />

          <Text variant="baseBold" style={styles.userTypeText}>
            {t('facilityUserType')}
          </Text>
        </View>

        <Text>{t('selectYourUserType')}</Text>
      </View>

      <ScrollView
        contentContainerStyle={{
          paddingTop: 25,
          paddingBottom: bottom + 25,
          paddingLeft: left + 25,
          paddingRight: right + 25
        }}
        bounces={false}
      >
        {userTypes.map((type, index, array) => (
          <TouchableOpacity
            key={type._id}
            style={[
              styles.typeContainer,
              {
                backgroundColor: colors.white,
                ...(index < array.length && { marginBottom: 20 })
              }
            ]}
            onPress={async () => {
              void onSelectUserType(type)
            }}
          >
            <Image style={styles.typeImage} source={{ uri: type.imageUrl }} />

            <Text variant="largeBold" style={{ flex: 1 }}>
              {t(`userTypes.${type.slug}`)}
            </Text>

            <Icon
              name={isRTL ? 'back' : 'next'}
              size={16}
              style={{ marginEnd: 'auto' }}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  backButton: {
    position: 'absolute',
    top: 0,
    start: 0,
    zIndex: 1
  },
  userTypeText: {
    textAlign: 'center',
    marginVertical: 15.5
  },
  typeContainer: {
    height: 116,
    paddingStart: 8,
    paddingVertical: 10,
    paddingEnd: 18,
    borderRadius: 10,
    ...shadows.default,
    gap: 25,
    flexDirection: 'row',
    alignItems: 'center'
  },
  typeImage: {
    height: 100,
    width: 100,
    borderRadius: 10
  }
})
