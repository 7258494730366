// External
import * as SecureStore from 'expo-secure-store'
import { Platform } from 'react-native'
// Models
import { TokenType } from '@/auth/models'

export const removeTokens = async () => {
  try {
    if (Platform.OS === 'web') {
      const cookieOptions = 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

      document.cookie = `${TokenType.AUTH}=; ${cookieOptions}`
      document.cookie = `${TokenType.REFRESH}=; ${cookieOptions}`
    } else {
      await SecureStore.deleteItemAsync(TokenType.AUTH, {
        keychainAccessible: SecureStore.AFTER_FIRST_UNLOCK
      })
      await SecureStore.deleteItemAsync(`${TokenType.AUTH}_expiration`, {
        keychainAccessible: SecureStore.AFTER_FIRST_UNLOCK
      })
      await SecureStore.deleteItemAsync(TokenType.REFRESH, {
        keychainAccessible: SecureStore.AFTER_FIRST_UNLOCK
      })
    }
  } catch (error) {
    console.error('Error removing tokens:', error)
    return await Promise.reject(error)
  }
}
