// External
import { useState, useEffect } from 'react'
import { AppState } from 'react-native'
import { getLastKnownPositionAsync } from 'expo-location'
// Models
import { type Coordinates } from '@/common/models'

const useCurrentPosition = () => {
  const [currentPosition, setCurrentPosition] = useState<Coordinates>()

  const getCurrentPosition = async () => {
    try {
      const position = await getLastKnownPositionAsync()

      if (position !== null) {
        setCurrentPosition({
          lat: position.coords.latitude,
          long: position.coords.longitude
        })
      } else {
        setCurrentPosition(undefined)
      }
    } catch (error) {
      console.warn("Can't get current position:", error)
      setCurrentPosition(undefined)
    }
  }

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      (nextAppState: string) => {
        if (nextAppState === 'active') {
          void getCurrentPosition()
        }
      }
    )

    void getCurrentPosition()

    return () => {
      subscription.remove()
    }
  }, [])

  return currentPosition
}

export default useCurrentPosition
