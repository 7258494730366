// External
import { TouchableOpacity } from 'react-native-gesture-handler'
import { StyleSheet, View } from 'react-native'
import { Input } from '@rneui/themed'
import { SheetManager } from 'react-native-actions-sheet'
import type { ImagePickerAsset } from 'expo-image-picker'
// Components
import { Icon, Text } from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Utils
import { getAttachmentName } from '@/common/utils'

interface Props {
  attachments: ImagePickerAsset[]
  onAddAttachment?: (attachment: ImagePickerAsset) => void
  onRemoveAttachment?: (index: number) => void
  limit?: number
  name: string
  description: string
  placeholder: string
  errorMessage?: string
}

export const CustomFieldAttachmentList = ({
  attachments,
  onAddAttachment,
  onRemoveAttachment,
  limit = 3,
  name,
  description,
  placeholder,
  errorMessage
}: Props) => {
  return (
    <>
      <Text style={{ marginBottom: 2 }}>{name}</Text>

      <Text style={{ marginBottom: 4 }} variant="small">
        {description}
      </Text>

      <TouchableOpacity
        {...(onAddAttachment !== undefined && {
          onPress: () => {
            if (attachments.length >= limit) {
              toast.showError({
                data: {
                  messageTranslationKey: 'attachmentLimitReached'
                }
              })
              return
            }

            void SheetManager.show('upload-options', {
              payload: {
                onImagePickerAsset: (asset) => {
                  onAddAttachment(asset)
                  void SheetManager.hide('upload-options')
                }
              }
            })
          }
        })}
      >
        <Input
          inputContainerStyle={{
            borderColor:
              errorMessage !== undefined ? colors.error : 'transparent'
          }}
          editable={false}
          placeholder={placeholder}
          rightIcon={<Icon name="add-file" size={20} />}
          errorMessage={errorMessage}
        />
      </TouchableOpacity>

      {attachments.length > 0 && (
        <View style={{ gap: 10 }}>
          {attachments.map((attachment, index) => (
            <View key={index} style={styles.attachmentContainer}>
              <Icon name="file" size={20} />

              <Text style={{ flex: 1 }} numberOfLines={1} variant="small">
                {getAttachmentName({ attachment, index })}
              </Text>

              {onRemoveAttachment !== undefined && (
                <Icon
                  name="remove-file"
                  size={16}
                  onPress={() => {
                    onRemoveAttachment(index)
                  }}
                />
              )}
            </View>
          ))}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  attachmentContainer: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 7,
    borderColor: colors.inactive,
    borderWidth: 1,
    paddingHorizontal: 15,
    gap: 6
  }
})
