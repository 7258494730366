// External
import Constants from 'expo-constants'
import createClient, { type Middleware } from 'openapi-fetch'
import { Platform } from 'react-native'
// Constants
import { apiUrl, defaultLocale, toast } from '@/common/constants'
// Models
import { TokenType } from '@/auth/models'
import { type paths, CustomHeaders } from '@/common/models'
// Utils
import { getToken, storeToken } from '@/auth/utils'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const noMiddlewareClient = createClient<paths>({
  baseUrl: apiUrl.replace('.com/api', '.com')
})

const middleware: Middleware = {
  onRequest: async (req) => {
    const { url } = req

    if (
      url.includes('sign-in') ||
      url.includes('register') ||
      url.includes('forgot-password') ||
      url.includes('reset-password') ||
      url.includes('app-versioning')
    ) {
      return req
    }

    let token = await getToken(TokenType.AUTH)

    if (token === null) {
      const refreshToken = await getToken(TokenType.REFRESH)

      if (refreshToken !== null) {
        const { data, error } = await noMiddlewareClient.POST(
          '/api/auth/app/refresh',
          {
            headers: {
              [CustomHeaders.REFRESH_TOKEN]: refreshToken
            }
          }
        )

        if (error !== undefined) {
          console.error('Error refreshing token:', error)
          return req
        }

        await storeToken(
          data.data.accessToken,
          TokenType.AUTH,
          data.data.expirationToken
        )
        token = data.data.accessToken
        if (__DEV__) {
          console.log('✅ Token refreshed successfully')
        }
      }
    }

    if (token !== null) {
      req.headers.append('Authorization', `Bearer ${token}`)
      req.headers.append(CustomHeaders.OS, Platform.OS)
      req.headers.append(
        CustomHeaders.OS_VERSION,
        Platform.OS === 'web'
          ? window.navigator.userAgent
          : Platform.Version.toString()
      )

      if (Platform.OS !== 'web') {
        req.headers.append(
          CustomHeaders.BUILD_NUMBER,
          Platform.select({
            ios: Constants.expoConfig?.ios?.buildNumber,
            android: Constants.expoConfig?.android?.versionCode?.toString()
          }) as string
        )

        req.headers.append(
          CustomHeaders.APP_VERSION,
          Constants.expoConfig?.version as string
        )
      }

      const { user, locale } = useAppStore.getState()
      req.headers.append(CustomHeaders.USER_ID, user?._id as string)
      req.headers.append(
        CustomHeaders.LANGUAGE,
        // @ts-expect-error - This locale doesn't exist as an option anymore but may be stored and selected for some users
        locale === 'en' ? defaultLocale : locale
      )
    }
    return req
  },
  onResponse: async (res) => {
    if (
      (res.status === 401 && !res.url.includes('/auth/app/sign-in')) ||
      res.status === 403
    ) {
      useAppStore.getState().logOut()
      toast.showError({
        data: {
          titleTranslationKey: 'error',
          messageTranslationKey: 'sessionExpired'
        }
      })
    }
    return res
  }
}

export const client = createClient<paths>({
  baseUrl: apiUrl.replace('.com/api', '.com')
})

client.use(middleware)
