// External
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState, useCallback } from 'react'
import { type DrawerScreenProps } from '@react-navigation/drawer'
import { useFocusEffect } from '@react-navigation/native'
// Components
import { CircleIconButton, Text, Select, Button } from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type {
  FacilityFilters,
  FindFacilityDrawerParamList
} from '@/findFacility/models'
// Services
import { getFacilityFilters } from '@/findFacility/services'
// Utils
import { handleError } from '@/common/utils'

type Props = DrawerScreenProps<FindFacilityDrawerParamList, 'Filters'>

const Filters = ({ navigation, route }: Props) => {
  const [filters, setFilters] = useState<FacilityFilters>({
    region: null,
    country: null,
    type: null
  })
  const { t } = useTranslation()

  const { data, error, isError } = useQuery({
    queryKey: ['facilityTypes'],
    queryFn: getFacilityFilters
  })

  useEffect(() => {
    if (isError) {
      navigation.goBack()
      handleError(error)
    }
  }, [isError, error])

  useFocusEffect(
    useCallback(() => {
      setFilters(route.params)
    }, [route.params])
  )

  return (
    <SafeArea
      style={{
        paddingHorizontal: 25
      }}
    >
      <View
        style={{
          marginTop: 11
        }}
      >
        <View style={{ marginBottom: 34 }}>
          <CircleIconButton
            iconName="close"
            onPress={() => {
              navigation.goBack()
            }}
            style={styles.closeButton}
          />

          <Text variant="baseBold" style={styles.title}>
            {t('filters')}
          </Text>
        </View>
      </View>

      <View
        style={{
          flex: 1
        }}
      >
        <Select
          label={t('region')}
          {...(filters.region !== null && { value: filters.region })}
          style={{ marginBottom: 12 }}
          placeholder={t('selectARegion')}
          searchable
          searchPlaceholder={t('searchARegion')}
          options={
            data?.regions.map((region) => ({
              label: region.name,
              value: region.code
            })) ?? []
          }
          onSelect={(option) => {
            setFilters((prev) => ({
              ...prev,
              region: option.value
            }))
          }}
        />

        <Select
          label={t('country')}
          {...(filters.country !== null && { value: filters.country })}
          style={{ marginBottom: 12 }}
          placeholder={t('selectACountry')}
          searchable
          searchPlaceholder={t('searchACountry')}
          options={
            data?.countries.map((country) => ({
              label: country.name,
              value: country._id
            })) ?? []
          }
          onSelect={(option) => {
            setFilters((prev) => ({
              ...prev,
              country: option.value
            }))
          }}
        />

        <Select
          label={t('facilityType')}
          value={filters.type?._id}
          style={{ marginBottom: 12 }}
          placeholder={t('selectAFacilityType')}
          searchable
          searchPlaceholder={t('searchAFacilityType')}
          options={
            data?.types.map((type) => ({
              label: type.name,
              value: type._id
            })) ?? []
          }
          onSelect={(option) => {
            setFilters((prev) => ({
              ...prev,
              type: {
                _id: option.value,
                name: option.label
              }
            }))
          }}
        />

        <View style={[styles.buttons, { marginBottom: 25 }]}>
          <Button
            compact
            type="secondary"
            title={t('clearAll')}
            onPress={() => {
              setFilters({
                region: null,
                country: null,
                type: null
              })
            }}
          />

          <Button
            compact
            title={t('applyFilters')}
            onPress={() => {
              navigation.navigate('FindFacility', filters)
            }}
          />
        </View>
      </View>
    </SafeArea>
  )
}

export default Filters

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: 0,
    start: 0,
    zIndex: 1
  },
  title: {
    textAlign: 'center',
    marginVertical: 15.5
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 'auto'
  }
})
