import { useEffect, useRef } from 'react'
import * as Updates from 'expo-updates'
import { AppState, Platform } from 'react-native'
import * as Sentry from '@sentry/react-native'
// Components
import { EASUpdate } from '@/common/components'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'

const useEASUpdate = () => {
  const appState = useRef(AppState.currentState)
  const showModal = usePortalStore((state) => state.showModal)

  const showUpdateModal = () => {
    showModal({
      content: <EASUpdate />,
      dismissable: false
    })
  }

  const checkForUpdates = async () => {
    try {
      if (Platform.OS !== 'web') {
        const update = await Updates.checkForUpdateAsync()

        if (update.isAvailable) {
          showUpdateModal()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) != null &&
        nextAppState === 'active'
      ) {
        void checkForUpdates()
      }

      appState.current = nextAppState
    })

    return () => {
      subscription.remove()
    }
  }, [])
}

export default useEASUpdate
