// External
import { useState, useEffect } from 'react'
// Constants
import { locales } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

/**
 * Replaces I18nManager.isRTL which returns undefined when Platform.OS === 'web'
 */
const useIsRTL = (): boolean => {
  const [isRTL, setIsRTL] = useState(false)
  const locale = useAppStore((state) => state.locale)

  useEffect(() => {
    try {
      const value = locales[locale].isRTL
      setIsRTL(value)
    } catch (error) {
      console.error('Error in useIsRTL:', error)
    }
  }, [locale])

  return isRTL
}

export default useIsRTL
