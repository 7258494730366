import { client } from '@/common/config'

export const getCustomFields = async () => {
  const { data, error } = await client.GET('/api/app-user/custom-fields')

  if (error !== undefined) {
    console.error('Error getting custom fields:', error)
    return await Promise.reject(error)
  }

  return data.data
}
