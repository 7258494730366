import { Platform } from 'react-native'
import { lazy, Suspense } from 'react'
import { Vimeo } from 'react-native-vimeo-iframe'

interface Props {
  vimeoId: string
}

const VimeoWeb = lazy(async () => {
  return await import('@u-wave/react-vimeo')
})

export const InductionVideoPlayer = ({ vimeoId }: Props) => {
  if (Platform.OS === 'web') {
    return (
      <Suspense>
        <VimeoWeb video={`https://vimeo.com/${vimeoId}`} />
      </Suspense>
    )
  }

  return <Vimeo style={{ flex: 1 }} videoId={vimeoId} />
}
