// External
import * as Sentry from '@sentry/react-native'
import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync
} from 'expo-location'
import { getExpoPushTokenAsync, getPermissionsAsync } from 'expo-notifications'
import { useEffect } from 'react'
import { AppState, Platform } from 'react-native'
// Services
import { updateUserSettings } from '@/common/services'

const useUpdateUserSettings = (isLoggedIn: boolean) => {
  if (Platform.OS === 'web') {
    return
  }

  const updatePushPermissions = async () => {
    try {
      if (isLoggedIn) {
        const { granted } = await getPermissionsAsync()

        const { data: pushId } = await getExpoPushTokenAsync()

        await updateUserSettings({
          pushIds: [pushId],
          permissions: { pushNotifications: granted }
        })
      }
    } catch (error) {
      console.error('Error updating push permissions:', error)
      Sentry.captureException(error)
    }
  }

  const updateLocationPermissions = async () => {
    try {
      if (isLoggedIn) {
        const fgStatus = await getForegroundPermissionsAsync()
        const bgStatus = await getBackgroundPermissionsAsync()

        if (fgStatus !== null && bgStatus !== null) {
          await updateUserSettings({
            permissions: {
              foregroundLocation: fgStatus.granted,
              backgroundLocation: bgStatus.granted
            }
          })
        }
      }
    } catch (error) {
      console.error('Error updating location permissions:', error)
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    void updatePushPermissions()
    void updateLocationPermissions()

    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        void updatePushPermissions()
        void updateLocationPermissions()
      }
    })

    return () => {
      subscription.remove()
    }
  }, [isLoggedIn])
}

export default useUpdateUserSettings
