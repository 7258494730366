import { client } from '@/common/config'

export const generateEmailChangeRequest = async (email: string) => {
  const { error } = await client.POST('/api/auth/app/email/change/request', {
    body: {
      email
    }
  })

  if (error !== undefined) {
    console.error('Error generating email change request:', error)
    return await Promise.reject(error)
  }
}
