// Config
import { client } from '@/common/config'
// Models
import { TokenType } from '@/auth/models'
// Utils
import { storeToken } from '@/auth/utils'

export const selectFacilityUserType = async (facilityUserTypeId: string) => {
  try {
    const { data, error } = await client.POST(
      '/api/auth/app/facility/user-type/select',
      {
        body: {
          facilityUserTypeId
        }
      }
    )

    if (error !== undefined) {
      console.error('Error selecting facility user type:', error)
      return await Promise.reject(error)
    }

    await storeToken(
      data.data.accessToken,
      TokenType.AUTH,
      data.data.expirationToken
    )
    await storeToken(
      data.data.refreshToken,
      TokenType.REFRESH,
      data.data.refreshExpirationToken
    )
  } catch (error) {
    console.error('Error selecting facility user type:', error)
    return await Promise.reject(error)
  }
}
