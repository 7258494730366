// External
import type { ImagePickerAsset } from 'expo-image-picker'
import { Platform } from 'react-native'
// Config
import { client } from '@/common/config'
// Utils
import { getAndroidFileType, getWebFile } from '@/common/utils'

interface Params {
  customFieldId: string
  attachmentId: string
  attachmentName: string
  newValue: ImagePickerAsset
}

export const updateCustomFieldAttachment = async ({
  customFieldId,
  attachmentId,
  attachmentName,
  newValue
}: Params) => {
  try {
    const { uri } = newValue

    let attachment: any

    if (Platform.OS === 'web') {
      attachment = await getWebFile({
        uri,
        fileName: attachmentName
      })
    } else {
      attachment = {
        name: newValue.fileName ?? attachmentName,
        type:
          Platform.OS === 'android'
            ? getAndroidFileType(newValue)
            : newValue.type,
        uri: Platform.OS === 'ios' ? uri.replace('file://', '') : uri
      }
    }

    const { data, error } = await client.POST(
      '/api/app-user/custom-fields/{cfid}/attachments/{attachmentId}/update',
      {
        params: {
          path: {
            cfid: customFieldId,
            attachmentId
          }
        },
        body: {
          attachment
        },
        bodySerializer: (body) => {
          const fd = new FormData()
          for (const name in body) {
            fd.append(name, body[name as keyof typeof body] as string)
          }
          return fd
        }
      }
    )

    if (error !== undefined) {
      console.error('Error updating custom field attachment:', error)
      return await Promise.reject(error)
    }

    return data.data.attachments
  } catch (error) {
    console.error('Error updating custom field attachment:', error)
    return await Promise.reject(error)
  }
}
