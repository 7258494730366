export const phoneNumberRegEx = /^\d{7,}$/

export const nameRegEx =
  /^(?!.*\s{2})(?!.*\s$)(?!^\s)[A-Za-zÀ-ÿ]{2,}(?:\s[A-Za-zÀ-ÿ]+)*$/

// Password
export const numbersRegex = /[0-9]/
export const uppercaseRegex = /[A-Z]/
export const lowercaseRegex = /[a-z]/
export const symbolRegex = /[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./]/
export const noSpaceRegex = /^[^\s]*$/
