import { client } from '@/common/config'

export const getInductionTest = async () => {
  const { data, error } = await client.GET('/api/safety-induction/test')

  if (error !== undefined) {
    console.error('Error getting induction test:', error)
    return await Promise.reject(error)
  }

  return data.data
}
