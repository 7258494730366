// External
import {
  StyleSheet,
  TouchableOpacity,
  type StyleProp,
  type ViewStyle
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { Button as RNEButton } from '@rneui/base'
// Components
import { Text } from './Text'
import { Icon } from './Icon'
// Constants
import { colors, gradients } from '@/common/constants'
// Models
import { type ButtonProps } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const Button = ({
  type = 'primary',
  style,
  onPress,
  title,
  disabled,
  compact = false,
  iconName,
  loading = false
}: ButtonProps) => {
  const colorScheme = useAppStore((state) => state.colorScheme)

  const alignStyle: StyleProp<ViewStyle> = compact
    ? { alignSelf: 'center' }
    : { alignSelf: 'stretch' }

  const spacingStyle: StyleProp<ViewStyle> =
    title === undefined && iconName !== undefined
      ? {
          justifyContent: 'center'
        }
      : { paddingHorizontal: 20 }

  if (disabled === true) {
    const disabledContainerColors = {
      light: {
        backgroundColor: colors.lightGray10Percent,
        borderColor: colors.lightGray10Percent
      },
      dark: {
        borderColor: colors.white5Percent,
        backgroundColor: colors.white10Percent
      }
    }
    const disabledTextColor =
      colorScheme === 'light'
        ? colors.lightGray10Percent
        : colors.white10Percent

    return (
      <RNEButton
        disabled
        loading={loading}
        containerStyle={[
          {
            height: 52,
            borderRadius: 7,
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 1
          },
          { ...disabledContainerColors[colorScheme] },
          style
        ]}
        disabledStyle={{ backgroundColor: 'transparent' }}
        titleStyle={{
          fontFamily: 'PlusJakartaSans_500Medium',
          fontWeight: '500',
          fontSize: 15,
          lineHeight: 21,
          letterSpacing: 0.35
        }}
        disabledTitleStyle={{
          color: disabledTextColor
        }}
      >
        {title !== undefined && title}

        {iconName !== undefined && (
          <Icon name={iconName} color={disabledTextColor} size={22} />
        )}
      </RNEButton>
    )
  }

  if (colorScheme === 'light') {
    if (type === 'primary') {
      return (
        <TouchableOpacity onPress={onPress} style={[alignStyle, style]}>
          <LinearGradient
            {...gradients.lightPrimaryButton}
            style={[styles.container, spacingStyle, { borderWidth: 0 }]}
          >
            {title !== undefined && <Text variant="buttonBold">{title}</Text>}

            {iconName !== undefined && (
              <Icon name={iconName} color={colors.white} size={22} />
            )}
          </LinearGradient>
        </TouchableOpacity>
      )
    }

    return (
      <TouchableOpacity onPress={onPress} style={[alignStyle, style]}>
        <LinearGradient
          {...gradients.lightSecondaryButtonBorder}
          style={styles.border}
        >
          <LinearGradient
            {...gradients.lightSecondaryButton}
            style={{
              ...styles.inner,
              ...spacingStyle,
              ...(!compact && { justifyContent: 'center' })
            }}
          >
            {title !== undefined && <Text variant="button">{title}</Text>}

            {iconName !== undefined && <Icon name={iconName} size={22} />}
          </LinearGradient>
        </LinearGradient>
      </TouchableOpacity>
    )
  }

  // if (type === 'primary') {
  //   // DARK PRIMARY
  //   return (
  //     <TouchableOpacity onPress={onPress} style={[alignStyle, style]}>
  //       <LinearGradient
  //         {...gradients.darkPrimaryButtonBorder}
  //         style={styles.border}
  //       >
  //         <LinearGradient
  //           {...gradients.darkPrimaryButton}
  //           style={[
  //             styles.inner,
  //             spacingStyle,
  //             {
  //               backgroundColor: colors.darkerSlateGray,
  //               ...(!compact && { justifyContent: 'center' })
  //             }
  //           ]}
  //         >
  //           {title !== undefined && <Text variant="buttonBold">{title}</Text>}

  //           {iconName !== undefined && <Icon name={iconName} size={22} />}
  //         </LinearGradient>
  //       </LinearGradient>
  //     </TouchableOpacity>
  //   )
  // }

  // DARK SECONDARY
  // return (
  //   <TouchableOpacity onPress={onPress} style={[alignStyle, style]}>
  //     <LinearGradient
  //       {...gradients.darkSecondaryButton}
  //       style={[
  //         styles.container,
  //         spacingStyle,
  //         { borderColor: colors.white10Percent }
  //       ]}
  //     >
  //       {title !== undefined && <Text variant="button">{title}</Text>}

  //       {iconName !== undefined && <Icon name={iconName} size={22} />}
  //     </LinearGradient>
  //   </TouchableOpacity>
  // )
}

const styles = StyleSheet.create({
  container: {
    height: 52,
    minWidth: 52,
    padding: 10,
    borderRadius: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    flexDirection: 'row',
    gap: 15
  },
  border: {
    height: 52,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
    borderRadius: 7
  },
  inner: {
    height: 50,
    minWidth: 50,
    width: '100%',
    borderRadius: 7,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    gap: 15
  }
})
