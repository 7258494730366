export const checkInTimeValues = [
  { value: '0', label: '12:00 AM' },
  { value: '30', label: '12:30 AM' },
  { value: '60', label: '01:00 AM' },
  { value: '90', label: '01:30 AM' },
  { value: '120', label: '02:00 AM' },
  { value: '150', label: '02:30 AM' },
  { value: '180', label: '03:00 AM' },
  { value: '210', label: '03:30 AM' },
  { value: '240', label: '04:00 AM' },
  { value: '270', label: '04:30 AM' },
  { value: '300', label: '05:00 AM' },
  { value: '330', label: '05:30 AM' },
  { value: '360', label: '06:00 AM' },
  { value: '390', label: '06:30 AM' },
  { value: '420', label: '07:00 AM' },
  { value: '450', label: '07:30 AM' },
  { value: '480', label: '08:00 AM' },
  { value: '510', label: '08:30 AM' },
  { value: '540', label: '09:00 AM' },
  { value: '570', label: '09:30 AM' },
  { value: '600', label: '10:00 AM' },
  { value: '630', label: '10:30 AM' },
  { value: '660', label: '11:00 AM' },
  { value: '690', label: '11:30 AM' },
  { value: '720', label: '12:00 PM' },
  { value: '750', label: '12:30 PM' },
  { value: '780', label: '01:00 PM' },
  { value: '810', label: '01:30 PM' },
  { value: '840', label: '02:00 PM' },
  { value: '870', label: '02:30 PM' },
  { value: '900', label: '03:00 PM' },
  { value: '930', label: '03:30 PM' },
  { value: '960', label: '04:00 PM' },
  { value: '990', label: '04:30 PM' },
  { value: '1020', label: '05:00 PM' },
  { value: '1050', label: '05:30 PM' },
  { value: '1080', label: '06:00 PM' },
  { value: '1110', label: '06:30 PM' },
  { value: '1140', label: '07:00 PM' },
  { value: '1170', label: '07:30 PM' },
  { value: '1200', label: '08:00 PM' },
  { value: '1230', label: '08:30 PM' },
  { value: '1260', label: '09:00 PM' },
  { value: '1290', label: '09:30 PM' },
  { value: '1320', label: '10:00 PM' },
  { value: '1350', label: '10:30 PM' },
  { value: '1380', label: '11:00 PM' },
  { value: '1410', label: '11:30 PM' }
]
