//  External
import type { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { object, array, type InferType, string, boolean } from 'yup'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  Text,
  StepNavigationButtons,
  ProgressBar,
  Select,
  QuestionAnswer
} from '@/common/components'
import { PermitGenericWorkCard } from '@/permitToWork/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
// Services
import { getTypeOfWork } from '@/permitToWork/services/getTypeOfWork'
// Stores
import usePTWConfigStore from '@/permitToWork/stores/usePTWConfigStore'
import useAppStore from '@/common/stores/useAppStore'
import { colors } from '@/common/constants'

type Props = StackScreenProps<PTWStackParamList, 'CreatePermitStepTypeOfWork'>

const CreatePermitStepTypeOfWork = ({ navigation, route }: Props) => {
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const hasGenericTypeOfWorkEnabled =
    ptwConfig?.hasGenericTypeOfWorkEnabled ?? false
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      object({
        typeOfWorkRisksIds: array(string().required()).when(
          'genericTypeOfWork',
          ([genericTypeOfWork]) => {
            if (genericTypeOfWork === false) {
              return array(string().required())
                .min(1, t('youNeedToSelectAtLeastOneTypeOfWork'))
                .required()
            } else {
              return array(string().required()).required()
            }
          }
        ),
        genericTypeOfWork: boolean().required()
      }),
    []
  )

  type FormValues = InferType<typeof formSchema>

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      typeOfWorkRisksIds: [],
      genericTypeOfWork: hasGenericTypeOfWorkEnabled ? undefined : false
    }
  })

  const { typeOfWorkRisksIds, genericTypeOfWork } = watch()

  const onSubmit = ({ typeOfWorkRisksIds, genericTypeOfWork }: FormValues) => {
    navigation.navigate('CreatePermitStepAttachments', {
      ...route.params,
      typeOfWorkRisksIds: typeOfWorkRisksIds ?? [],
      genericTypeOfWork
    })
  }

  const { data } = useQuery({
    queryKey: ['typeOfWork', currentFacility?._id],
    queryFn: getTypeOfWork
  })

  return (
    <SafeArea>
      <View style={{ marginHorizontal: 25, marginBottom: 25 }}>
        <ProgressBar
          style={styles.progressBar}
          value={hasGenericTypeOfWorkEnabled ? 0.875 : 0.857}
        />

        <Text variant="h2Bold" style={{ marginBottom: 8 }}>
          {t('typeOfWork')}
        </Text>

        {[false].includes(genericTypeOfWork) && (
          <Text>{t('pleaseSelectTheHighRisks')}</Text>
        )}

        {[true].includes(genericTypeOfWork) && <PermitGenericWorkCard />}
      </View>

      {hasGenericTypeOfWorkEnabled && genericTypeOfWork === undefined && (
        <View style={{ marginHorizontal: 25 }}>
          <Text
            style={{ marginBottom: 38, textAlign: 'center' }}
            variant="mediumBold"
          >
            {t('doesThisPTWIncludeHighRiskActivities')}
          </Text>

          <View style={{ gap: 17 }}>
            <QuestionAnswer
              label={t('yes')}
              value="true"
              onSelect={() => {
                setValue('genericTypeOfWork', false)
              }}
              isSelected={genericTypeOfWork === false}
            />

            <QuestionAnswer
              label={t('no')}
              value="true"
              onSelect={() => {
                setValue('genericTypeOfWork', true)
              }}
              isSelected={genericTypeOfWork === true}
            />
          </View>
        </View>
      )}

      {[false].includes(genericTypeOfWork) && (
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={styles.contentContainer}
          bounces={false}
        >
          {data?.map((typeOfWork) => (
            <Select
              key={typeOfWork._id}
              searchable
              searchPlaceholder={t('searchHighRisks')}
              noResultsTranslationKey="noHighRisksFound"
              value={typeOfWorkRisksIds}
              label={typeOfWork.label}
              options={
                typeOfWork.risks.map((risk) => ({
                  label: risk.risk,
                  value: risk._id
                })) ?? []
              }
              onMultipleSelect={(options) => {
                setValue('typeOfWorkRisksIds', [
                  ...new Set([
                    ...(typeOfWorkRisksIds ?? []),
                    ...options.map((option) => option.value)
                  ])
                ])
              }}
              placeholder={t('selectOneOrMoreOptions')}
            />
          ))}

          {errors.typeOfWorkRisksIds?.message !== undefined && (
            <Text style={styles.error}>
              {errors.typeOfWorkRisksIds.message}
            </Text>
          )}
        </ScrollView>
      )}

      <StepNavigationButtons
        style={styles.navigationButtons}
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={
          isSubmitting ||
          (hasGenericTypeOfWorkEnabled && genericTypeOfWork === undefined)
        }
        {...(genericTypeOfWork !== undefined && {
          backOnPress: () => {
            reset({
              typeOfWorkRisksIds: [],
              genericTypeOfWork: undefined
            })
          }
        })}
      />
    </SafeArea>
  )
}

export default CreatePermitStepTypeOfWork

const styles = StyleSheet.create({
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  navigationButtons: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  contentContainer: {
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  genericTypeOfWork: {
    borderRadius: 7,
    padding: 16,
    borderWidth: 1,
    flexDirection: 'row',
    gap: 8,
    borderColor: colors.lightGray10Percent
  },
  error: {
    color: colors.error,
    fontFamily: 'PlusJakartaSans_400Regular'
  }
})
